<ion-content>
  <div class="content">
    <div class="articleImage">
      <div *ngIf="isLoading" class="spinner">
        <doka-spinner [size]="64"></doka-spinner>
      </div>
      <ion-img
        [src]="url"
        (ionImgDidLoad)="isLoading = false"
        (ionError)="displayErrorImage(); isLoading = false"
        alt="No Image available"
      ></ion-img>
    </div>
    <div class="articleInfos">
      <ion-list lines="none">
        <div class="articleInfoContainer">
          <div>
            <p>{{ "NORMPACKQTY" | i18nextEager }}</p>
          </div>
          <div class="articleInfoValue">
            <ion-skeleton-text
              *ngIf="article === null"
              animated="true"
            ></ion-skeleton-text>
            <p *ngIf="article">
              {{
                article.packages[0]
                  ? article.packages[0]?.maximumPackagingQuantity
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div class="articleInfoContainer">
          <div>
            <p>{{ "NORMPACKUNIT" | i18nextEager }}</p>
          </div>
          <div class="articleInfoValue">
            <ion-skeleton-text
              *ngIf="article === null"
              animated="true"
            ></ion-skeleton-text>
            <p *ngIf="article">
              {{
                article.orderDetails[0]
                  ? (article.orderDetails[0]?.orderUnit | i18nextEager)
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div class="articleInfoContainer">
          <div>
            <p>{{ "COLLI_TYPE" | i18nextEager }}</p>
          </div>
          <div class="articleInfoValue">
            <ion-skeleton-text
              *ngIf="article === null"
              animated="true"
            ></ion-skeleton-text>
            <p *ngIf="article">
              {{
                article.packages[0]
                  ? article.packages[0]?.articleIdentifier
                    ? article.packages[0].packagingArticle.name
                    : (article.packages[0]?.packagingUnit | i18nextEager)
                  : "-"
              }}
            </p>
          </div>
        </div>
      </ion-list>
    </div>
  </div>
</ion-content>
