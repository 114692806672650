<ion-button class="openDatePickerButton" (click)="datePickerModal.present()">{{ (datetime !== null && datetime !== undefined) ? (datetime | date) : ("DATE_FILTER_SELECT" | i18nextEager) }}</ion-button>
<ion-modal class="datePickerModal" #datePickerModal>
    <ng-template>
        <ion-datetime 
            id="datetime" 
            presentation="date"
            showDefaultButtons="true"
            (ionChange)="onChange()"
            (ionCancel)="deleteValue()"
            [value]="datetime"
            #date>
            <ion-buttons class="datetime-buttons" slot="buttons">
                <ion-button color="primary" (click)="date.cancel()">{{ date.value !== null
                    && date.value !== undefined ? ('DELETE' | i18nextEager) : ('CANCEL' | i18nextEager) }}</ion-button>
                <ion-button class="button-primary" (click)="date.confirm()">{{
                    "DATE_PICKER_DONE" | i18nextEager }}</ion-button>
            </ion-buttons>
        </ion-datetime>
    </ng-template>
</ion-modal>