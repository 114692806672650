import { ModalController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";
import { DatabaseService } from '../services/database.service';
import { LocalizedArticle } from "../models/localizedArticle";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-item",
  templateUrl: "./add-item.component.html",
  styleUrls: ["./add-item.component.scss"],
})
export class AddItemComponent implements OnInit {
  @Input("isInventoryValued") isInventoryValued: boolean = null;
  @Input("lifeCycleCodeFilters") lifeCycleCodeFilters: string[] = [];
  public articles: LocalizedArticle[];
  public searchText: string = "";
  isLoadingArticles: boolean = false;
  isLoadingMoreArticles: boolean = false;
  selectedArticle: LocalizedArticle = null;
  errorLoadingArticleImage: string[] = [];

  constructor(
    private modalCtrl: ModalController,
    private databaseService: DatabaseService
  ) { }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async ngOnInit(): Promise<void> {
    this.isLoadingArticles = true;
    this.articles = await this.databaseService.getAllArticles(100, 0, this.isInventoryValued, this.lifeCycleCodeFilters);
    this.isLoadingArticles = false;
  }

  async onSearch() {
    this.isLoadingArticles = true;
    this.articles = null;
    this.articles = await this.databaseService.getAllArticles(100, 0, this.isInventoryValued, this.lifeCycleCodeFilters, this.searchText);
    this.isLoadingArticles = false;
  }

  async onReload(event) {
    this.isLoadingMoreArticles = true;
    let articles = await this.databaseService.getAllArticles(100, this.articles.length, this.isInventoryValued, this.lifeCycleCodeFilters, this.searchText);
    this.articles.push(...articles);
    event.target.complete();
    this.isLoadingMoreArticles = false;
  }

  onAddItem() {
    this.modalCtrl.dismiss(this.selectedArticle);
  }

  protected generateArticleImageUrl(articleIdentifier: string) {
    return environment.groupAPIUrl + "/articles/" + articleIdentifier + "/assets/images?type=Rendering&renditionType=Thumbnail";
  }

}
