export enum MobileReturnDeliveryStatus {
    None = "None",
    Released = "Released",
    Unloading = "Unloading",
    FirstSight = "FirstSight",
    FirstSightFinishedPending = "FirstSightFinishedPending",
    FirstSightFinished = "FirstSightFinished",
    Processing = "Processing",
    ProcessingPartlyDone = "ProcessingPartlyDone",
    ProcessingFinishedPending = "ProcessingFinishedPending",
    ProcessingFinished = "ProcessingFinished",
    Finishing = "Finishing",
    ReturnTakenOverPending = "ReturnTakenOverPending",
    ReturnTakenOver = "ReturnTakenOver"
}