import { NativeDateAdapter } from '@angular/material/core';
import { AppInjector } from '../app.module';
import { ConfigService } from '../services/config.service';
import { Weekday } from '../enum/weekDay';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    override getFirstDayOfWeek(): number {
        const configService = AppInjector.get(ConfigService);
        let weekDay: number = Weekday[configService.branchConfiguration.firstDayOfWeek];
        return weekDay;
    }
}