<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ tempTransportOrder._id }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item>
      {{ "transportOrderId" | i18nextEager }}:
      <span class="textRight">
        {{ tempTransportOrder._id }}
      </span>
    </ion-item>
    <ion-item>
      {{ "YARDCAM_LINKS" | i18nextEager }}
      <span class="textRight">
        <a
          class="yardcamLink"
          (click)="openYardcam('TO', tempTransportOrder._id)"
        >
          {{ "NEW_YC_LINK" | i18nextEager }}
        </a>
      </span>
    </ion-item>
    <ion-item>
      {{ "COMPANY" | i18nextEager }}:
      <span class="textRight">
        {{ tempTransportOrder.pickingLists[0]?.custName }}
      </span>
    </ion-item>
    <ion-item>
      {{ "projectName" | i18nextEager }}:
      <span class="textRight">
        {{ tempTransportOrder.pickingLists[0]?.projectName }}
      </span>
    </ion-item>
    <ion-item>
      {{ "DLV_ADDRESS" | i18nextEager }}:
      <span class="textRight">
        {{ tempTransportOrder.pickingLists[0]?.dlvAddress }}
      </span>
    </ion-item>
    <ion-item>
      {{ "p_id" | i18nextEager }}:
      <span class="textRight">
        {{ getAllPickingListIds() }}
      </span>
    </ion-item>
    <ion-item>
      {{ "COMMISSION_LOCATIONS" | i18nextEager }}:
      <span class="textRight">
        {{ getCommissionLocations() }}
      </span>
    </ion-item>
    <ion-item>
      {{ "ARTICLE_COUNT" | i18nextEager }}:
      <span class="textRight">
        {{ getArticleCount() }}
      </span>
    </ion-item>
    <ion-item>
      {{ "WEIGHT" | i18nextEager }}:
      <span class="textRight"> {{ getWeight() }} kg </span>
    </ion-item>
  </ion-list>
</ion-content>
