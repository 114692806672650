import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IonDatetime, IonModal } from '@ionic/angular';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @ViewChild("date") date: IonDatetime;
  @ViewChild("datePickerModal") datePickerModal: IonModal;
  @Output() valueChange = new EventEmitter();
  public datetime: string = null;

  constructor() { }

  ngOnInit(): void {}

  onChange() {
    this.datetime = this.date.value.toString();
    this.valueChange.emit(this.datetime);
    this.datePickerModal.dismiss();
  }

  deleteValue() {
    this.datetime = null;
    this.valueChange.emit(this.datetime);
    this.datePickerModal.dismiss();
  }

}
