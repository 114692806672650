import { Branch } from "./branch";

export class UserPermissionRequestRole {

    constructor(
        id: string,
        roleName: string,
        branches: Branch[]
    ) {
        this.id = id;
        this.roleName = roleName;
        this.branches = branches;
    }

    id: string;
    roleName: string;
    branches: Branch[];
}