import { WorkDay } from "../enums/workDay.enum";

export class CapacityBaseData {

    constructor(
        maximumRedeliveryCapacity: number,
        maximumReconditioningCapacity: number,
        targetRedeliveryCapacity: number,
        targetReconditioningCapacity: number,
        exists: boolean,
        workDays: string[]
    ) {
        this.maximumRedeliveryCapacity = maximumRedeliveryCapacity;
        this.maximumReconditioningCapacity = maximumReconditioningCapacity;
        this.targetRedeliveryCapacity = targetRedeliveryCapacity;
        this.targetReconditioningCapacity = targetReconditioningCapacity;
        this.exists = exists;
        this.workDays = workDays;
    }

    maximumRedeliveryCapacity: number;
    maximumReconditioningCapacity: number;
    targetRedeliveryCapacity: number;
    targetReconditioningCapacity: number;
    exists: boolean;
    workDays: string[];
}