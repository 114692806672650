import { Overview } from './../enum/overview.enum';
import { Injectable } from "@angular/core";
import { ConfigModel } from "../models/config";
import { BehaviorSubject, Subject } from "rxjs";
import { PickingSlide } from '../enum/pickingSlide.enum';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BranchConfiguration } from '../models/branchConfiguration';
import { MeasurementSystem } from '../enum/measurementSystem.enum';

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public readonly defaultLanguage: string = "en_us";
  public appLanguages: string[] = [];
  public readonly teams: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private appLanguage: string;
  public configChange: Subject<void> = new Subject();
  public config: ConfigModel;
  public isPrinterConnected: boolean;
  public configLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public newOrder = new BehaviorSubject<boolean>(true);
  public branchConfiguration: BranchConfiguration = null;

  constructor(
  ) { }

  //Sets the initial language on app start (currently without PouchDB access)
  public async initConfigService() {
    if (localStorage.getItem("config") !== null) {
      const config = this.getConfig();

      if (!config.branchId) {
        config.branchId = null;
      }

      if (!config.measurementSystem) {
        config.measurementSystem = MeasurementSystem.Metric;
      }

      if (!config.returnDeliveryColumns) {
        config.returnDeliveryColumns = [
          { value: "returnDeliveryIdentifier", size: null, display: true },
          { value: "customerName", size: null, display: true },
          { value: "projectIdentifier", size: null, display: true },
          { value: "projectName", size: null, display: true },
          { value: "returnDeliveryType", size: null, display: true },
          { value: "expectedReturnDeliveryDate", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "unloadingLocation", size: null, display: true },
          { value: "progress", size: null, display: true },
          { value: "users", size: null, display: true },
          { value: "tonnage1st", size: null, display: true },
          { value: "tonnage", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ];
      }

      if (!config.returnDeliveryItemColumns) {
        config.returnDeliveryItemColumns = [
          { value: "image", size: null, display: true },
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "quantityFirstSight", size: null, display: true },
          { value: "jobSite", size: null, display: true },
          { value: "quantityCustomerCount", size: null, display: true },
          { value: "quantityTotal", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "quantityOk", size: null, display: true },
          { value: "quantityRepair", size: null, display: true },
          { value: "quantityFinishedReconditioning", size: null, display: true },
          { value: "quantityWear", size: null, display: true },
          { value: "quantityDamaged", size: null, display: true },
          { value: "quantityOwnConsumption", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ];
      }

      if (!config.returnDeliveryItemColumns.find(c => c.value === "image")) {
        config.returnDeliveryItemColumns.unshift({ value: "image", size: null, display: true });
      }

      if (!config.returnDeliverySparepartColumns) {
        config.returnDeliverySparepartColumns = [
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "materialCondition", size: null, display: true },
          { value: "quantityDoka", size: null, display: true },
          { value: "quantityCustomer", size: null, display: true },
          { value: "reason", size: null, display: true },
          { value: "isIncluded", size: null, display: true }
        ];
      }

      if (!config.yardStockColumns.find(c => c.value === "tonnage")) {
        config.yardStockColumns.push({ value: "tonnage", size: null, display: true });
      }

      if (!config.inboundOrderColumns) {
        config.inboundOrderColumns = [
          { value: "inboundOrderIdentifier", size: null, display: true },
          { value: "purchaseType", size: null, display: true },
          { value: "purchaseOrderIdentifiers", size: null, display: true },
          { value: "plannedArrivalDate", size: null, display: true },
          { value: "mobileStatus", size: null, display: true },
          { value: "progress", size: null, display: true },
          { value: "location", size: null, display: true },
          { value: "remarks", size: null, display: true },
          { value: "carrierName", size: null, display: true },
          { value: "senderDeliveryNoteIdentifier", size: null, display: true },
          { value: "vendorName", size: null, display: true },
          { value: "processors", size: null, display: true },
          { value: "unloader", size: null, display: true },
          { value: "tonnage", size: null, display: true }
        ];
      }

      if (!config.inboundOrderItemColumns) {
        config.inboundOrderItemColumns = [
          { value: "image", size: null, display: true },
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "targetYardIdentifier", size: null, display: true },
          { value: "materialCondition", size: null, display: true },
          { value: "quantityOrdered", size: null, display: true },
          { value: "quantityOkNew", size: null, display: true },
          { value: "quantityOkUsed", size: null, display: true },
          { value: "quantityRepair", size: null, display: true },
          { value: "quantityDefect", size: null, display: true },
          { value: "mobileLineItemStatus", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "remarks", size: null, display: true }
        ];
      }

      config.yardStockColumns = [
        { value: "articleIdentifier", size: null, display: true },
        { value: "articleName", size: null, display: true },
        { value: "serialNumberIdentifier", size: null, display: true },
        { value: "yardIdentifier", size: null, display: true },
        { value: "quantityNew", size: null, display: true },
        { value: "quantityUsed", size: null, display: true },
        { value: "tonnage", size: null, display: true },
      ];

      if (!config.yardStockColumns.find(c => c.value === "expectedProcessingTime")) {
        config.yardStockColumns.push({ value: "expectedProcessingTime", size: null, display: true });
      }

      if (config.overviewFirst === Overview.Return) {
        config.overviewFirst = Overview.ReturnDelivery;
      }

      if (config.overviewSecond === Overview.Return) {
        config.overviewSecond = Overview.ReturnDelivery;
      }

      if (config.overviewThird === Overview.Return) {
        config.overviewThird = Overview.ReturnDelivery;
      }

      if (config.overviewFourth === Overview.Return) {
        config.overviewFourth = Overview.ReturnDelivery;
      }

      this.setAllColumnsToVisible(config);

      await this.saveConfig(config);
      this.changeFont();
    } else {
      console.warn(
        "AppConfiguartion was not found. Default config will be used."
      );
      const newConfig: any = {
        appLanguage: this.defaultLanguage,
        fontSize: "standard",
        token: null,
        clientid: null,
        branchId: null,
        returnCustQtyColumns: [
          { value: "ItemId", size: null, display: true },
          { value: "ItemName", size: null, display: true },
          { value: "InventSerialId", size: null, display: true },
          { value: "Invent", size: null, display: true },
          { value: "QtyCustPackingSlip", size: null, display: true },
          { value: "FirstSight", size: null, display: true },
          { value: "FirstSightSeen", size: null, display: true },
          { value: "AddedInApp", size: null, display: true }
        ],
        returnColumns: [
          { value: "locked", size: null, display: true },
          { value: "redeliveryType", size: null, display: true },
          { value: "t_id", size: null, display: true },
          { value: "convertedDate", size: null, display: true },
          { value: "pamstatus", size: null, display: true },
          { value: "pamplacenumber", size: null, display: true },
          { value: "additionaltext", size: null, display: true },
          { value: "rentalsitedescription", size: null, display: true },
          { value: "custname", size: null, display: true },
          { value: "tonnage1st", size: null, display: true },
          { value: "tonnage", size: null, display: true },
          { value: "projectno", size: null, display: true },
        ],
        returnItemColumns: [
          { value: "ItemId", size: null, display: true },
          { value: "ItemName", size: null, display: true },
          { value: "SerialNumber", size: null, display: true },
          { value: "FirstSight", size: null, display: true },
          { value: "FirstSightSeen", size: null, display: true }, //Augesymbol
          { value: "Invent", size: null, display: true },
          { value: "QtyCustPackingSlip", size: null, display: true },
          { value: "Total", size: null, display: true }, // OK, Rec, Crap, CustCrap
          { value: "RedCatFinished", size: null, display: true },
          { value: "Qty_QualCat_OK", size: null, display: true },
          { value: "Qty_Pulled", size: null, display: true },
          { value: "Qty_QualCat_Repair", size: null, display: true },
          { value: "Qty_QualCat_RepairFinished", size: null, display: true },
          { value: "Qty_QualCat_Crap", size: null, display: true },
          { value: "Qty_QualCat_CustCrap", size: null, display: true },
          { value: "Qty_Cleaning", size: null, display: true },
          { value: "Qty_OwnConsumption", size: null, display: true },
          { value: "Qty_PlyWoodRepair", size: null, display: true },
          { value: "Qty_FrameRepair", size: null, display: true },
          { value: "AdditionalLineText", size: null, display: true },
          { value: "CouchAdditionalLineText", size: null, display: true },
          { value: "AddedInApp", size: null, display: true },
          { value: "IsCentralItem", size: null, display: true }, //Fadenkreuz
        ],
        returnSparePartColumns: [
          { value: "SuppItemId", size: null, display: true },
          { value: "SuppItemName", size: null, display: true },
          { value: "NewUsed", size: null, display: true },
          { value: "SalesUnit", size: null, display: true },
          { value: "CouchSalesQtyDoka", size: null, display: true },
          { value: "CouchSalesQty", size: null, display: true },
          { value: "Picked", size: null, display: true },
          { value: "ItemDamaged", size: null, display: true },
          { value: "ItemLack", size: null, display: true },
        ],
        openReturnItemColumns: [
          { value: "returnId", size: null, display: true },
          { value: "returnDate", size: null, display: true },
          { value: "pamplacenumber", size: null, display: true },
          { value: "ItemId", size: null, display: true },
          { value: "ItemName", size: null, display: true },
          { value: "Qty_QualCat_OK", size: null, display: true },
          { value: "Qty_QualCat_Repair", size: null, display: true },
          { value: "Qty_QualCat_RepairFinished", size: null, display: true },
          { value: "FirstSight", size: null, display: true },
          { value: "firstSightSeen", size: null, display: true }
        ],
        pickingColumns: [
          { value: "salesIds", size: null, display: true },
          { value: "convertedDate", size: null, display: true },
          { value: "projectName", size: null, display: true },
          { value: "custName", size: null, display: true },
          { value: "p_id", size: null, display: true },
          { value: "commissionLocation", size: null, display: true },
          { value: "picker", size: null, display: true },
          { value: "projectNo", size: null, display: true },
          { value: "itemCount", size: null, display: true },
          { value: "weight", size: null, display: true },
          { value: "done", size: null, display: true },
          { value: "combinedText", size: null, display: true },
          { value: "locked", size: null, display: true }
        ],
        pickingItemColumns: [
          { value: "itemId", size: null, display: true },
          { value: "itemName", size: null, display: true },
          { value: "orderedNew", size: null, display: true },
          { value: "orderedUsed", size: null, display: true },
          { value: "pickedNew", size: null, display: true },
          { value: "pickedUsed", size: null, display: true },
          { value: "changes", size: null, display: true },
          { value: "salesId", size: null, display: true },
          { value: "combinedText", size: null, display: true },
        ],
        transportOrderColumns: [
          { value: "transportOrderId", size: null, display: true },
          { value: "convertedDepDate", size: null, display: true },
          { value: "carrierAccount", size: null, display: true },
          { value: "carrierName", size: null, display: true },
          { value: "chauffeur", size: null, display: true },
          { value: "containerCode", size: null, display: true },
          { value: "tansUnitCode1", size: null, display: true },
          { value: "tansUnitCode2", size: null, display: true },
          { value: "packageCount", size: null, display: true },
          { value: "itemCount", size: null, display: true },
          { value: "shipper", size: null, display: true },
          { value: "checkLocked", size: null, display: true },
          { value: "locked", size: null, display: true },
          { value: "combinedText", size: null, display: true },
          { value: "loadingMeter", size: null, display: true },
          { value: "done", size: null, display: true }
        ],
        reconColumns: [
          { value: "isLocked", size: null, display: true },
          { value: "canItemsBeAdded", size: null, display: true },
          { value: "reconditioningOrderIdentifier", size: null, display: true },
          { value: "plannedFinishDate", size: null, display: true },
          { value: "departureYardIdentifier", size: null, display: true },
          { value: "receptionYardIdentifier", size: null, display: true },
          { value: "alternativeYardIdentifier", size: null, display: true },
          { value: "countType", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "type", size: null, display: true },
          { value: "customerIdentifier", size: null, display: true },
          { value: "customer", size: null, display: true },
          { value: "netWeight", size: null, display: true },
          { value: "doneWeight", size: null, display: true },
          { value: "initialCountEmployee", size: null, display: true },
          { value: "unloadingLocation", size: null, display: true },
          { value: "licensePlateTruck", size: null, display: true },
        ],
        reconItemColumns: [
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "quantity", size: null, display: true },
          { value: "quantityDone", size: null, display: true },
          { value: "initialCount", size: null, display: true },
          { value: "quantityCustomer", size: null, display: true },
          { value: "plannedFinishDate", size: null, display: true },
          { value: "customer", size: null, display: true },
          { value: "customerIdentifier", size: null, display: true },
          { value: "project", size: null, display: true },
          { value: "jobSite", size: null, display: true },
          { value: "reconditioningOrderIdentifier", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ],
        reconSparePartColumns: [
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "quantityDone", size: null, display: true },
          { value: "quantity", size: null, display: true },
          { value: "condition", size: null, display: true }
        ],
        openItemColumns: [
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "quantity", size: null, display: true },
          { value: "quantityDone", size: null, display: true },
          { value: "initialCount", size: null, display: true },
          { value: "quantityCustomer", size: null, display: true },
          { value: "plannedFinishDate", size: null, display: true },
          { value: "type", size: null, display: true },
          { value: "reconditioningOrderIdentifier", size: null, display: true },
          { value: "customer", size: null, display: true },
          { value: "customerIdentifier", size: null, display: true },
          { value: "project", size: null, display: true },
          { value: "jobSite", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ],
        yardStockColumns: [
          { value: "articleIdentifier", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "yardIdentifier", size: null, display: true },
          { value: "quantityNew", size: null, display: true },
          { value: "quantityUsed", size: null, display: true },
          { value: "tonnage", size: null, display: true },
        ],
        returnDeliveryColumns: [
          { value: "returnDeliveryIdentifier", size: null, display: true },
          { value: "customerName", size: null, display: true },
          { value: "projectIdentifier", size: null, display: true },
          { value: "projectName", size: null, display: true },
          { value: "returnDeliveryType", size: null, display: true },
          { value: "expectedReturnDeliveryDate", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "unloadingLocation", size: null, display: true },
          { value: "progress", size: null, display: true },
          { value: "users", size: null, display: true },
          { value: "tonnage1st", size: null, display: true },
          { value: "tonnage", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ],
        returnDeliveryItemColumns: [
          { value: "image", size: null, display: true },
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "quantityFirstSight", size: null, display: true },
          { value: "jobSite", size: null, display: true },
          { value: "quantityCustomerCount", size: null, display: true },
          { value: "quantityTotal", size: null, display: true },
          { value: "status", size: null, display: true },
          { value: "quantityOk", size: null, display: true },
          { value: "quantityRepair", size: null, display: true },
          { value: "quantityFinishedReconditioning", size: null, display: true },
          { value: "quantityWear", size: null, display: true },
          { value: "quantityDamaged", size: null, display: true },
          { value: "quantityOwnConsumption", size: null, display: true },
          { value: "additionalText", size: null, display: true }
        ],
        returnDeliverySparepartColumns: [
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "materialCondition", size: null, display: true },
          { value: "quantityDoka", size: null, display: true },
          { value: "quantityCustomer", size: null, display: true },
          { value: "reason", size: null, display: true },
          { value: "isIncluded", size: null, display: true }
        ],
        inboundOrderColumns: [
          { value: "inboundOrderIdentifier", size: null, display: true },
          { value: "purchaseType", size: null, display: true },
          { value: "purchaseOrderIdentifiers", size: null, display: true },
          { value: "plannedArrivalDate", size: null, display: true },
          { value: "mobileStatus", size: null, display: true },
          { value: "progress", size: null, display: true },
          { value: "location", size: null, display: true },
          { value: "remarks", size: null, display: true },
          { value: "carrierName", size: null, display: true },
          { value: "senderDeliveryNoteIdentifier", size: null, display: true },
          { value: "vendorName", size: null, display: true },
          { value: "processors", size: null, display: true },
          { value: "unloader", size: null, display: true },
          { value: "tonnage", size: null, display: true }
        ],
        inboundOrderItemColumnColumns: [
          { value: "image", size: null, display: true },
          { value: "articleIdentifier", size: null, display: true },
          { value: "articleName", size: null, display: true },
          { value: "targetYardIdentifier", size: null, display: true },
          { value: "materialCondition", size: null, display: true },
          { value: "quantityOrdered", size: null, display: true },
          { value: "quantityOkNew", size: null, display: true },
          { value: "quantityOkUsed", size: null, display: true },
          { value: "quantityRepair", size: null, display: true },
          { value: "quantityDefect", size: null, display: true },
          { value: "mobileLineItemStatus", size: null, display: true },
          { value: "serialNumberIdentifier", size: null, display: true },
          { value: "remarks", size: null, display: true }
        ],
        returnHighlighting: 'All',
        overviewFirst: Overview.Picking,
        overviewSecond: Overview.Outbound,
        overviewThird: Overview.Return,
        overviewFourth: Overview.Reconditioning,
        PickingSlide: PickingSlide.Checked
      };
      await this.saveConfig(newConfig);
    }
  }

  //Loading current configuration from local DB
  public getConfig(): ConfigModel {
    //console.log(JSON.parse(this.cookieService.get("config")) as ConfigModel);
    if (localStorage.getItem("config") === null) {
      return;
    }
    //console.log(JSON.parse(localStorage.getItem("config")) as ConfigModel);
    var config = JSON.parse(localStorage.getItem("config")) as ConfigModel;
    if (config.returnHighlighting == undefined) {
      config.returnHighlighting = 'All';
    }
    return config;
  }

  //Saving config changes to DB and directly return the new config
  public async saveConfig(config: ConfigModel) {
    console.log("Saving Config:", config);
    // this.cookieService.set(
    //   "config",
    //   JSON.stringify(config) /* ,
    //   new Date("12-12-2020"),
    //   "/",
    //   "",
    //   true,
    //   "Strict" */
    // );
    localStorage.setItem("config", JSON.stringify(config));
    console.log(
      "Updated Config successful",
      localStorage.getItem("config") !== null
    );
    // this.setFontSize(config);
    this.config = config;
    this.configLoaded.next(true);
  }

  public deleteConfig() {
    localStorage.removeItem("config");
    console.log("DELETE COOKIES");
    this.config = null;
  }

  /* Sets Global Font Size, either large or standard */
  // setFontSize(config: ConfigModel) {
  //   let element: HTMLHtmlElement;
  //   console.log(config.fontSize);
  //   if (config.fontSize === "standard") {
  //     element = document.getElementsByTagName("html")[0];
  //     element.style.fontSize = "1rem";
  //   } else {
  //     element = document.getElementsByTagName("html")[0];
  //     element.style.fontSize = "1.15rem";
  //   }
  // }

  public changeFont() {
    let element: HTMLHtmlElement;
    if (this.config.fontSize === "standard") {
      element = document.getElementsByTagName("html")[0];
      element.style.fontSize = "100%";
    } else {
      element = document.getElementsByTagName("html")[0];
      element.style.fontSize = "110%";
    }
  }

  reorderColumns(columnVariable: string, event: CdkDragDrop<string[]>) {
    let tempMovedElement = this.config[columnVariable][event.previousIndex];
    console.log(tempMovedElement);
    if (event.previousIndex < event.currentIndex) {
      for (let i = event.previousIndex; i <= event.currentIndex; i++) {
        this.config[columnVariable][i] = this.config[columnVariable][i + 1];
      }
    } else {
      for (let i = event.previousIndex; i >= event.currentIndex; i--) {
        this.config[columnVariable][i] = this.config[columnVariable][i - 1];
      }
    }
    this.config[columnVariable][event.currentIndex] = tempMovedElement;
    this.saveConfig(this.config);
    this.newOrder.next(true);
  }

  setAllColumnsToVisible(config: ConfigModel) {
    for (let key in config) {
      if (key.toLowerCase().includes("columns".toLowerCase())) {
        for (let column of config[key]) {
          column.display = true;
        }
      }
    }
  }

}
