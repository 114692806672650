<doka-button class="openDatePickerButton" (click)="picker.open()"
  >{{
    dateStart != null && dateEnd != null
      ? (dateStart | date) + " - " + (dateEnd | date)
      : ("DATE_FILTER_SELECT" | i18nextEager)
  }}<doka-icon
    *ngIf="dateStart === null && dateEnd === null"
    name="calendar_month"
    size="xl"
    type="filled"
  ></doka-icon
></doka-button>
<mat-date-range-picker color="blue" #picker>
  <mat-date-range-picker-actions>
    <div class="pickerButtons">
      <doka-button
        *ngIf="dateStart === null || dateEnd === null"
        matDateRangePickerCancel
        >{{ "CANCEL" | i18nextEager }}</doka-button
      >
      <doka-button
        *ngIf="!(dateStart == null || dateEnd === null)"
        (click)="deleteDate()"
        >{{ "DELETE" | i18nextEager }}</doka-button
      >
      <doka-button color="primary" matDateRangePickerApply>{{
        "DATE_PICKER_DONE" | i18nextEager
      }}</doka-button>
    </div>
  </mat-date-range-picker-actions>
</mat-date-range-picker>
<mat-date-range-input #dateRange hidden="true" [rangePicker]="picker">
  <input matStartDate #dateRangeStart />
  <input
    matEndDate
    #dateRangeEnd
    (dateChange)="dateChanged(dateRangeStart, dateRangeEnd)"
  />
</mat-date-range-input>
