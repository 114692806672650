export enum Overview {
    NoApp = "NoApp",
    Return = "Return",
    Picking = "Picking",
    Outbound = "Outbound",
    Reconditioning = "Reconditioning",
    DeliveryPicking = "Delivery Picking",
    DeliveryTransportOrder = "Delivery Transport Order",
    ReturnDelivery = "Return Delivery",
    Inbound = "Inbound"
}