import { UserPermissionRequestRole } from "./userPermissionRequestRole";

export class UserPermissionRequest {

    constructor(
        userId: string,
        firstName: string,
        lastName: string,
        userName: string,
        requestedRoles: UserPermissionRequestRole[]
    ) {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
        this.requestedRoles = requestedRoles;
    }

    userId: string;
    firstName: string;
    lastName: string;
    userName: string;
    requestedRoles: UserPermissionRequestRole[];
}