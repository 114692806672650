import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ArticleImageComponent } from '../dashboard/item-overview/article-image/article-image.component';
import { DokaToastService, ToastType } from '@doka-shared/common';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private dokaToastService: DokaToastService
  ) { }

  public async displayHttpError(err: HttpErrorResponse) {
    let toastEl = await this.dokaToastService.create({
      title: err.name + " " + err.status,
      error: err
    }, ToastType.Alert, 5);
    toastEl.show();
  }

  public navigateToVoucherDetails(voucherType: "RD" | "RO" | "CM" | "TO" | "IO", voucherGuid: string) {
    this.navCtrl.navigateForward(['/', 'voucher-detail'], { queryParams: { voucherType: voucherType, voucherGuid: voucherGuid } });
  }

  public generateArticleImageUrl(articleIdentifier: string) {
    return environment.groupAPIUrl + "/articles/" + articleIdentifier + "/assets/images?type=Rendering&renditionType=Thumbnail";
  }

  public async openArticleImage(articleIdentifier: string) {
    let modalEl = await this.modalCtrl.create({
      component: ArticleImageComponent,
      componentProps: {
        articleIdentifier: articleIdentifier
      }
    });
    await modalEl.present();
  }
}
