import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from '../enum/measurementSystem.enum';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {

  transform(value: number, convertTo: MeasurementSystem, includeUnit: boolean = true): string {
    if (convertTo === MeasurementSystem.Metric) {
      let weightString = "";
      if (includeUnit) {
        weightString = (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString() + " kg";
      } else {
        weightString = (Math.round((value + Number.EPSILON) * 100) / 100).toString();
      }
      return weightString;
    } else if (convertTo === MeasurementSystem.Imperial) {
      let weightString = "";
      if (includeUnit) {
        weightString = (Math.round(((value / 0.45359237) + Number.EPSILON) * 100) / 100).toLocaleString() + " lbs";
      } else {
        weightString = (Math.round(((value / 0.45359237) + Number.EPSILON) * 100) / 100).toString();
      }
      return weightString;
    } else {
      let weightString = "";
      if (includeUnit) {
        weightString = (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString() + " kg";
      } else {
        weightString = (Math.round((value + Number.EPSILON) * 100) / 100).toString();
      }
      return weightString;
    }
  }

}
