<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ reconOrder.mobileReconditioningStatus | i18nextEager }}
      {{ reconOrder.reconditioningOrderIdentifier }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="firstColumn">
    <table>
      <tr>
        <td class="headerLabel">
          <b>{{ "YARDCAM_LINKS" | i18nextEager }}:</b>
        </td>
        <td class="headerValue">
          <a
            (click)="
              openYardcam('RO', reconOrder.reconditioningOrderIdentifier)
            "
          >
            {{ "NEW_YC_LINK" | i18nextEager }}
          </a>
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "plannedFinishDate" | i18nextEager }}:</b>
        </td>
        <td>
          {{ reconOrder.plannedFinishDate | date }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "type" | i18nextEager }}:</b>
        </td>
        <td>
          {{ reconOrder.type | i18nextEager }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "countType" | i18nextEager }}:</b>
        </td>
        <td>
          {{ reconOrder.countType | i18nextEager }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "canItemsBeAdded" | i18nextEager }}</b>
        </td>
        <td>
          {{
            reconOrder.canItemsBeAdded
              ? ("YES" | i18nextEager)
              : ("NO" | i18nextEager)
          }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "customer" | i18nextEager }}:</b>
        </td>
        <td class="headerValue">
          @if(reconOrder.customer) {
          {{ reconOrder.customer?.customerIdentifier }}
          {{ reconOrder.customer?.name }}
          } @else { - }
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "project" | i18nextEager }}:</b>
        </td>
        <td class="headerValue">
          @if(reconOrder.project) {
          {{ reconOrder.project?.projectIdentifier }}
          {{ reconOrder.project?.name }}
          } @else { - }
        </td>
      </tr>
    </table>
  </div>
  <div class="secondColumn">
    <table>
      <tr>
        <td class="headerLabel">
          <b>{{ "departureYardIdentifier" | i18nextEager }}:</b>
        </td>
        <td>
          {{ reconOrder.departureYardIdentifier }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "receptionYardIdentifier" | i18nextEager }}:</b>
        </td>
        <td>
          {{ reconOrder.receptionYardIdentifier }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "alternativeYardIdentifier" | i18nextEager }}:</b>
        </td>
        <td>
          {{
            reconOrder.alternativeYardIdentifier
              ? reconOrder.alternativeYardIdentifier
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "qualityStandardCleaning" | i18nextEager }}:</b>
        </td>
        <td>
          {{
            reconOrder.qualityStandardCleaning
              ? reconOrder.qualityStandardCleaning
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "qualityStandardPlywood" | i18nextEager }}:</b>
        </td>
        <td>
          {{
            reconOrder.qualityStandardPlywood
              ? reconOrder.qualityStandardPlywood
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "qualityStandardFrame" | i18nextEager }}:</b>
        </td>
        <td>
          {{
            reconOrder.qualityStandardFrame
              ? reconOrder.qualityStandardFrame
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td class="headerLabel">
          <b>{{ "qualityStandardAdditionalText" | i18nextEager }}:</b>
        </td>
        <td>
          {{
            reconOrder.qualityStandardAdditionalText
              ? reconOrder.qualityStandardAdditionalText
              : "-"
          }}
        </td>
      </tr>
    </table>
  </div>
</ion-content>
