import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TransportOrder } from '../models/transport-order';
import { TransportOrderService } from '../services/transport-order.service';

@Component({
  selector: 'app-outbound-header',
  templateUrl: './outbound-details.component.html',
  styleUrls: ['./outbound-details.component.scss']
})
export class OutboundDetailsComponent implements OnInit {

  tempTransportOrder: TransportOrder = new TransportOrder();

  constructor(
    private transportOrderService: TransportOrderService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
    Object.assign(this.tempTransportOrder,this.transportOrderService.currentTransportOrder);
    console.log(this.tempTransportOrder);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  openYardcam(type: string, number: string) {
    window.open(environment.yardCamLink + '?voucherType=' + type + '&searchText=' + number,'_blank');
  }

  getAllPickingListIds() {
    let pickingListIds: string = "";
    this.tempTransportOrder.pickingLists.forEach((currentPicking,index) => {
      if((index+1) == this.tempTransportOrder.pickingLists.length) {
        pickingListIds = pickingListIds + currentPicking.pickingListId;
      } else {
        pickingListIds = pickingListIds + currentPicking.pickingListId + ",";
      }
    });
    return pickingListIds;
  }

  getCommissionLocations() {
    const commissionLocations = [];
    for (const picking of this.tempTransportOrder.pickingLists) {
      if (
        !commissionLocations.includes(picking.commissionLocation) &&
        picking.commissionLocation !== ""
      ) {
        commissionLocations.push(picking.commissionLocation);
      }
    }
    return commissionLocations;
  }

  getArticleCount() {
    let count = 0;
    for (const picking of this.tempTransportOrder.pickingLists) {
      count += picking.items.length;
    }
    return count;
  }

  getWeight() {
    let weight = 0;
    for (const picking of this.tempTransportOrder.pickingLists) {
      weight += picking.weight;
    }
    return weight;
  }

}
