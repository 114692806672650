import { FormsModule } from '@angular/forms';
import { NavigationComponent } from './navigation/navigation.component';
import { Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { AddItemComponent } from './add-item/add-item.component';
import { PickingDetailsComponent } from './picking-details/picking-details.component';
import { OutboundDetailsComponent } from './outbound-details/outbound-details.component';
import { ReconDetailsComponent } from './recon-details/recon-details.component';
import { ReturnDetailsComponent } from './return-details/return-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePickerModule } from './date-picker/date-picker.module';
import { DateRangeFilterModule } from './date-range-filter/date-range-filter.module';
import { DatePipe } from '@angular/common';
import { CoreModule } from './modules/core.module';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './i18n';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { WeightPipe } from '@doka-shared/common';

export let AppInjector: Injector;

@NgModule({
    declarations: [AppComponent, NavigationComponent, AddItemComponent, PickingDetailsComponent, OutboundDetailsComponent, ReconDetailsComponent, ReturnDetailsComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        DatePickerModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
                allowedUrls: ['https://yardmanager.dev.doka.com/']
            }
        }),
        FormsModule,
        BrowserAnimationsModule,
        DateRangeFilterModule,
        CoreModule,
        I18NextModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })], providers: [
            CookieService,
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            WeightPipe,
            DatePipe,
            { provide: OAuthStorage, useValue: localStorage },
            I18N_PROVIDERS,
            { provide: LOCALE_ID, useValue: 'en' },
            { provide: MAT_DATE_LOCALE, useValue: 'en' },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
