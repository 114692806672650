import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Role } from 'src/app/models/role';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-permission-info',
  templateUrl: './permission-info.component.html',
  styleUrls: ['./permission-info.component.scss'],
})
export class PermissionInfoComponent implements OnInit {

  protected roles: Role[] = [];
  protected isLoadingRoles: boolean = false;

  constructor(
    private modalController: ModalController,
    private webService: WebService,
    private utilitiesService: UtilitiesService) {
  }

  async ngOnInit() {
    this.isLoadingRoles = true;
    try {
      this.roles = await this.webService.getRoles(1000, 0);
      this.roles = this.roles.filter(r => r.canBeRequested);
    } catch (error) {
      this.utilitiesService.displayHttpError(error);
    }
    console.log(this.roles);
    this.isLoadingRoles = false;
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
