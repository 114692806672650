import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Recon } from '../models/recon';
import { ReconService } from '../services/recon.service';

@Component({
  selector: 'app-recon-header',
  templateUrl: './recon-details.component.html',
  styleUrls: ['./recon-details.component.scss']
})
export class ReconDetailsComponent implements OnInit {

  tempRecon: Recon = new Recon();

  constructor(
    private reconService: ReconService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
    Object.assign(this.tempRecon,this.reconService.currentRecon);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  openYardcam(type: string, number: string) {
    window.open(environment.yardCamLink + '?voucherType=' + type + '&searchText=' + number,'_blank');
  }
  
}
