export class BranchConfiguration {
    isPickupPossible: boolean = false;
    firstDayOfWeek: string = "";
    delivery: {
        deliveryLeadTimeInDays: number;
        pickupLeadTimeInDays: number;
        isMandatoryDocumentProcessEnabled: boolean
    } = { deliveryLeadTimeInDays: 0, pickupLeadTimeInDays: 0, isMandatoryDocumentProcessEnabled: false };
    returnDelivery: {
        deliveryLeadTimeInDays: number;
        pickupLeadTimeInDays: number;
        isFourEyesPrincipleRequired: boolean;
        isAIAssistanceAllowed: boolean;
        isPullingEnabled: boolean;
        version: "V1" | "V2";
    } = { deliveryLeadTimeInDays: 0, pickupLeadTimeInDays: 0, isFourEyesPrincipleRequired: false, isAIAssistanceAllowed: false, isPullingEnabled: false, version: "V1" };
    loading: {
        isCheckingRequired: boolean
    } = { isCheckingRequired: false };
    picking: {
        isPackageRequired: boolean
    } = { isPackageRequired: false };
}