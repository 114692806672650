<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "LOADERS" | i18nextEager }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="saveLoaders()">
        <doka-icon
          name="save"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list line="none">
    <ion-item *ngFor="let loader of loaders">
      {{ loader.firstName }} {{ loader.lastName }}
      <doka-icon
        (click)="removeLoader(loader)"
        class="removePickerIcon"
        name="do_not_disturb_on"
        size="2xl"
        type="filled"
        slot="end"
      ></doka-icon>
    </ion-item>
  </ion-list>
</ion-content>
