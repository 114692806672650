<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "picker" | i18nextEager }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="savePicker()">
        <doka-icon
          name="save"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="none">
    <ion-item *ngFor="let picker of activePickers">
      {{ picker.userFirstname }} {{ picker.userLastname }}
      <doka-icon
        (click)="removePicker(picker)"
        class="removePickerIcon"
        name="do_not_disturb_on"
        size="2xl"
        type="filled"
        slot="end"
      ></doka-icon>
    </ion-item>
    <ion-item (click)="openAddPicker()" button>
      <doka-icon
        class="addPicker"
        name="add_circle"
        size="2xl"
        type="filled"
      ></doka-icon>
    </ion-item>
  </ion-list>
</ion-content>
