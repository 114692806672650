import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { PickersListComponent } from '../pickers-list/pickers-list.component';
import { LoadersListComponent } from '../loaders-list/loaders-list.component';
import { WeightPipe } from '../pipes/weight.pipe';
import { AddPickerComponent } from '../pickers-list/add-picker/add-picker.component';
import { DokaCommonModule } from '@doka-shared/common';
import { I18NextModule } from 'angular-i18next';
import { CustomDateAdapter } from '../helper/custom-date-adapter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    DokaCommonModule,
    I18NextModule
  ],
  declarations: [
    CheckboxComponent,
    PickersListComponent,
    LoadersListComponent,
    WeightPipe,
    AddPickerComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CheckboxComponent,
    PickersListComponent,
    LoadersListComponent,
    WeightPipe,
    DatePipe,
    AddPickerComponent,
    DokaCommonModule,
    I18NextModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class CoreModule { }