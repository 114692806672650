// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: "1.0.13",
  returnDbUrl: "https://appsvc2.doka.com/return_stage",
  pickingDbUrl: "https://appsvc2.doka.com/picking_stage",
  outboundDbUrl: "https://appsvc2.doka.com/outbound_stage",
  reconDbUrl: "https://appsvc2.doka.com/reconditioning_stage",
  loginUrl: "https://appsvc2.doka.com/yardcam_stage/appVersion",
  tokenDbUrl: "https://appsvc2.doka.com/token_auth",
  yardManagerDbUrl: "https://appsvc2.doka.com/yardmanager",
  returnExportUrl: "https://appsvc.doka.com/dokareturn-test/ReturnDeliveryApp.svc/postCountList",
  groupAPIUrl: "https://api.test.doka.com",
  groupIdUrl: "https://login.test.doka.com",
  groupIdContractor: "f6b76130-43a4-ec11-a22a-a085fc48656d",
  groupIdContractorUmdasch: "079cb8b8-aae0-47f0-8cf8-bccb4984f0bb",
  groupIdGroupReconditioning: "",
  yardCamLink: "https://yardcam.dev.doka.com/gallery/",
  idAppLink: "https://id.test.doka.com",
  odlDbUrl: "https://azwe-app-dev-ym.azurewebsites.net/",
  returnExportUrlOwnMaterial: "https://appsvc.doka.com/dokareturn-test/ReturnDeliveryApp.svc/postCountListOwnMaterial",
  yardAPIUrl: "https://yard-api.dev.doka.com/api",
  yardQCUrl: "https://yardqualitycheck.test.doka.com",
  identityServerClientId: "6c8b14e7-a8d1-4e4d-8027-a63335ed71a2",
  translationsApiBaseUrl: "https://yard-api.dev.doka.com",
  clientId: "6c8b14e7-a8d1-4e4d-8027-a63335ed71a2",
  emiotUrl: "https://emiot.test.doka.com/",
  emUrl: "https://em.doka.com/betriebsmittelverwaltung/test/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
