<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS' | i18nextEager }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <ion-item-group>
      <ion-item-divider> {{ "USER_INFO" | i18nextEager }} </ion-item-divider>
      <ion-item>
        <ion-label>{{ "PROFILE_PICTURE" | i18nextEager }}</ion-label>
        <ion-thumbnail class="profilePicture">
          <img
            [src]="authenticationService.profilePictureUrl !== null ? authenticationService.profilePictureUrl : 'assets/imgs/user_default.png'"
          />
        </ion-thumbnail>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>
        {{ "SETTINGS_INFO" | i18nextEager }}
      </ion-item-divider>
      <ion-item>
        <ion-label>{{ "VERSION" | i18nextEager }}</ion-label>
        <ion-label class="ion-text-right">{{ appVersion }}</ion-label>
      </ion-item>
      <ion-item-divider>
        {{ 'SETTINGS_LANGUAGE' | i18nextEager }}
      </ion-item-divider>
      <ion-item>
        <ion-label>{{ 'SETTINGS_APPLANGUAGE' | i18nextEager }}</ion-label>
        <ion-select
          [(ngModel)]="cfgService.config.appLanguage"
          (ionChange)="onChange()"
          [okText]="'OK' | i18nextEager"
          [cancelText]="'CANCEL' | i18nextEager"
        >
          <ion-select-option
            [value]="currentLang"
            *ngFor="let currentLang of cfgService.appLanguages"
            >{{ currentLang | i18nextEager }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-item-divider>
        {{ 'SETTINGS_CONTENT' | i18nextEager }}
      </ion-item-divider>
    </ion-item-group>
    <ion-item>
      <ion-label>{{ 'SETTINGS_FONTSIZE' | i18nextEager }}</ion-label>
      <ion-select
        [(ngModel)]="cfgService.config.fontSize"
        (ionChange)="onFontChange()"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option value="standard"
          >{{ 'SETTINGS_FONT_STANDARD' | i18nextEager }}</ion-select-option
        >
        <ion-select-option value="large"
          >{{ 'SETTINGS_FONT_BIG' | i18nextEager }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{ "MEASUREMENT_SYSTEM" | i18nextEager }}</ion-label>
      <ion-select
        [(ngModel)]="cfgService.config.measurementSystem"
        (ionChange)="onChange()"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="measurementSystem.Metric">
          {{ "METRIC" | i18nextEager }}
        </ion-select-option>
        <ion-select-option [value]="measurementSystem.Imperial">
          {{ "IMPERIAL" | i18nextEager }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item-divider>
      {{ "RETURN_SETTINGS" | i18nextEager }}
    </ion-item-divider>
    <ion-item>
      <ion-label>{{ 'HIGHLIGHTING' | i18nextEager }}</ion-label>
      <ion-select
        [(ngModel)]="cfgService.config.returnHighlighting"
        (ionChange)="onChange()"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option value="All"
          >{{ 'ALL' | i18nextEager }}</ion-select-option
        >
        <ion-select-option value="Reduced"
          >{{ 'REDUCED' | i18nextEager }}</ion-select-option
        >
        <ion-select-option value="None"
          >{{ 'NONE' | i18nextEager }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item-divider>
      {{ "OVERVIEW_SETTINGS" | i18nextEager }}
    </ion-item-divider>
    <ion-item>
      <ion-label>{{ "FIRST" | i18nextEager }}</ion-label>
      <ion-select
        (ionChange)="onChange()"
        [(ngModel)]="cfgService.config.overviewFirst"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="overview.NoApp"
          >{{ overview.NoApp | i18nextEager }}</ion-select-option
        >
        <ion-select-option>{{ overview.Reconditioning }}</ion-select-option>
        <ion-select-option>{{ overview.DeliveryPicking }}</ion-select-option>
        <ion-select-option
          >{{ overview.DeliveryTransportOrder }}</ion-select-option
        >
        <ion-select-option>{{ overview.ReturnDelivery }}</ion-select-option>
        <ion-select-option>{{ overview.Inbound }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{ "SECOND" | i18nextEager }}</ion-label>
      <ion-select
        (ionChange)="onChange()"
        [(ngModel)]="cfgService.config.overviewSecond"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="overview.NoApp"
          >{{ overview.NoApp | i18nextEager }}</ion-select-option
        >
        <ion-select-option>{{ overview.Reconditioning }}</ion-select-option>
        <ion-select-option>{{ overview.DeliveryPicking }}</ion-select-option>
        <ion-select-option
          >{{ overview.DeliveryTransportOrder }}</ion-select-option
        >
        <ion-select-option>{{ overview.ReturnDelivery }}</ion-select-option>
        <ion-select-option>{{ overview.Inbound }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{ "THIRD" | i18nextEager }}</ion-label>
      <ion-select
        (ionChange)="onChange()"
        [(ngModel)]="cfgService.config.overviewThird"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="overview.NoApp"
          >{{ overview.NoApp | i18nextEager }}</ion-select-option
        >
        <ion-select-option>{{ overview.Reconditioning }}</ion-select-option>
        <ion-select-option>{{ overview.DeliveryPicking }}</ion-select-option>
        <ion-select-option
          >{{ overview.DeliveryTransportOrder }}</ion-select-option
        >
        <ion-select-option>{{ overview.ReturnDelivery }}</ion-select-option>
        <ion-select-option>{{ overview.Inbound }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{ "FOURTH" | i18nextEager }}</ion-label>
      <ion-select
        (ionChange)="onChange()"
        [(ngModel)]="cfgService.config.overviewFourth"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="overview.NoApp"
          >{{ overview.NoApp | i18nextEager }}</ion-select-option
        >
        <ion-select-option>{{ overview.Reconditioning }}</ion-select-option>
        <ion-select-option>{{ overview.DeliveryPicking }}</ion-select-option>
        <ion-select-option
          >{{ overview.DeliveryTransportOrder }}</ion-select-option
        >
        <ion-select-option>{{ overview.ReturnDelivery }}</ion-select-option>
        <ion-select-option>{{ overview.Inbound }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{ "PICKING_SLIDE_SETTING" | i18nextEager }}</ion-label>
      <ion-select
        (ionChange)="onChange()"
        [(ngModel)]="cfgService.config.pickingSlide"
        [okText]="'OK' | i18nextEager"
        [cancelText]="'CANCEL' | i18nextEager"
      >
        <ion-select-option [value]="pickingSlide.Canceled"
          >{{ "CANCELED" | i18nextEager }}</ion-select-option
        >
        <ion-select-option [value]="pickingSlide.Checked"
          >{{ "CHECKED" | i18nextEager }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </ion-list>
</ion-content>
