import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-add-picker',
  templateUrl: './add-picker.component.html',
  styleUrls: ['./add-picker.component.scss'],
})
export class AddPickerComponent implements OnInit {

  @Input("excludedUsers") excludedUsers: { userId: string, userFirstname: string, userLastname: string, pickingListIds: string[] }[] = [];
  @Input("loadUsersRole") loadUsersRole: string;
  isLoadingUsers: boolean = false;
  searchText: string = "";
  users: User[] = [];
  usersBuffer: User[] = [];

  constructor(
    private modalCtrl: ModalController,
    private webService: WebService
  ) { }

  ngOnInit() {
    this.loadUsers();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async loadUsers() {
    this.users = [];
    this.usersBuffer = [];
    this.isLoadingUsers = true;
    this.usersBuffer = await this.webService.getUsersByBranchIdAndRolename(this.loadUsersRole);
    for (let users of this.usersBuffer) {
      this.webService.getUserById(users.id).then(u => users.profilePictureUri = u.profilePictureUri);
    }
    console.log(this.excludedUsers);
    this.usersBuffer = this.usersBuffer.filter(u => {
      return this.excludedUsers.findIndex(user => user.userId === u.id) === -1;
    });
    console.log(this.usersBuffer);
    this.isLoadingUsers = false;
    this.searchUsers();
  }

  searchUsers() {
    this.users = this.usersBuffer.filter(u =>
      u.firstName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      u.lastName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      u.userName?.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  selectUser(user: User) {
    this.modalCtrl.dismiss(user);
  }

}
