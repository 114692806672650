import { AdminService } from './../services/admin.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConfigService } from "./../services/config.service";
import { AuthenticationService } from "./../services/authentication.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoadingController, NavController } from "@ionic/angular";

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { DokaI18NextService } from '@doka-shared/i18next';

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard {
  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;

  constructor(
    private navCtrl: NavController,
    private authentication: AuthenticationService,
    private configService: ConfigService,
    private oauthService: OAuthService,
    private adminService: AdminService,
    private loadingCtrl: LoadingController,
    private translate: DokaI18NextService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //If there is no token set on the device, the Login page will be the first page
    return new Promise(async (resolve, reject) => {
      let loadingEl = await this.loadingCtrl.create({
        message: this.translate.instant("LOADING_PAGE") === "LOADING_PAGE" ? "Loading page" : this.translate.instant("LOADING_PAGE")
      });
      loadingEl.present();
      const config = this.configService.getConfig();
      const claims = this.oauthService.getIdentityClaims();
      await this.adminService.loadPermissions(config.branchId);
      if (config && config.clientid && !!claims && this.adminService.userRoles.length > 0 && this.oauthService.hasValidAccessToken()) {
        this.authentication.autoLogin(config.clientid);
        let canAccessSite = await this.adminService.canAccessSiteAsync(route.routeConfig.path);
        if (canAccessSite) {
          resolve(true);
        } else {
          this.navCtrl.navigateForward("/dashboard");
        }
      } else {
        if (!state.url.includes("login") && config.clientid) {
          this.authentication.oauthLogin(window.location.origin + state.url);
          resolve(true);
        } else {
          this.navCtrl.navigateRoot("/login");
          resolve(false);
        }
      }
      loadingEl.dismiss();
    });
  }
}
