import { Branch } from "./branch";
import { Customer } from "./customer";
import { Project } from "./project";
import { ReconItem } from "./reconItem";
import { User } from "./user";

export class Recon {
    id: string;
    branchIdentifier: string;
    reconditioningOrderIdentifier: string;
    plannedFinishDate: string;
    departureYardIdentifier: string;
    receptionYardIdentifier: string;
    alternativeYardIdentifier: string;
    type: string;
    netWeight: number;
    doneWeight: number;
    qualityStandardCleaning: string;
    qualityStandardPlywood: string;
    qualityStandardFrame: string;
    qualityStandardAdditionalText: string;
    printCustomerName: true;
    sendMaterialToCustomer: string;
    countType: string;
    licensePlateTruck: string;
    unloadingLocation: string;
    truckArrivalTime: string;
    truckDepartureTime: string;
    initialCountEmployee: string;
    canItemsBeAdded: true;
    status: string;
    mobileReconditioningStatus: string;
    reconditioningStatus: string;
    canCustomerBeDefect: boolean;
    canICBeDefectCustomer: boolean;
    canICBeDefectDoka: boolean;
    canStandardBeDefectCustomer: boolean;
    canStandardBeDefectDoka: boolean;
    project: Project;
    customer: Customer;
    branch: Branch;
    items: ReconItem[];
    isLocked: boolean;
    lockedByUser: User;
}