export class User {

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        userName: string
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
    }

    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    profilePictureUri: string;
    state: string;
    email: string;
}