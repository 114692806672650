export class PostPermissionRequest {

    constructor(
        userId: string,
        roleId: string,
        branchId: string
    ) {
        this.userId = userId;
        this.roleId = roleId;
        this.branchId = branchId;
    }

    userId: string;
    roleId: string;
    branchId: string;
}