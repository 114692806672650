import { AuthenticationService } from './../services/authentication.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Role } from '../models/role';
import { Branch } from '../models/branch';
import { AddPermissionRequestRoleComponent } from './add-permission-request-role/add-permission-request-role.component';
import { WebService } from '../services/web.service';
import { PostPermissionRequest } from '../models/postPermissionRequest';
import { DokaI18NextService } from '@doka-shared/i18next';
import { PermissionInfoComponent } from '../user-management/permission-info/permission-info.component';

@Component({
  selector: 'app-request-permission',
  templateUrl: './request-permission.component.html',
  styleUrls: ['./request-permission.component.scss'],
})
export class RequestPermissionComponent implements OnInit {

  roleBranches: {
    role: Role,
    branches: Branch[]
  }[] = [];

  selectedRoleBranch: {
    role: Role,
    branches: Branch[]
  } = null;

  branches: Branch[] = [];
  isLoadingBranches: boolean = false;

  errorRoles: Role[] = [];

  constructor(
    public authenticationService: AuthenticationService,
    private modalCtrl: ModalController,
    private webService: WebService,
    private loadingCtrl: LoadingController,
    private translate: DokaI18NextService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.loadBranches();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async openPermissionInfo() {
    let modalEl = await this.modalCtrl.create({
      component: PermissionInfoComponent,
      componentProps: {}
    });
    await modalEl.present();
  }

  async openAddRole() {
    let existingRoles: Role[] = [];
    for (let roleBranch of this.roleBranches) {
      existingRoles.push(roleBranch.role);
    }
    let modalEl = await this.modalCtrl.create({
      component: AddPermissionRequestRoleComponent,
      componentProps: {
        existingRoles: existingRoles
      }
    });
    modalEl.onDidDismiss().then(result => {
      if (result.data) {
        this.roleBranches.push({
          role: result.data.role,
          branches: []
        });
        this.selectedRoleBranch = this.roleBranches.find(rb => rb.role.id === result.data.role.id);
      }
    });
    await modalEl.present();
  }

  async loadBranches() {
    this.isLoadingBranches = true;
    this.branches = await this.webService.getBranches(1000, 0);
    this.branches.sort((a, b) => parseInt(a.branchIdentifier) - parseInt(b.branchIdentifier));
    this.isLoadingBranches = false;
  }

  selectRole(roleBranch: { role: Role, branches: Branch[] }) {
    this.selectedRoleBranch = roleBranch;
  }

  removeRole(roleBranch: { role: Role, branches: Branch[] }) {
    let roleBranchIndex = this.roleBranches.findIndex(rb => rb.role.id === roleBranch.role.id);
    if (this.selectedRoleBranch.role.id === roleBranch.role.id) {
      this.selectedRoleBranch = null;
    }
    this.roleBranches.splice(roleBranchIndex, 1);
  }

  roleBranchContainsBranch(branch: Branch) {
    return this.selectedRoleBranch.branches.findIndex(rb => rb.id === branch.id) !== -1;
  }

  selectBranch(branch: Branch) {
    if (this.roleBranchContainsBranch(branch)) {
      let branchIndex = this.selectedRoleBranch.branches.findIndex(b => b.id === branch.id);
      this.selectedRoleBranch.branches.splice(branchIndex, 1);
    } else {
      this.selectedRoleBranch.branches.push(branch);
    }
  }

  async savePermissionRequest() {
    this.errorRoles = [];
    for (let roleBranch of this.roleBranches) {
      if (roleBranch.branches.length === 0) {
        this.errorRoles.push(roleBranch.role);
      }
    }
    if (this.errorRoles.length === 0) {
      let loadingEl = await this.loadingCtrl.create({
        message: this.translate.instant("PERMISSION_REQUEST_LOADING"),
        spinner: 'crescent'
      });
      await loadingEl.present();
      let permissionRequests: PostPermissionRequest[] = [];
      for (let roleBranch of this.roleBranches) {
        for (let branch of roleBranch.branches) {
          let permissionRequest = new PostPermissionRequest(this.authenticationService.userId, roleBranch.role.id, branch.id);
          permissionRequests.push(permissionRequest);
        }
      }
      for (let permissionRequest of permissionRequests) {
        await this.webService.addPermissionRequest(permissionRequest);
      }
      await loadingEl.dismiss();
      let alertEl = await this.alertCtrl.create({
        header: this.translate.instant("PERMISSION_ALERT_HEADER"),
        message: this.translate.instant("PERMISSION_ALERT_MESSAGE"),
        buttons: [
          {
            text: this.translate.instant("OK")
          }
        ]
      });
      await alertEl.present();
      this.dismissModal();
    }
  }

  roleHasError(role: Role) {
    return this.errorRoles.findIndex(r => r.id === role.id) !== -1;
  }

}
