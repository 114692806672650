import { ConfigService } from "./config.service";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { TransportOrder } from "./../models/transport-order";
import { Injectable, NgZone } from "@angular/core";
import { DbContext } from "./dbContext.service";
import { Return } from "../models/return";
import { Picking } from "../models/picking";
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { TonnageArticle } from "../models/tonnageArticle";
import { LocalizedArticle } from "../models/localizedArticle";
import { UserToken } from "../models/userToken";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  private isoLanguageCode: {
    branchIdentifier: string;
    isoLanguageCode: string;
    isoCountryCode: string;
  } = {
      branchIdentifier: "",
      isoLanguageCode: "",
      isoCountryCode: "",
    };

  constructor(
    private dbContext: DbContext,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private oAuthService: OAuthService,
    private configService: ConfigService
  ) {
    this.authenticationService.clientId.subscribe(() => {
      this.addLoginToDatabase();
    });
  }

  public getReturnsByClientId(clientId: string): Promise<Return[]> {
    console.log("Getting Returns for client id: " + clientId);
    return this.dbContext.returnDb
      .find({
        selector: {
          $or: [
            {
              $and: [
                {
                  clientid: {
                    $eq: clientId,
                  },
                },
                {
                  dgtargetcompany: {
                    $or: [{ $eq: "" }, { $exists: false }],
                  },
                },
              ],
            },
            {
              dgtargetcompany: {
                $eq: clientId,
              },
            },
          ],
        },
        fields: [
          "_id",
          "dateofarrival",
          "custname",
          "pamstatus",
          "pamplacenumber",
          "projectno",
          "rentalsitedescription",
          "locked",
          "lockemail",
          "transunitcode",
          "additionaltext",
          "items",
          "cleaningagreed",
          "cleaningplusagreed",
          "deviceserviceagreed",
          "formworkservicetop",
          "formworkserviceplus",
          "formworkservicebasic",
          "formworkserviceexclusive",
          "cleaningandfsbasic",
          "unloaderemplid",
          "carrieraccount",
          "transunitcode",
          "pamdateofarrival_place",
          "pamdateofdeparture_place",
          "customerdeliverynote",
          "redeliveryType",
          "custaccount"
        ],
        limit: 500,
      })
      .then((res) => {
        console.log(
          "Successfully fetched returns for client id: " + clientId,
          res.docs
        );
        return res.docs;
      })
      .catch((err) => {
        console.error("ERROR getting returns for client id: " + clientId, err);
        throw err;
      });
  }

  public getReturnsByClientIdPaginated(
    clientId: string,
    currentLength: number
  ) {
    console.log("Getting Returns starting at ", currentLength);
    return this.dbContext.returnDb
      .find({
        selector: {
          clientid: {
            $eq: clientId,
          },
        },
        limit: 40,
        skip: currentLength,
      })
      .then((res) => {
        console.log(
          "Successfully fetched returns for client id: " + clientId,
          res.docs
        );
        return res.docs;
      })
      .catch((err) => {
        console.error("ERROR getting returns for client id: " + clientId, err);
        throw err;
      });
  }

  public saveReturn(rn: Return) {
    console.log("Savin Return: " + rn._id);
    return this.dbContext.returnDb
      .get(rn._id)
      .then((res) => {
        rn._rev = res._rev;
        this.dbContext.returnDb.put(rn);
      })
      .then(() => {
        console.log("Successfully updated Return: " + rn._id);
      })
      .catch((err) => {
        console.log("ERROR saving Return: " + rn._id, err);
        throw err;
      });
  }

  public getReturnById(returnId: string) {
    console.log("Getting Return with ID", returnId);
    return this.dbContext.returnDb
      .get(returnId)
      .then((res) => {
        console.log("Successfully loaded Return", res);
        return res;
      })
      .catch((err) => {
        console.error("ERROR loading Return", err);
        throw err;
      });
  }

  public deleteReturnSparePart(returnId: string, returnArticleNumber: string, suppArticleNumber: string) {
    return this.dbContext.returnDb
      .get(returnId)
      .then((doc) => {
        let returnItemIndex = doc.items.findIndex(i => i.ItemId === returnArticleNumber);
        let suppItemIndex = doc.items[returnItemIndex].SuppItems.findIndex(s => s.SuppItemId === suppArticleNumber);
        doc.items[returnItemIndex].SuppItems.splice(suppItemIndex, 1);
        return this.dbContext.returnDb.put(doc);
      });
  }

  public getPickingsByClientId(clientId: string) {
    console.log("Getting Pickings for client id: " + clientId);
    return this.dbContext.pickingDb
      .find({
        selector: {
          $and: [
            {
              clientId: {
                $eq: clientId,
              },
            }
          ],
        },
        limit: 500,
      })
      .then((res) => {
        res.docs.sort((a, b) => {
          console.log(a.dlvDate);
          let firstDate = a.dlvDate.substring(6, a.dlvDate.length - 2);
          let secondDate = b.dlvDate.substring(6, b.dlvDate.length - 2);
          return firstDate - secondDate;
        });
        console.log(
          "Successfully fetched pickings for client id: " + clientId,
          res.docs
        );
        return res.docs;
      })
      .catch((err) => {
        console.error("ERROR getting pickings for client id: " + clientId, err);
        throw err;
      });
  }

  public getTransportOrdersByClient(clientId: string) {
    console.log("Getting Pickings for client id: " + clientId);
    return this.dbContext.outboundDb
      .find({
        selector: {
          $and: [
            {
              clientId: {
                $eq: clientId,
              },
            },
          ],
        },
        /* ,
            fields: [
                "_id",
                "salesIds",
                "dlvDate",
                "custName",
                "commissionLocation",
                "projectNo",
                "items",
                "additionalText",
                "discosText",
                "pickedCount",
                "workingCount",
                "done",
                "weight",
                "projectName",
                "picker",
                "locked"
            ] */ limit: 500,
      })
      .then((res) => {
        res.docs.sort((a, b) => {
          console.log(a.depDate);
          let firstDate = a.depDate.substring(6, a.depDate.length - 2);
          let secondDate = b.depDate.substring(6, b.depDate.length - 2);
          return firstDate - secondDate;
        });
        console.log(
          "Successfully fetched transportOrders for client id: " + clientId,
          res.docs
        );
        return res.docs;
      })
      .catch((err) => {
        console.error(
          "ERROR getting transportOrders for client id: " + clientId,
          err
        );
        throw err;
      });
  }

  public getTransportOrderById(transportOrderId: string) {
    console.log("Getting TransportOrder By Id", transportOrderId);
    return this.dbContext.outboundDb
      .get(transportOrderId)
      .then((res) => {
        console.log("Successfully got TransportOrder");
        return res;
      })
      .catch((err) => {
        console.log("There was an error getting TransportOrder by Id");
      });
  }

  public getPickingById(pickingId: string) {
    console.log("Getting Picking with ID: " + pickingId);
    return this.dbContext.pickingDb
      .get(pickingId)
      .then((res) => {
        console.log("Successfully loaded Picking", res);
        return res;
      })
      .catch((err) => {
        console.error("ERROR getting Picking", err);
        throw err;
      });
  }

  public unlockReturn(rn: Return) {
    console.log("Unlocking Return: " + rn._id);
    return this.dbContext.returnDb
      .get(rn._id)
      .then((res) => {
        res.locked = false;
        delete res.lockemail;
        return this.dbContext.returnDb.put(res);
      })
      .then(() => {
        console.log("Successfully unlocked Return: " + rn._id);
      })
      .catch((err) => {
        console.error("ERROR unlocking return: " + rn._id, err);
        throw err;
      });
  }

  public unlockPicking(picking: Picking) {
    console.log("Unlocking Picking: " + picking._id);
    return this.dbContext.pickingDb
      .get(picking._id)
      .then((res) => {
        res.locked = false;
        return this.dbContext.pickingDb.put(res);
      })
      .then(() => {
        console.log("Successfully unlocked Picking: " + picking._id);
      })
      .catch((err) => {
        console.error("ERROR unlocking picking: " + picking._id, err);
        throw err;
      });
  }

  public deletePickingWorkingCount(
    picking: Picking,
    moveTocheck: boolean = false
  ) {
    console.log("Deleting Working Count of Picking: " + picking._id);
    return this.dbContext.pickingDb
      .get(picking._id)
      .then((res) => {
        if (moveTocheck) {
          res.pickedCount = 0;
        } else {
          delete res.workingCount;
          delete res.pickedCount;
        }
        this.dbContext.pickingDb.put(res);
      })
      .then(() => {
        console.log(
          "Successfully deleted Working Count of Picking: " + picking._id
        );
      })
      .catch((err) => {
        console.error(
          "ERROR deleting Working Count of Picking: " + picking._id,
          err
        );
        throw err;
      });
  }

  public deletePicking(picking: Picking) {
    console.log("Delete Picking: " + picking._id);
    return this.dbContext.pickingDb
      .get(picking._id)
      .then((res) => {
        return this.dbContext.pickingDb.remove(res);
      })
      .catch((err) => {
        console.log("ERROR deleting Picking: " + picking._id);
        throw err;
      });
  }

  public unlockTransportOrder(transportOrder: TransportOrder) {
    console.log("Unlocking TransportOrder: " + transportOrder._id);
    return this.dbContext.outboundDb
      .get(transportOrder._id)
      .then((res) => {
        res.checkLocked = false;
        return this.dbContext.outboundDb.put(res);
      })
      .then(() => {
        console.log(
          "Successfully unlocked TransportOrder: " + transportOrder._id
        );
      })
      .catch((err) => {
        console.error(
          "ERROR unlocking transportOrder: " + transportOrder._id,
          err
        );
        throw err;
      });
  }

  public deleteTransportOrderWorkingCount(transportOrder: TransportOrder) {
    console.log(
      "Deleting Working Count of TransportOrder: " + transportOrder._id
    );
    return this.dbContext.outboundDb
      .get(transportOrder._id)
      .then((res) => {
        delete res.workingCount;
        delete res.pickedCount;
        this.dbContext.outboundDb.put(res);
      })
      .then(() => {
        console.log(
          "Successfully deleted Working Count of TransportOrder: " +
          transportOrder._id
        );
      })
      .catch((err) => {
        console.error(
          "ERROR deleting Working Count of TransportOrder: " +
          transportOrder._id,
          err
        );
        throw err;
      });
  }

  public async getTokenList(top: number, skip: number): Promise<UserToken[]> {
    let requestUrl = environment.yardAPIUrl + "/users/" + this.authenticationService.userId + "/branches?$top=" + top + "&$skip=" + skip + "&isoLanguageCode=" + this.getIsoLanguageCode();
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let couchdbTokenlist = await this.dbContext.tokenDb.get("yardcam");
    let userTokenlist = await lastValueFrom(this.http.get<UserToken[]>(requestUrl, { headers: headers }));

    for (let userToken of userTokenlist) {
      let couchDbToken = couchdbTokenlist.tokenList.find(t => t.dataareaid === userToken.branchIdentifier);
      if (couchDbToken) {
        userToken.token = couchDbToken.token;
      }
    }

    return userTokenlist;
  }

  public getAdminUsers() {
    return this.dbContext.yardManagerDb.get("adminUsers").then((res) => {
      console.log("Successfully fetched Admin User List");
      return res;
    });
  }

  public addAdminUser(email: string) {
    return this.dbContext.yardManagerDb.get("adminUsers").then((res) => {
      res.users.push(email);
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public removeAdminUser(email: string) {
    return this.dbContext.yardManagerDb.get("adminUsers").then((res) => {
      res.users = res.users.filter((value) => value != email);
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public getShiftsupervisorUsers() {
    return this.dbContext.yardManagerDb.get("shiftSupervisorUsers").then((res) => {
      console.log("Successfully fetched Shift Supervisor User List");
      return res;
    });
  }

  public addShiftsupervisorUser(email: string, tokenList?: string[]) {
    return this.dbContext.yardManagerDb.get("shiftSupervisorUsers").then((res) => {
      res.users.push(email);
      if (tokenList && tokenList.length !== 0) {
        res[email] = tokenList;
      } else {
        res[email] = [];
      }
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public removeShiftsupervisorUser(email: string) {
    return this.dbContext.yardManagerDb.get("shiftSupervisorUsers").then((res) => {
      res.users = res.users.filter((value) => value != email);
      delete res[email];
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public getKioskUsers() {
    return this.dbContext.yardManagerDb.get("kioskUsers").then((res) => {
      console.log("Successfully fetched Kiosk User List");
      return res;
    });
  }

  public addKioskUser(email: string) {
    return this.dbContext.yardManagerDb.get("kioskUsers").then((res) => {
      res.users.push(email);
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public removeKioskUser(email: string) {
    return this.dbContext.yardManagerDb.get("kioskUsers").then((res) => {
      res.users = res.users.filter((value) => value != email);
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public getKeyUsers() {
    return this.dbContext.yardManagerDb.get("keyUsers").then((res) => {
      console.log("Successfully fetched Key User List");
      return res;
    });
  }

  public addKeyUser(email: string, tokenList?: string[]) {
    return this.dbContext.yardManagerDb.get("keyUsers").then((res) => {
      res.users.push(email);
      if (tokenList && tokenList.length !== 0) {
        res[email] = tokenList;
      } else {
        res[email] = [];
      }
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public removeKeyUser(email: string) {
    return this.dbContext.yardManagerDb.get("keyUsers").then((res) => {
      res.users = res.users.filter((value) => value != email);
      delete res[email];
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public saveKeyUserToken(email: string, tokenList) {
    console.log(email);
    console.log(tokenList);
    return this.dbContext.yardManagerDb.get("keyUsers").then((res) => {
      res[email] = tokenList;
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public saveShiftSupervisorToken(email: string, tokenList) {
    return this.dbContext.yardManagerDb.get("shiftSupervisorUsers").then(res => {
      res[email] = tokenList;
      this.dbContext.yardManagerDb.put(res);
      return res;
    });
  }

  public deleteReturn(returnList: Return) {
    return this.dbContext.returnDb
      .get(returnList._id)
      .then((doc) => {
        return this.dbContext.returnDb.remove(doc);
      })
      .catch((err) => {
        console.error("deleteReturnRemote", err);
      });
  }

  public startReturn(returnItem: Return) {
    return this.dbContext.returnDb
      .get(returnItem._id)
      .then((doc) => {
        if (
          doc.locked &&
          doc.lockemail != this.authenticationService.useremail
        ) {
          return false;
        }
        doc.locked = true;
        doc.lockemail = this.authenticationService.useremail;
        return this.dbContext.returnDb.put(doc);
      })
      .catch((err) => {
        console.error("startingReturnError", err);
      });
  }

  public startFinishing(returnId) {
    return this.dbContext.returnDb
      .get(returnId)
      .then((doc) => {
        if (
          doc.locked &&
          doc.lockemail != this.authenticationService.useremail
        ) {
          return false;
        }
        doc.locked = true;
        doc.lockemail = this.authenticationService.useremail;
        console.log(doc);
        return this.dbContext.returnDb.put(doc);
      })
      .catch((err) => {
        console.error("startFinishingError", err);
      });
  }

  public stopFinishing(returnId) {
    return this.dbContext.returnDb
      .get(returnId)
      .then((doc) => {
        doc.locked = false;
        delete doc.lockemail;
        return this.dbContext.returnDb.put(doc);
      })
      .catch((err) => {
        console.error("startFinishingError", err);
      });
  }

  public getUserTokens(email: string) {
    return this.dbContext.yardManagerDb.get("keyUsers").then((res) => {
      console.log(res);
    });
  }

  deleteTransportOrder(transportOrder: TransportOrder) {
    console.log("Delete TransportOrder: " + transportOrder._id);
    return this.dbContext.outboundDb
      .get(transportOrder._id)
      .then((res) => {
        return this.dbContext.outboundDb.remove(res);
      })
      .catch((err) => {
        console.log("ERROR deleting TransportOrder: " + transportOrder._id);
        throw err;
      });
  }

  async getAllArticles(top: number, skip: number, isInventoryValued?: boolean, lifeCycleCodeFilters?: string[], searchText?: string): Promise<LocalizedArticle[]> {
    console.log("Getting all articles.");
    const isoLanguageCode = await this.getIsoLanguageCode();
    const isoContryCode = await this.getBranchIsoCountryCode();
    let requestUrl =
      environment.yardAPIUrl +
      "/articles" +
      "?isoLanguageCode=" +
      isoLanguageCode +
      "&isoCountryCode=" +
      isoContryCode +
      "&$top=" +
      top +
      "&$skip=" +
      skip;

    for (let lifeCycleCodeFilter of lifeCycleCodeFilters) {
      requestUrl += "&LifeCycleCodes=" + lifeCycleCodeFilter;
    }

    if (searchText) {
      requestUrl += "&SearchString=" + searchText;
    }
    if (isInventoryValued) {
      requestUrl += "&IsInventoryValued=" + isInventoryValued;
    }
    let auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let articles: LocalizedArticle[] = await lastValueFrom(this.http.get<LocalizedArticle[]>(requestUrl, { headers: headers }));
    return articles;
  }

  getIsoLanguageCode(): string {
    let isoLanguageCode = this.configService.config.appLanguage;
    isoLanguageCode = isoLanguageCode.replace("_", "-");
    return isoLanguageCode;
  }

  getBranchIsoCountryCode(): Promise<string> {
    console.log("Get Branch ISO Country Code");
    return new Promise((resolve, reject) => {
      if (
        this.isoLanguageCode.isoCountryCode === "" ||
        this.isoLanguageCode.branchIdentifier !==
        this.configService.config.clientid
      ) {
        this.dbContext.tokenDb
          .get("localArticles")
          .then((res) => {
            const branch = res.branchList.find(
              (b) => b.branchIdentifier === this.configService.config.clientid
            );
            this.isoLanguageCode.isoCountryCode = branch.isoCountryCode;
            this.isoLanguageCode.branchIdentifier =
              this.configService.config.clientid;
            resolve(branch.isoCountryCode);
          })
          .catch((err) => {
            console.error(err);
            console.log(
              "Couldn't find ISO Language Code for " +
              this.configService.config.clientid
            );
            console.log("Falling back to en");
            resolve("en");
          });
      } else {
        resolve(this.isoLanguageCode.isoCountryCode);
      }
    });
  }

  public bulkPickings(pickings: Picking[]) {
    console.log(pickings);
    return new Promise((resolve, reject) => {
      this.dbContext.pickingDb.bulkDocs(pickings).then(res => {
        console.log(res);
        console.log(pickings);
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  public bulkTOs(transportOrders: TransportOrder[]) {
    console.log(transportOrders);
    return new Promise((resolve, reject) => {
      this.dbContext.outboundDb.bulkDocs(transportOrders).then(res => {
        console.log(res);
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  public addLoginToDatabase() {
    return new Promise((resolve, reject) => {
      let requestUrl = environment.odlDbUrl + "odl/yard-manager/login";
      var auth_token = this.oAuthService.getAccessToken();
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "accept-version": "1.0"
      }
      if (!this.configService.config) {
        resolve(true);
      } else {
        let body = {
          "userEmail": this.oAuthService.getIdentityClaims()['email'],
          "branchIdentifier": this.configService.config.clientid
        };
        this.http.post(requestUrl, body, { headers: headers }).subscribe(() => {
          resolve(true);
        }, error => {
          console.error(error);
          reject();
        });
      }
    });
  }

  async getAverageLogins(): Promise<number> {
    let requestUrl = environment.odlDbUrl + "odl/yard-manager/login/average";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    }

    try {
      return await lastValueFrom(this.http.get<number>(requestUrl, { headers: headers }));
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async getTonnage(tonnageArticles: TonnageArticle[]): Promise<number> {
    if (this.oAuthService.hasValidAccessToken()) {
      let url = environment.yardAPIUrl + "/articles/tonnage";
      var auth_token = this.oAuthService.getAccessToken();
      const headers = {
        Authorization: `Bearer ${auth_token}`
      };

      try {
        let tonnage = await lastValueFrom(this.http.post<number>(url, tonnageArticles, { headers: headers }));
        return tonnage;
      } catch (error) {
        console.error(error);
        return 0;
      }
    } else {
      return 0;
    }
  }

}
