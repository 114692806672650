import { AdminService } from './../services/admin.service';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from './../services/authentication.service';
import { DatabaseService } from './../services/database.service';
import { Component, Input, OnInit } from '@angular/core';
import { UserToken } from '../models/userToken';

@Component({
  selector: 'app-admin-select',
  templateUrl: './admin-select.page.html',
  styleUrls: ['./admin-select.page.scss'],
})
export class AdminSelectPage implements OnInit {

  @Input("tempToken") tempToken;
  selectedToken: UserToken = null;
  loadedTokens: UserToken[] = [];
  loadedTokensBuffer: UserToken[] = [];
  isLoading = true;
  isLoadingMore = false;
  searchText: string = "";

  constructor(
    private dataBaseService: DatabaseService,
    private authenticationService: AuthenticationService,
    private modalCtrl: ModalController,
    public adminService: AdminService
  ) { }

  async ngOnInit() {
    this.loadTokens();
  }

  async loadTokens() {
    this.isLoading = true;
    this.loadedTokensBuffer = await this.dataBaseService.getTokenList(1000, 0);
    this.loadedTokensBuffer = this.loadedTokensBuffer.filter(t => t.token !== undefined);
    this.loadedTokensBuffer.sort((a, b) => parseInt(a.branchIdentifier) - parseInt(b.branchIdentifier));
    this.onSearch();
    this.isLoading = false;
  }

  async loadMoreTokens(event) {
    this.isLoadingMore = true;
    let tokens = await this.dataBaseService.getTokenList(1000, this.loadedTokensBuffer.length);
    this.loadedTokensBuffer.push(...tokens);
    this.loadedTokensBuffer = this.loadedTokensBuffer.filter(t => t.token !== undefined);
    this.loadedTokensBuffer.sort((a, b) => parseInt(a.branchIdentifier) - parseInt(b.branchIdentifier));
    this.onSearch();
    event.target.complete();
    this.isLoadingMore = false;
  }

  selectToken(userToken: UserToken) {
    this.selectedToken = userToken;
  }

  loginWithSelectedToken() {
    this.modalCtrl.dismiss();
    this.authenticationService.login(this.selectedToken.token, this.selectedToken.id);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  onSearch() {
    this.loadedTokens = this.loadedTokensBuffer.filter(t => {
      return t.branchIdentifier.includes(this.searchText.toLowerCase()) || t.displayName?.toLowerCase().includes(this.searchText.toLowerCase()) || t.name?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    console.log(this.loadedTokensBuffer);
  }

}
