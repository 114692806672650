import { PickingItem } from "./pickingItem";

export class Picking {
  _id: string;
  _rev: string;
  _deleted: boolean;
  additionalText: string;
  clientId: string;
  commissionBy: string;
  commissionLocation: string;
  contactPerson: string;
  custAccount: string;
  custInfo: string;
  custName: string;
  dlvAddress: string;
  dlvDate: string;
  dlvTime: string;
  dlvModeId: string;
  dlvTerm: string;
  items: PickingItem[];
  jobSiteNo: string;
  orderDate: string;
  phoneNo: string;
  picker: string;
  pickingDate: string;
  pickingListId: string;
  projectAddress: string;
  projectNo: string;
  projectName: string;
  purchaseCust: string;
  salesId: string;
  salesIds: string[];
  salesTaker: string;
  shipper: string;
  sortOrder: number;
  status: string;
  weight: number;
  locked: boolean;
  type: string;
  uploadStatus: string;
  pickedCount?: number;
  workingCount?: number;
  action?: string;
  done?: boolean;
  local?: boolean;
  removed?: boolean;
  teams?: number[];
  tempText?: string;
  localPicker?: string;
  newLocalPicker?: string;
  newCommissionLocation: string;
  discosText: string;
  checker: string;
  pickingNoChange: boolean;
  qrMandatory: boolean;
  headerChanged?: boolean;
  convertedDate: Date;
  combinedText: string;
  itemCount: number;
  isCanceled: boolean;
}
