import { ConfigService } from "./config.service";
import { DatabaseService } from "./database.service";
import { TransportOrder } from "./../models/transport-order";
import { Injectable } from "@angular/core";
import { Picking } from "../models/picking";
import * as jspdf from "jspdf";
import domtoimage from "dom-to-image";

@Injectable({
  providedIn: "root",
})
export class TransportOrderService {
  public currentTransportOrders: TransportOrder[];
  public currentTransportOrder: TransportOrder;
  public currentPicking: Picking;
  public currentTransportOrderId: string;
  public currentSearchArray = [];

  constructor(
    private database: DatabaseService,
    private configService: ConfigService
  ) {}

  loadTransportOrders() {
    return new Promise((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getTransportOrdersByClient(this.configService.config.clientid)
            .then((res) => {
              this.currentTransportOrders = res;
              for (const transportOrder of this.currentTransportOrders) {
                transportOrder.locked = transportOrder.locked ? true : false;
                transportOrder.convertedDepDate = transportOrder.depDate
                  ? new Date(
                      +transportOrder.depDate.substring(
                        transportOrder.depDate.indexOf("(") + 1,
                        transportOrder.depDate.indexOf(")")
                      )
                    )
                  : null;
                transportOrder.combinedText =
                  transportOrder.discosText +
                  (transportOrder.additionalText && transportOrder.discosText
                    ? ","
                    : "") +
                  (transportOrder.additionalText
                    ? transportOrder.additionalText
                    : "");
                // transportOrder.itemCount = transportOrder.items.length;
                transportOrder.done = transportOrder.done
                  ? transportOrder.done
                  : false;
                transportOrder.itemCount = 0;
                for (const picking of transportOrder.pickingLists) {
                  transportOrder.itemCount += picking.items.length;
                }
              }
              sub.unsubscribe();
              resolve(null);
            });
        }
      });
    });
  }

  loadTransportOrdersDashboard(): Promise<TransportOrder[]> {
    return new Promise((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getTransportOrdersByClient(this.configService.config.clientid)
            .then((res) => {
              this.currentTransportOrders = res;
              for (const transportOrder of this.currentTransportOrders) {
                transportOrder.locked = transportOrder.locked ? true : false;
                transportOrder.convertedDepDate = transportOrder.depDate
                  ? new Date(
                      +transportOrder.depDate.substring(
                        transportOrder.depDate.indexOf("(") + 1,
                        transportOrder.depDate.indexOf(")")
                      )
                    )
                  : null;
                transportOrder.combinedText =
                  transportOrder.discosText +
                  (transportOrder.additionalText && transportOrder.discosText
                    ? ","
                    : "") +
                  (transportOrder.additionalText
                    ? transportOrder.additionalText
                    : "");
                // transportOrder.itemCount = transportOrder.items.length;
                transportOrder.done = transportOrder.done
                  ? transportOrder.done
                  : false;
                transportOrder.itemCount = 0;
                for (const picking of transportOrder.pickingLists) {
                  transportOrder.itemCount += picking.items.length;
                }
              }
              sub.unsubscribe();
              resolve(res);
            });
        }
      });
    });
  }

  loadTransportOrder() {
    return this.database
      .getTransportOrderById(this.currentTransportOrderId)
      .then((res) => {
        this.currentTransportOrder = res;
        for (const picking of this.currentTransportOrder.pickingLists) {
          picking.combinedText =
            (picking.discosText ? picking.discosText : "") +
            (picking.additionalText && picking.discosText ? "," : "") +
            (picking.additionalText ? picking.additionalText : "");
          picking.convertedDate = picking.dlvDate
            ? new Date(
                +picking.dlvDate.substring(
                  picking.dlvDate.indexOf("(") + 1,
                  picking.dlvDate.indexOf(")")
                )
              )
            : null;
          picking.itemCount = picking.items.length;
          for (let item of picking.items) {
            item.combinedText =
              (item.discosText ? item.discosText : "") +
              (item.additionalText && item.discosText ? "," : "") +
              (item.additionalText ? item.additionalText : "");
          }
        }
      });
  }

  unlockTransportOrder(transportOrder: TransportOrder) {
    return this.database.unlockTransportOrder(transportOrder).then(() => {
      transportOrder.checkLocked = false;
    });
  }

  deleteWorkingCount(transportOrder: TransportOrder) {
    return this.database.deleteTransportOrderWorkingCount(transportOrder).then(() => {
      transportOrder.workingCount = null;
      transportOrder.pickedCount = null;
    });
  }

  deleteTransportOrder(transportOrder: TransportOrder) {
    return this.database.deleteTransportOrder(transportOrder).then(res => {
      
    });
  }

  deleteMultipleTransportOrders(transportOrders: TransportOrder[]) {
    return this.database.bulkTOs(transportOrders);
  }
}
