import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Recon } from '../models/recon';

@Component({
  selector: 'app-recon-header',
  templateUrl: './recon-details.component.html',
  styleUrls: ['./recon-details.component.scss']
})
export class ReconDetailsComponent implements OnInit {

  @Input("reconOrder") reconOrder: Recon;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  openYardcam(type: string, number: string) {
    window.open(environment.yardCamLink + '?voucherType=' + type + '&searchText=' + number, '_blank');
  }

}
