import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SuppItem } from "./../models/suppitem";
import { ConfigService } from "./config.service";
import { DatabaseService } from "./database.service";
import { Injectable } from "@angular/core";
import { Return } from "../models/return";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TonnageArticle } from "../models/tonnageArticle";
import { ReturnItem } from "../models/returnitem";
import { LineItem } from "../models/returnDeliveries/lineItem";
import { Redelivery } from "../models/returnDeliveries/redelivery";
import { MobileReturnDeliveryStatus } from "../models/returnDeliveries/mobileReturnDeliveryStatus";

@Injectable({
  providedIn: "root",
})
export class ReturnService {
  public currentReturnList: Return[];
  public currentReturn: Return;
  public currentReturnId: string;
  public currentItemId: string;
  public currentSpareParts: SuppItem[];
  public currentSearchArray = [];

  tempTonnage: any = [];
  tempTonnageFirstSight: any = [];

  constructor(
    private database: DatabaseService,
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  loadReturns() {
    return new Promise((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getReturnsByClientId(this.configService.config.clientid)
            .then(async (res) => {
              this.currentReturnList = res;
              for (const rn of this.currentReturnList) {
                rn.locked = rn.locked ? true : false;
                rn.convertedDate = rn.dateofarrival
                  ? new Date(
                    +rn.dateofarrival.substring(
                      rn.dateofarrival.indexOf("(") + 1,
                      rn.dateofarrival.indexOf(")")
                    )
                  )
                  : null;
                this.calculateTonnageAll(rn).then((tonnage) => {
                  rn.tonnage = tonnage;
                });
                this.calculateTonnageFirstSight(rn).then((tonnage1st) => {
                  rn.tonnage1st = tonnage1st;
                });
              }
              sub.unsubscribe();
              resolve(null);
              return;
            });
        }
      });
    });
  }

  loadReturnsDashboard(): Promise<Return[]> {
    return new Promise((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getReturnsByClientId(this.configService.config.clientid)
            .then((res) => {
              this.currentReturnList = res;
              for (const rn of this.currentReturnList) {
                rn.locked = rn.locked ? true : false;
                rn.convertedDate = rn.dateofarrival
                  ? new Date(
                    +rn.dateofarrival.substring(
                      rn.dateofarrival.indexOf("(") + 1,
                      rn.dateofarrival.indexOf(")")
                    )
                  )
                  : null;
                this.calculateTonnageAll(rn).then((tonnage) => {
                  rn.tonnage = tonnage;
                });
                this.calculateTonnageFirstSight(rn).then((tonnage1st) => {
                  rn.tonnage1st = tonnage1st;
                });
              }
              sub.unsubscribe();
              resolve(res);
              return;
            });
        }
      });
    });
  }

  loadReturn(returnId = this.currentReturnId) {
    return this.database.getReturnById(returnId).then((res) => {
      this.currentReturn = res;
      this.currentReturn.convertedDate = this.currentReturn.dateofarrival
        ? new Date(
          +this.currentReturn.dateofarrival.substring(
            this.currentReturn.dateofarrival.indexOf("(") + 1,
            this.currentReturn.dateofarrival.indexOf(")")
          )
        )
        : null;
      for (const item of this.currentReturn.items) {
        item.Total =
          item.Qty_QualCat_OK +
          item.Qty_QualCat_Repair +
          item.Qty_QualCat_Crap +
          item.Qty_QualCat_CustCrap;
      }
      return this.currentReturn;
    });
  }

  loadSingleReturn(returnId: string) {
    return this.database.getReturnById(returnId).then((res) => {
      res.convertedDate = this.currentReturn.dateofarrival
        ? new Date(
          +res.dateofarrival.substring(
            res.dateofarrival.indexOf("(") + 1,
            res.dateofarrival.indexOf(")")
          )
        )
        : null;
      for (const item of res.items) {
        item.Total =
          item.Qty_QualCat_OK +
          item.Qty_QualCat_Repair +
          item.Qty_QualCat_Crap +
          item.Qty_QualCat_CustCrap;
      }
      return res;
    });
  }

  saveCurrentReturn() {
    this.currentReturn.items.forEach((value) => {
      delete value["column"];
      delete value["columnValue"];
      delete value["isFinished"];
    });
    delete this.currentReturn["Total"];
    delete this.currentReturn["column"];
    delete this.currentReturn["columnValue"];
    return this.database.saveReturn(this.currentReturn);
  }

  loadSpareParts(itemId: string) {
    this.currentItemId = itemId;
    this.currentSpareParts = this.currentReturn.items
      .filter((data) => data.ItemId === itemId)[0]
      .SuppItems.filter((data) => data.SalesQty > 0 || data.SalesQtyDoka > 0);
    console.log(this.currentSpareParts);
  }

  loadReturnsPaginated() {
    return this.database
      .getReturnsByClientIdPaginated(
        this.configService.config.clientid,
        this.currentReturnList ? this.currentReturnList.length : 0
      )
      .then((res) => {
        this.currentReturnList = this.currentReturnList
          ? this.currentReturnList
          : [];
        this.currentReturnList.push(...res);
        for (const rn of this.currentReturnList) {
          rn.convertedDate = rn.dateofarrival
            ? new Date(
              +rn.dateofarrival.substring(
                rn.dateofarrival.indexOf("(") + 1,
                rn.dateofarrival.indexOf(")")
              )
            )
            : null;
        }
        console.log(this.currentReturnList);
        return;
      });
  }

  unlockReturn(rn: Return) {
    return this.database.unlockReturn(rn).then(() => {
      rn.locked = false;
    });
  }

  exportReturn(returnItem: Return) {
    console.log(returnItem);
    delete returnItem["Total"];
    delete returnItem["column"];
    delete returnItem["columnValue"];
    delete returnItem.lockemail;
    /* returnItem.items.forEach(current => {
      delete current["column"];
      delete current["columnValue"];
      delete current["isFinished"];
      current.SuppItems.forEach(suppItem => {
        delete suppItem["columnValue"];
      });
    }); */

    returnItem.items.forEach((current) => {
      delete current["column"];
      delete current["columnValue"];
      delete current["columnValue"];
      delete current.AddedCurrentStatus;
      if (current.SuppItems) {
        current.SuppItems.forEach((currentSupp) => {
          delete currentSupp["columnValue"];
        });
      }
    });

    console.log(returnItem);
    /* if(returnItem.AdditionalTextNew) {

      if(returnItem.additionaltext.lastIndexOf(';') === returnItem.additionaltext.length - 1) {
        returnItem.additionaltext = returnItem.additionaltext + returnItem.AdditionalTextNew;
      } else {
        returnItem.additionaltext = returnItem.additionaltext + ';' + returnItem.AdditionalTextNew;
      }

      returnItem.AdditionalTextNew = '';
    } else {
      if(returnItem.AdditionalTextNew) {
        returnItem.additionaltext = returnItem.AdditionalTextNew + ";";
        returnItem.additionaltext = '';
      } else {
        returnItem.additionaltext = '';
      }
    } */

    if (this.currentReturn.pamstatus === "QtyEstimatedFinished") {
      returnItem.items.forEach((x) => {
        if (x.AdditionalLineTextNew) {
          x.AdditionalLineText = x.AdditionalLineTextNew;
          x.AdditionalLineTextNew = "";
        } else {
          x.AdditionalLineText = "";
        }
      });
    }

    const observable: Observable<boolean> = new Observable((observer) => {
      let bodyJSON = JSON.stringify(returnItem, null, 4);
      console.log("Export JSON called");
      /* let headers = ({
        'content-type': 'text/plain',
        'X-Doka-Authorization': this.configService.config.token,
        'X-Doka-Application': 'yardcam',
        'X-Doka-FileName': this.configService.config.clientid + '_countlist_' + returnItem._id + '.json'
      }); */

      let options = {
        headers: new HttpHeaders({
          "content-type": "text/plain",
          "X-Doka-Authorization": this.configService.config.token,
          "X-Doka-Application": "yardcam",
          "X-Doka-FileName":
            this.configService.config.clientid +
            "_countlist_" +
            returnItem._id +
            "_" +
            new Date().getFullYear() +
            "_" +
            (new Date().getMonth().toString().length === 1 ? "0" : "") +
            (new Date().getMonth() + 1) +
            "_" +
            (new Date().getDate().toString().length === 1 ? "0" : "") +
            new Date().getDate() +
            "_" +
            (new Date().getUTCHours().toString().length === 1 ? "0" : "") +
            new Date().getUTCHours() +
            "_" +
            (new Date().getMinutes().toString().length === 1 ? "0" : "") +
            new Date().getMinutes() +
            "_" +
            (new Date().getSeconds().toString().length === 1 ? "0" : "") +
            new Date().getSeconds() +
            "_" +
            (new Date().getMinutes().toString().length === 1
              ? "00"
              : new Date().getMinutes().toString().length === 1
                ? "0"
                : "") +
            new Date().getMilliseconds() +
            ".json",
        }),
      };
      let url =
        returnItem.redeliveryType !== "Mixed"
          ? environment.returnExportUrl
          : environment.returnExportUrlOwnMaterial;
      this.http
        .post(url, JSON.parse(bodyJSON), options)
        .subscribe(
          (response) => {
            console.log(response);
            observer.next(true);
            observer.complete();
          },
          (error) => {
            console.log(error);
            observer.error(error);
            observer.complete();
          }
        );
    });

    return observable;
  }

  public startReturn(returnItem: Return) {
    return this.database.startReturn(returnItem).then((res) => {
      console.log(res);
      return res;
    });
  }

  public saveReturn(Return: Return, sparePartSave: boolean = false) {
    if (!sparePartSave) {
      delete Return.lockemail;
      Return.locked = false;
    }
    Return.items.forEach((value) => {
      delete value["column"];
      delete value["columnValue"];
      delete value["isFinished"];
    });
    delete Return["Total"];
    delete Return["column"];
    delete Return["columnValue"];
    console.log(Return);
    return this.database.saveReturn(Return).then((res) => {
      console.log(res);
      return res;
    });
  }

  public startFinishing(returnId) {
    return this.database.startFinishing(returnId).then((res) => {
      console.log(res);
      return res;
    });
  }

  public stopFinishing(returnId) {
    return this.database.stopFinishing(returnId).then((res) => {
      console.log(res);
      return res;
    });
  }

  public deleteReturn(returnItem) {
    return this.database.deleteReturn(returnItem).then(() => { });
  }

  public async calculateRedeliveryTonnageAll(redelivery: Redelivery): Promise<number> {
    let tonnageArticles: TonnageArticle[] = [];
    let tonnage: number = 0;
    for (const lineItem of redelivery.lineItems) {
      if (
        lineItem.firstSightSeen ||
        lineItem.quantities.quantityFirstSight > 0 ||
        lineItem.quantities.quantityOk > 0 ||
        lineItem.quantities.quantityRepair
      ) {
        const total =
          lineItem.quantities.quantityOk +
          lineItem.quantities.quantityRepair +
          lineItem.quantities.quantityDamaged +
          lineItem.quantities.quantityWear;
        if (total > 0) {
          let tonnageArticle = new TonnageArticle(lineItem.article.articleIdentifier, total);
          tonnageArticles.push(tonnageArticle);
        }
      }
    }
    if (tonnageArticles.length !== 0) {
      try {
        tonnage = await this.database.getTonnage(tonnageArticles);
        return tonnage;
      } catch (error) {
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async calculateRedeliveryFirstSightTonnage(redelivery: Redelivery) {
    let tonnageArticles: TonnageArticle[] = [];
    for (const lineItem of redelivery.lineItems) {
      let total = 0;
      if (redelivery.mobileReturnDeliveryStatus === MobileReturnDeliveryStatus.Released || redelivery.mobileReturnDeliveryStatus === MobileReturnDeliveryStatus.FirstSight) {
        total = lineItem.quantities.quantityCustomerCount !== 0 ? lineItem.quantities.quantityCustomerCount : lineItem.quantities.quantityFirstSight;
      } else {
        total = lineItem.quantities.quantityFirstSight;
      }
      if (total > 0) {
        let tonnageArticle = new TonnageArticle(lineItem.article.articleIdentifier, total);
        tonnageArticles.push(tonnageArticle);
      }
    }
    if (tonnageArticles.length !== 0) {
      try {
        let tonnage = this.database.getTonnage(tonnageArticles);
        return tonnage;
      } catch {
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async calculateTonnageAll(returnItem: Return): Promise<number> {
    let tonnageArticles: TonnageArticle[] = [];
    let tonnage: number = 0;
    for (const item of returnItem.items) {
      if (
        item.FirstSightSeen ||
        item.FirstSight > 0 ||
        item.CouchQty_QualCat_OK > 0 ||
        item.CouchQty_QualCat_Repair > 0
      ) {
        const total =
          +(item.CouchQty_QualCat_OK ? item.CouchQty_QualCat_OK : item.Qty_QualCat_OK) +
          +(item.CouchQty_QualCat_Repair ? item.CouchQty_QualCat_Repair : item.Qty_QualCat_Repair) +
          +(item.CouchQty_QualCat_Crap ? item.CouchQty_QualCat_Crap : item.Qty_QualCat_Crap) +
          +(item.CouchQty_QualCat_CustCrap ? item.CouchQty_QualCat_CustCrap : item.Qty_QualCat_CustCrap);
        if (total > 0) {
          let tonnageArticle = new TonnageArticle(item.ItemId, total)
          tonnageArticles.push(tonnageArticle);
        }
      }
    }
    if (tonnageArticles.length !== 0) {
      try {
        tonnage = await this.database.getTonnage(tonnageArticles);
        return tonnage;
      } catch (error) {
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async calculateTonnageFirstSight(returnItem: Return): Promise<number> {
    let tonnageArticles: TonnageArticle[] = [];
    for (const item of returnItem.items) {
      let total = 0;
      if (returnItem.pamstatus === 'None') {
        total = +(item.QtyCustPackingSlip ? item.QtyCustPackingSlip : 0);
      } else {
        total = item.FirstSight;
      }
      if (total > 0) {
        let tonnageArticle = new TonnageArticle(item.ItemId, total);
        tonnageArticles.push(tonnageArticle);
      }
    }
    if (tonnageArticles.length !== 0) {
      try {
        let tonnage = this.database.getTonnage(tonnageArticles);
        return tonnage;
      } catch {
        return 0;
      }
    } else {
      return 0;
    }
  }

  public deleteSparepart(itemArticleNumber: string, suppArticleNumber: string) {
    return this.database.deleteReturnSparePart(this.currentReturn._id, itemArticleNumber, suppArticleNumber);
  }

  public async writeQtyAndSaveRemote(redelivery: Return) {
    try {
      let remoteRedelivery = await this.database.getReturnById(redelivery._id);
      for (let item of redelivery.items) {
        this.convertStringQtyToNumber(item);
      }
      const localRedelivery = new Return();
      Object.assign(
        localRedelivery,
        redelivery
      );
      const localPosFinishedArticles = localRedelivery.items.filter(
        (value) => value.RedCatFinished || value.RemoteFinished
      );
      /* create seperate object to add values of "Qty_" variables remote to local */
      const checkRemoteRedelivery = new Return();
      Object.assign(
        checkRemoteRedelivery,
        remoteRedelivery
      );
      checkRemoteRedelivery.items = remoteRedelivery.items.slice();
      /* Make one array with all posfinished items */
      for (const localArticle of localPosFinishedArticles) {
        const index = remoteRedelivery.items.findIndex(value => {
          return value.ItemId == localArticle.ItemId;
        });
        remoteRedelivery.items.splice(
          index,
          1,
          localArticle
        );
      }
      const firstSightArticles = remoteRedelivery.items.filter(
        (value) =>
          this.checkArticleSighted(
            value
          )
      );
      let posFinishedArticledCount = 0;
      /* Check is every tapped item is posfinished */
      for (const article of firstSightArticles) {
        if (
          article.RedCatFinished ||
          article.RemoteFinished
        ) {
          posFinishedArticledCount++;
        }
      }
      /* Add remote to local values */
      for (const item of localRedelivery.items) {
        this.addRemoteToLocalValue(item, checkRemoteRedelivery);
      }

      if (
        posFinishedArticledCount ===
        firstSightArticles.length
      ) {
        localRedelivery.pamstatus =
          "PosFinished";
      } else {
        localRedelivery.pamstatus =
          "QtyEstimatedFinished";
      }
      console.log(localRedelivery);

      await this.database.saveReturn(localRedelivery);
    } catch (error) {
      console.error(error);
    }
  }

  addRemoteToLocalValue(returnItem: ReturnItem, checkRemoteRedelivery: Return) {
    if (returnItem.RedCatFinished) {
      returnItem.RedCatFinished = false;
      returnItem.RemoteFinished = true;
    }
    if (
      checkRemoteRedelivery.items.filter(
        (value) =>
          value.ItemId ===
          returnItem.ItemId
      ).length > 0
    ) {
      const remoteItem = checkRemoteRedelivery.items.filter(
        (value) =>
          value.ItemId ===
          returnItem.ItemId
      )[0];

      returnItem.CouchAdditionalLineText =
        (remoteItem.CouchAdditionalLineText ? remoteItem.CouchAdditionalLineText : "") + returnItem.AdditionalLineText;
      returnItem.AdditionalLineText = "";

      for (const suppItem of returnItem.SuppItems) {
        if (
          remoteItem.SuppItems.filter(
            (value) =>
              value.SuppItemId ===
              value.SuppItemId
          ).length > 0
        ) {
          const remoteSuppItem = remoteItem.SuppItems.filter(
            (value) =>
              value.SuppItemId ===
              value.SuppItemId
          )[0];
          remoteSuppItem.SalesQtyDoka;
          suppItem.ItemDamaged =
            remoteSuppItem.ItemDamaged ||
            suppItem.ItemDamaged;
          suppItem.ItemLack =
            remoteSuppItem.ItemLack ||
            suppItem.ItemLack;
          suppItem.Picked =
            remoteSuppItem.Picked ||
            suppItem.Picked;
        }
        suppItem.CouchSalesQty =
          suppItem.SalesQty;
        suppItem.CouchSalesQtyDoka =
          suppItem.SalesQtyDoka;
      }
    }

    returnItem.CouchQty_Cleaning =
      returnItem.Qty_Cleaning;
    returnItem.CouchQty_FrameRepair =
      returnItem.Qty_FrameRepair;
    returnItem.CouchQty_OwnConsumption =
      returnItem.Qty_OwnConsumption;
    returnItem.CouchQty_PlyWoodRepair =
      returnItem.Qty_PlyWoodRepair;
    returnItem.CouchQty_QualCat_Crap =
      returnItem.Qty_QualCat_Crap;
    returnItem.CouchQty_QualCat_CustCrap =
      returnItem.Qty_QualCat_CustCrap;
    returnItem.CouchQty_QualCat_OK =
      returnItem.Qty_QualCat_OK;
    returnItem.CouchQty_QualCat_Repair =
      returnItem.Qty_QualCat_Repair;
    returnItem.CouchQty_QualCat_RepairFinished =
      returnItem.Qty_QualCat_RepairFinished;
  }

  convertStringQtyToNumber(returnItem: ReturnItem) {
    returnItem.Qty_Cleaning = +returnItem.Qty_Cleaning;
    returnItem.Qty_FrameRepair = +returnItem.Qty_FrameRepair;
    returnItem.Qty_OwnConsumption = +returnItem.Qty_OwnConsumption;
    returnItem.Qty_PlyWoodRepair = +returnItem.Qty_PlyWoodRepair;
    returnItem.Qty_QualCat_Crap = +returnItem.Qty_QualCat_Crap;
    returnItem.Qty_QualCat_CustCrap = +returnItem.Qty_QualCat_CustCrap;
    returnItem.Qty_QualCat_OK = +returnItem.Qty_QualCat_OK;
    returnItem.Qty_QualCat_Repair = +returnItem.Qty_QualCat_Repair
    returnItem.Qty_QualCat_RepairFinished = +returnItem.Qty_QualCat_RepairFinished;
    delete returnItem["column"];
    delete returnItem["columnValue"];
    delete returnItem["columnValue"];
    delete returnItem.AddedCurrentStatus;
    for (let suppItem of returnItem.SuppItems) {
      suppItem.SalesQty = +suppItem.SalesQty;
      suppItem.SalesQtyDoka = +suppItem.SalesQtyDoka;
      delete suppItem["columnValue"];
    }
  }

  checkArticleSighted(article: ReturnItem): boolean {
    if (
      article.Qty_Cleaning ||
      article.Qty_FrameRepair ||
      article.Qty_OwnConsumption ||
      article.Qty_PlyWoodRepair ||
      article.Qty_QualCat_Crap ||
      article.Qty_QualCat_CustCrap ||
      article.Qty_QualCat_OK ||
      article.Qty_QualCat_Repair ||
      article.Qty_QualCat_RepairFinished ||
      article.FirstSightSeen ||
      article.FirstSight
    ) {
      return true;
    } else {
      return false;
    }
  }

}
