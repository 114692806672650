export class TonnageArticle {

    constructor(
        articleIdentifier,
        amount
    ) {
        this.articleIdentifier = articleIdentifier;
        this.amount = amount;
    }

    articleIdentifier: string;
    amount: number;
}