import { DatabaseService } from "./database.service";
import { Picking } from "./../models/picking";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class PickingService {
  public currentPickingList: Picking[];
  public currentPicking: Picking;
  public currentPickingId: string;
  public currentSearchArray = [];

  constructor(
    private database: DatabaseService,
    private configService: ConfigService
  ) {}

  loadPickings() {
    return new Promise((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getPickingsByClientId(this.configService.config.clientid)
            .then((res) => {
              this.currentPickingList = res;
              for (const picking of this.currentPickingList) {
                picking.locked = picking.locked ? true : false;
                picking.convertedDate = picking.dlvDate
                  ? new Date(
                      +picking.dlvDate.substring(
                        picking.dlvDate.indexOf("(") + 1,
                        picking.dlvDate.indexOf(")")
                      )
                    )
                  : null;
                picking.combinedText =
                  picking.discosText +
                  (picking.additionalText && picking.discosText ? "," : "") +
                  (picking.additionalText ? picking.additionalText : "");
                picking.itemCount = picking.items.length;
                picking.done = picking.done ? picking.done : false;
              }
              resolve(null);
              sub.unsubscribe();
            });
        }
      });
    });
  }

  loadPickingsDashboard(): Promise<Picking[]> {
    return new Promise<Picking[]>((resolve, reject) => {
      const sub = this.configService.configLoaded.subscribe((loaded) => {
        if (loaded) {
          return this.database
            .getPickingsByClientId(this.configService.config.clientid)
            .then((res: Picking[]) => {
              for (const picking of res) {
                picking.locked = picking.locked ? true : false;
                picking.convertedDate = picking.dlvDate
                  ? new Date(
                      +picking.dlvDate.substring(
                        picking.dlvDate.indexOf("(") + 1,
                        picking.dlvDate.indexOf(")")
                      )
                    )
                  : null;
                picking.combinedText =
                  picking.discosText +
                  (picking.additionalText && picking.discosText ? "," : "") +
                  (picking.additionalText ? picking.additionalText : "");
                picking.itemCount = picking.items.length;
                picking.done = picking.done ? picking.done : false;
              }
              resolve(res);
              sub.unsubscribe();
            });
        }
      });
    });
  }

  loadPicking() {
    return this.database.getPickingById(this.currentPickingId).then((res) => {
      this.currentPicking = res;
      for (const item of this.currentPicking.items) {
        item.combinedText =
          (item.discosText ? item.discosText : "") +
          (item.additionalText && item.discosText ? "," : "") +
          (item.additionalText ? item.additionalText : "");
      }
    });
  }

  unlockPicking(picking: Picking) {
    return this.database.unlockPicking(picking).then(() => {
      picking.locked = false;
    });
  }

  deleteWorkingCount(picking: Picking, moveToCheck: boolean = false) {
    return this.database.deletePickingWorkingCount(picking,moveToCheck).then(() => {
      if(moveToCheck) {
        picking.pickedCount = 0;
      } else {
        picking.workingCount = null;
        picking.pickedCount = null;
      }
    });
  }

  deletePicking(picking: Picking) {
    return this.database.deletePicking(picking);
  }

  deleteMultiplePickings(pickings: Picking[]) {
    return this.database.bulkPickings(pickings);
  }
}
