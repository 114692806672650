<div class="itemWrapper">
  <doka-icon
    *ngIf="!checked"
    name="check_box_outline_blank"
    size="2xl"
    type="filled"
  ></doka-icon>
  <doka-icon
    *ngIf="checked"
    name="check_box"
    size="2xl"
    type="filled"
  ></doka-icon>
</div>
