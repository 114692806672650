export class Branch {

    constructor(
        id: string,
        branchIdentifier: string
    ) {
        this.id = id;
        this.branchIdentifier = branchIdentifier;
    }

    id: string;
    branchIdentifier: string;
    name: string;
    displayName: string;
}