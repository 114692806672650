import { AdminService } from './../services/admin.service';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from './../services/authentication.service';
import { DatabaseService } from './../services/database.service';
import { Component, Input, OnInit } from '@angular/core';
import { UserToken } from '../models/userToken';

@Component({
  selector: 'app-admin-select',
  templateUrl: './admin-select.page.html',
  styleUrls: ['./admin-select.page.scss'],
})
export class AdminSelectPage implements OnInit {

  @Input("tempToken") tempToken;
  selectedToken: UserToken = null;
  loadedTokens: UserToken[] = [];
  isLoading = true;
  isLoadingMore = false;

  constructor(
    private dataBaseService: DatabaseService,
    private authenticationService: AuthenticationService,
    private modalCtrl: ModalController,
    public adminService: AdminService
  ) { }

  async ngOnInit() {
    this.loadTokens();
  }

  async loadTokens() {
    this.isLoading = true;
    this.loadedTokens = await this.dataBaseService.getTokenList(1000, 0);
    this.loadedTokens = this.loadedTokens.filter(t => t.token !== undefined);
    this.loadedTokens.sort((a, b) => parseInt(a.branchIdentifier) - parseInt(b.branchIdentifier));
    this.isLoading = false;
  }

  async loadMoreTokens(event) {
    this.isLoadingMore = true;
    let tokens = await this.dataBaseService.getTokenList(1000, this.loadedTokens.length);
    this.loadedTokens.push(...tokens);
    this.loadedTokens = this.loadedTokens.filter(t => t.token !== undefined);
    this.loadedTokens.sort((a, b) => parseInt(a.branchIdentifier) - parseInt(b.branchIdentifier));
    event.target.complete();
    this.isLoadingMore = false;
  }

  selectToken(userToken: UserToken) {
    this.selectedToken = userToken;
  }

  loginWithSelectedToken() {
    this.modalCtrl.dismiss();
    this.authenticationService.login(this.selectedToken.token, this.selectedToken.id);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
