import { environment } from './../../environments/environment';
import { Injectable } from "@angular/core";
import PouchDB from "pouchdb";
import PouchFind from "pouchdb-find";

@Injectable({
  providedIn: "root",
})
export class DbContext {
  public returnDb: PouchDB.Database;
  public pickingDb: PouchDB.Database;
  public outboundDb: PouchDB.Database;
  public tokenDb: PouchDB.Database;
  public yardManagerDb: PouchDB.Database;
  public tokenAuthDb: PouchDB.Database;

  constructor() {
    PouchDB.on("created", function (dbName) {
      console.warn("Opened connection to ", dbName);
    });
    PouchDB.on("destroyed", function (dbName) {
      console.warn("Closed connection to ", dbName);
    });
    this.init();
  }

  private init() {
      console.log("Initializing Pouchd/Couch Database");
      try {
        this.returnDb = new PouchDB(environment.returnDbUrl  ,{
          fetch: function (url, opts) {
            opts.headers.set("X-Doka-Authorization", "115AUT123AUT");
            opts.headers.set("X-Doka-Application", "yardcam");
            opts.credentials = "omit";
            return PouchDB.fetch(url, opts);
          },
        }  );
        this.pickingDb = new PouchDB(environment.pickingDbUrl ,{
          fetch: function (url, opts) {
            opts.headers.set("X-Doka-Authorization", "115AUT123AUT");
            opts.headers.set("X-Doka-Application", "yardcam");
            opts.credentials = "omit";
            return PouchDB.fetch(url, opts);
          },
        } );
        this.outboundDb = new PouchDB(environment.outboundDbUrl ,{
          fetch: function (url, opts) {
            opts.headers.set("X-Doka-Authorization", "115AUT123AUT");
            opts.headers.set("X-Doka-Application", "yardcam");
            opts.credentials = "omit";
            return PouchDB.fetch(url, opts);
          },
        } );
        this.tokenDb = new PouchDB(environment.tokenDbUrl  ,{
          fetch: function (url, opts) {
            opts.headers.set("X-Doka-Authorization", "115AUT123AUT");
            opts.headers.set("X-Doka-Application", "yardcam");
            opts.credentials = "omit";
            return PouchDB.fetch(url, opts);
          },
        }  );
        this.yardManagerDb = new PouchDB(environment.yardManagerDbUrl  ,{
          fetch: function (url, opts) {
            opts.headers.set("X-Doka-Authorization", "115AUT123AUT");
            opts.headers.set("X-Doka-Application", "yardcam");
            opts.credentials = "omit";
            return PouchDB.fetch(url, opts);
          },
        }  );
        PouchDB.plugin(PouchFind);
        console.log("PouchDB init complete.");
      } catch (ex) {
        console.error("PouchDB init failed.");
        console.error(JSON.stringify(ex));
      }
  }
}
