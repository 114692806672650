import { Picking } from "./picking";

export class TransportOrder {
  _id: string;
  _rev: string;
  additionalText: string;
  carrierAccount: string;
  carrierName: string;
  chauffeur: string;
  checker: string;
  clientId: string;
  packageCount: number;
  containerCode: string;
  errors: any[];
  fourEyesPrinciple: boolean;
  loadingEnded: string; //is a Date
  loadingMeter: number;
  loadingStarted: string; //is a Date
  locked: boolean;
  pickingLists: Picking[];
  shipper: string;
  tansUnitCode1: string;
  tansUnitCode2: string;
  transportOrderId: string;
  waggonNumber: string;
  discosText: string;
  pickedCount: number;
  workingCount: number;
  local: boolean;
  headerChanged: boolean;
  action:
    | "remList"
    | "remCheck"
    | "uploadList"
    | "uploadCheck"
    | "uploadDiscos"
    | "uploadCount";
  uploadStatus: string;
  done: boolean;
  type: string;
  checkLocked: boolean;
  depDate: string;
  addedShipper: string;
  salesIds?: string[];
  custName?: string;
  custAccount?: string;
  projectNo?: string;
  convertedDepDate?: any;
  combinedText?: string;
  itemCount?: number;
}
