import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Picking } from '../models/picking';
import { PickingService } from '../services/picking.service';

@Component({
  selector: 'app-picking-header',
  templateUrl: './picking-details.component.html',
  styleUrls: ['./picking-details.component.scss']
})
export class PickingDetailsComponent implements OnInit {
  tempPicking: Picking = new Picking();

  constructor(
    private modalCtrl: ModalController,
    private pickingService: PickingService
  ) { }

  ngOnInit(): void {
    Object.assign(this.tempPicking,this.pickingService.currentPicking);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  openYardcam(number: string, type?: string) {
    if(type) {
      window.open(environment.yardCamLink + '?voucherType=' + type + '&searchText=' + number,'_blank');
    } else {
      window.open(environment.yardCamLink + '?searchText=' + number,'_blank');
    }
  }

  calculatePickedWeight() {
    let pickedWeight: number = 0;
    this.tempPicking.items.forEach(currentItem => {
      pickedWeight += ((currentItem.pickedNew ? currentItem.pickedNew : 0) + (currentItem.pickedUsed ? currentItem.pickedUsed : 0)) * currentItem.weight;
    });
    return pickedWeight;
  }

}
