<ion-header>
  <ion-toolbar>
    <ion-title>{{ "ADD_ARTICLE" | i18nextEager }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="articleListContainer">
    <div class="listHeader">
      <ion-searchbar
        (ionChange)="onSearch()"
        [(ngModel)]="searchText"
      ></ion-searchbar>
      <doka-button
        class="searchButton"
        (click)="onSearch()"
        [disabled]="isLoadingArticles"
      >
        {{ "SEARCH" | i18nextEager }}
      </doka-button>
    </div>
    <div class="listContent">
      <doka-spinner *ngIf="isLoadingArticles"></doka-spinner>
      <div
        *ngFor="let article of articles"
        (click)="selectArticle(article)"
        class="articleElement ion-activatable ripple-parent rectangle"
      >
        <ion-ripple-effect></ion-ripple-effect>
        <div class="articleSelect">
          <ion-radio-group
            *ngIf="!selectMultiple"
            [(ngModel)]="selectedArticle"
          >
            <ion-radio [value]="article"></ion-radio>
          </ion-radio-group>
          <doka-checkbox
            *ngIf="selectMultiple"
            [checked]="isArticleSelected(article)"
          ></doka-checkbox>
        </div>
        <div class="articleThumbnail">
          <ion-thumbnail>
            <img
              *ngIf="
                !errorLoadingArticleImage.includes(article.articleIdentifier)
              "
              [src]="generateArticleImageUrl(article.articleIdentifier)"
              (error)="errorLoadingArticleImage.push(article.articleIdentifier)"
            />
            <img
              *ngIf="
                errorLoadingArticleImage.includes(article.articleIdentifier)
              "
              src="assets/noImageAvailable.png"
            />
          </ion-thumbnail>
        </div>
        <div class="articleInfos">
          <h5>
            {{ article.name }}
          </h5>
          <p>#{{ article.articleIdentifier }}</p>
        </div>
      </div>
      <ion-infinite-scroll
        *ngIf="!isLoadingArticles"
        (ionInfinite)="onReload($event)"
      >
        <div class="infinite-scroll-content">
          <doka-spinner
            [ngClass]="{ notVisible: !isLoadingMoreArticles }"
            class="infiniteScrollSpinner"
          ></doka-spinner>
        </div>
      </ion-infinite-scroll>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="footerButtons">
    <doka-button (click)="dismissModal()">
      {{ "CANCEL" | i18nextEager }}
    </doka-button>
    <doka-button
      (click)="onAddItem()"
      [disabled]="
        selectMultiple
          ? selectedArticles.length === 0
          : selectedArticle === null
      "
      color="primary"
    >
      {{ "ADD_ARTICLE" | i18nextEager }}
    </doka-button>
  </div>
</ion-footer>
