<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          name="close"
          size="2xl"
          type="filled"
          slot="icon-only"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ "ADD_USER_HEADER" | i18nextEager }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item class="searchBar">
    <ion-searchbar
      [(ngModel)]="searchText"
      (ionInput)="loadUsers()"
    ></ion-searchbar>
  </ion-item>
  <doka-spinner *ngIf="isLoadingUsers"></doka-spinner>
  <ion-label *ngIf="!isLoadingUsers && users.length === 0">{{
    "NO_USERS" | i18nextEager
  }}</ion-label>
  <ion-list *ngIf="!isLoadingUsers && users.length > 0" lines="none">
    <ion-item *ngFor="let user of users" (click)="selectUser(user)" button>
      <ion-thumbnail slot="start">
        <ion-img [src]="user.profilePictureUri"></ion-img>
      </ion-thumbnail>
      {{ user.firstName }} {{ user.lastName }}
      {{
        user.firstName !== undefined || user.lastName !== undefined
          ? "- " + user.userName
          : user.userName
      }}
    </ion-item>
  </ion-list>
</ion-content>
