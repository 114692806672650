import { Component, Input, OnInit } from '@angular/core';
import { Article } from 'src/app/models/articles';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-article-image',
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss'],
})
export class ArticleImageComponent implements OnInit {

  @Input("articleIdentifier") articleIdentifier;
  url: string = "";
  isLoading: boolean = true;
  article: Article | null = null;

  constructor(
    private webService: WebService
  ) { }

  async ngOnInit() {
    this.url = "https://api.test.doka.com/articles/" + this.articleIdentifier + "/assets/images?type=Rendering&renditionType=Full";
    let article = await this.webService.getArticleByIdentifier(this.articleIdentifier);
    if (article.packages[0] && article.packages[0].articleIdentifier) {
      let colliArticle = await this.webService.getArticleByIdentifier(article.packages[0].articleIdentifier);
      article.packages[0].packagingArticle = colliArticle;
    }
    this.article = article;
  }

  displayErrorImage() {
    this.url = "assets/imgs/noImageAvailable.png";
  }
}
