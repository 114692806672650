import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.css']
})
export class DateRangeFilterComponent implements OnInit {

  @ViewChild("picker") picker: MatDateRangePicker<any>;
  @Output() valueChange = new EventEmitter();
  dateStart: string = null;
  dateEnd: string = null;
  @ViewChild("dateRange") dateRange;

  constructor() { }

  ngOnInit(): void {
  }

  dateChanged(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if (dateRangeStart.value !== "" && dateRangeEnd.value !== "") {
      this.dateStart = new Date(dateRangeStart.value).toString();
      this.dateEnd = new Date(dateRangeEnd.value).toString();
      this.valueChange.emit({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      });
    }
  }

  deleteDate() {
    this.dateStart = null;
    this.dateEnd = null;
    this.dateRange.value.start = null;
    this.dateRange.value.end = null;
    this.picker.close();
    this.valueChange.emit({
      dateStart: this.dateStart,
      dateEnd: this.dateEnd
    });
  }

  setDate(startDate: Date, endDate: Date) {
    this.picker.select(startDate);
    this.picker.select(endDate);
  }

}
