import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  NavController,
  AlertController,
  LoadingController,
} from "@ionic/angular";
import { environment } from "../../environments/environment";
import { DokaI18NextService } from '@doka-shared/i18next';
import { ConfigModel } from "../models/config";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root",
})

export class AuthenticationService {
  private _clientId = new BehaviorSubject<string>(null);
  private config: ConfigModel;
  public loginSub = new BehaviorSubject<boolean>(null);
  private authConfig: AuthConfig;
  public username: string;
  public useremail: string;
  public userFirstname: string;
  public userLastname: string;
  public userId: string;
  public profilePictureUrl: string = null;

  constructor(
    private navCrtl: NavController,
    private http: HttpClient,
    private alertCtrl: AlertController,
    private translate: DokaI18NextService,
    private configService: ConfigService,
    private loadingCtrl: LoadingController,
    private oauthService: OAuthService
  ) {
    /* clientIds:
        Prod: 4335d960-9a5c-4ad4-ab8f-29b9e2d545ee
        Test: 6c8b14e7-a8d1-4e4d-8027-a63335ed71a2
      */
    if (environment.production) {
      this.authConfig = {
        issuer: environment.groupIdUrl,
        strictDiscoveryDocumentValidation: false,
        redirectUri: window.location.origin + "/login",
        clientId: "4335d960-9a5c-4ad4-ab8f-29b9e2d545ee",
        scope: 'openid profile email roles offline_access yardmanager.doka.com/odl.write yardmanager.doka.com/odl.read yard-api.doka.com/common.read yard-api.doka.com/common.write ',
        responseType: 'code',
        showDebugInformation: true,
      }
    } else {
      this.authConfig = {
        issuer: environment.groupIdUrl,
        strictDiscoveryDocumentValidation: false,
        redirectUri: window.location.origin + "/login",
        clientId: "6c8b14e7-a8d1-4e4d-8027-a63335ed71a2",
        scope: 'openid profile email roles offline_access yardmanager.doka.com/odl.write yardmanager.doka.com/odl.read yard-api.doka.com/common.read yard-api.doka.com/common.write',
        responseType: 'code',
        showDebugInformation: true
      }
    }
    oauthService.configure(this.authConfig);
    oauthService.loadDiscoveryDocumentAndTryLogin().then(res => {
      console.log("OAuth Response ", res);
      console.log(oauthService.getAccessToken());
      oauthService.loadUserProfile().then((res: any) => {
        console.log(res);
        this.username = res.info.family_name + " " + res.info.given_name;
        this.useremail = res.info.preferred_username;
        this.userFirstname = res.info.given_name;
        this.userLastname = res.info.family_name;
        this.userId = res.info.sub;
        this.profilePictureUrl = res.info.picture ? res.info.picture : '../assets/imgs/user_default.png';
        this.loginSub.next(true);
      }).catch(err => {
        throw err;
      });
    }).catch(err => {
      console.error("OAuth ERROR ", err);
    });

    // Redirect to initially requested page
    this.oauthService.events.subscribe((event) => {
      if (event.type === "token_received" && this.oauthService.state) {
        const redirect_uri = decodeURIComponent(this.oauthService.state);
        console.log(redirect_uri);
        if (redirect_uri && redirect_uri !== "/") {
          console.log("redirecting to initially requested page", redirect_uri);
          window.location.href = redirect_uri;
        }
      }
    });
    oauthService.setupAutomaticSilentRefresh();
  }

  /* Loggin in User and displays alert when login fails */
  async login(loginToken: string, branchId: string) /* : Observable<any> */ {
    await this.configService.initConfigService();
    const application = "yardcam";
    this.loadingCtrl
      .create({
        message: this.translate.instant("LOGGING_IN"),
      })
      .then((loading) => {
        loading.present();
        this.http
          .get(environment.loginUrl, {
            headers: {
              "X-Doka-Authorization": loginToken,
              "X-Doka-Application": application,
            },
            observe: "response",
            responseType: "json"
          })
          .subscribe(
            async (data) => {
              loading.dismiss();
              console.log(data);
              console.log(data.headers.keys());
              const clientid = data.headers.get("x-doka-clientid");
              if (clientid) {
                console.log("Valid Token found!");

                const config = this.configService.getConfig();
                this.config = config;
                console.log(this.config);
                console.log(config);
                this.config.token = loginToken;
                this.config.clientid = clientid;
                this.config.branchId = branchId;
                this.configService.saveConfig(this.config).then(() => {
                  this.navCrtl.navigateForward("/dashboard");
                  this._clientId.next(clientid);
                });
              } else {
                console.log(this.translate.instant("INVALID_TOKEN"));
              }
            },
            (error) => {
              loading.dismiss();
              console.log("Invalid Token, access denied!");
              console.error(error);

              if (error.status == 401) {
                this.alertCtrl
                  .create({
                    header: this.translate.instant("TOKEN_POPUP_TITLE"),
                    subHeader: this.translate.instant("TOKEN_POPUP"),
                    buttons: [this.translate.instant("OK")],
                  })
                  .then((alert) => alert.present());
              } else {
                this.alertCtrl
                  .create({
                    header: this.translate.instant("UNKNOWN_ERROR"),
                    subHeader: this.translate.instant("UNKNOWN_ERROR_TEXT"),
                    buttons: [this.translate.instant("OK")],
                  })
                  .then((alert) => alert.present());
              }
            }
          );
      });
  }

  /* Skips this page when already logged in */
  autoLogin(userId) {
    this._clientId.next(userId);
  }

  /* Loggin out, means navigating to login Page and deleting token adn userid in App-Config */
  logout() {
    const config = this.configService.getConfig();
    this.configService.deleteConfig();
    this.oauthService.logOut();
    this._clientId.next(null);
    this.navCrtl.navigateBack("/login");
  }

  /* Getter for clientId */
  get clientId() {
    return this._clientId.asObservable();
  }

  /* Getter for Authentication Status */
  get isAuthenticated() {
    let isAuth = false;
    this._clientId.asObservable().subscribe((clientId) => {
      if (clientId !== null && clientId !== undefined) {
        isAuth = true;
      }
    }).unsubscribe;
    return isAuth;
  }

  get isOAuthLoggedIn() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  public oauthLogin(url?: string) {
    console.log("Login");
    this.oauthService.initLoginFlow(url);
  }
}
