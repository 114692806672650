<ion-app [ngClass]="{ screenSaverOpacity: screenSaverActive }">
  <ion-router-outlet
    id="main-content"
    (click)="closeUserMenu()"
  ></ion-router-outlet>
  <app-navigation
    (click)="isUserMenuOpen = false"
    [isMenuOpen]="fullScreenService.isMenuOpen"
    [ngClass]="{ menuIsClosed: !fullScreenService.isMenuOpen }"
    *ngIf="
      !this.router.url.includes('login') &&
      !this.router.url.includes('not-supported')
    "
  ></app-navigation>
  <ion-header
    *ngIf="
      !this.router.url.includes('login') &&
      !this.router.url.includes('not-supported')
    "
  >
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button
          (click)="fullScreenService.isMenuOpen = !fullScreenService.isMenuOpen"
        >
          <doka-icon
            type="filled"
            name="menu"
            size="xl"
            slot="icon-only"
            type="filled"
          ></doka-icon>
        </ion-button>
      </ion-buttons>
      <ion-img
        src="assets/dokalogo.svg"
        class="cursorPointer dokaLogo"
        slot="start"
        (click)="onLogo()"
        button
      >
      </ion-img>
      <div class="screenSaverActivateButton" (click)="startScreensaver()"></div>
      <div
        class="userMenuButton"
        [ngClass]="{ userMenuButtonOpen: isUserMenuOpen }"
        (click)="isUserMenuOpen = !isUserMenuOpen"
        slot="end"
      >
        <ion-thumbnail>
          <img
            [src]="
              authenticationService.profilePictureUrl !== null
                ? authenticationService.profilePictureUrl
                : 'assets/imgs/user_default.png'
            "
          />
        </ion-thumbnail>
        <ion-label>
          {{ authenticationService.username }}<br />
          {{ "MANDANT" | i18nextEager }}: {{ configService.config?.clientid }}
        </ion-label>
        <doka-icon
          type="filled"
          slot="end"
          name="arrow_drop_down"
          size="2xl"
        ></doka-icon>
      </div>
    </ion-toolbar>
  </ion-header>
  <div
    *ngIf="authenticationService.isAuthenticated"
    class="userMenu"
    [ngClass]="{ userMenuOpen: isUserMenuOpen }"
  >
    <ion-item lines="none" (click)="switchMandant()" button>
      <doka-icon
        type="filled"
        slot="start"
        name="swap_horiz"
        size="2xl"
      ></doka-icon>
      <ion-label>{{ "SWITCH_MANDANT" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="openHelp()" button>
      <doka-icon type="filled" slot="start" name="help" size="2xl"></doka-icon>
      <ion-label>{{ "HELP" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="openSettings()" button>
      <doka-icon
        type="filled"
        slot="start"
        name="settings"
        size="2xl"
      ></doka-icon>
      <ion-label>{{ "SETTINGS" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="logout()" button>
      <doka-icon
        type="filled"
        slot="start"
        name="logout"
        size="2xl"
      ></doka-icon>
      <ion-label>{{ "LOGOUT" | i18nextEager }}</ion-label>
    </ion-item>
  </div>
</ion-app>
<div
  (click)="stopScreensaver()"
  [ngClass]="{ screenSaverInvisible: !screenSaverActive }"
  class="screenSaverContainer"
  id="Container"
>
  <ion-img id="DVD" src="/assets/dokalogo.svg"></ion-img>
</div>
