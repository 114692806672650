import { NavController } from "@ionic/angular";
import { DatabaseService } from "./database.service";
import { ReconSuppItem } from "./../models/reconSuppItem";
import { Injectable } from "@angular/core";
import { Recon } from "../models/recon";
import { ReconItem } from "../models/reconItem";
import { WebService } from "./web.service";

@Injectable({
  providedIn: "root",
})
export class ReconService {
  public currentReconList: Recon[];
  public currentRecon: Recon;
  public currentReconId: string;
  public currentItemId: string;
  public currentSpareParts: ReconSuppItem[];
  public currentSearchArray = [];
  public openItems: ReconItem[] = [];

  constructor(
    private databaseService: DatabaseService,
    private navCtrl: NavController,
    private webService: WebService
  ) { }

  async loadRecons(): Promise<Recon[]> {
    let allReconditioningOrders: Recon[] = [];
    let loadedReconditioningOrdersCount: number = 0;
    do {
      let reconditioningOrders = await this.webService.getReconditioningOrders(1000, allReconditioningOrders.length);
      loadedReconditioningOrdersCount = reconditioningOrders.length;
      for (let reconditioningOrder of reconditioningOrders) {

        if (reconditioningOrder.project) {
          this.webService.getProjectById(reconditioningOrder.project.id).then(project => reconditioningOrder.project = project);
        }

        if (reconditioningOrder.customer) {
          reconditioningOrder.customer['isLoading'] = true;
          this.webService.getCustomerById(reconditioningOrder.customer.id).then(customer => reconditioningOrder.customer = customer);
        }

        for (let reconditioningOrderItem of reconditioningOrder.items) {
          this.webService.getArticleByIdentifier(reconditioningOrderItem.article.articleIdentifier).then(article => reconditioningOrderItem.article = article);

          for (let reconditioningOrderSuppItem of reconditioningOrderItem.supplementaryItems) {
            this.webService.getArticleByIdentifier(reconditioningOrderSuppItem.article.articleIdentifier).then(article => reconditioningOrderSuppItem.article = article);
          }
        }
      }
      allReconditioningOrders.push(...reconditioningOrders);
    } while (loadedReconditioningOrdersCount === 1000);

    this.currentReconList = allReconditioningOrders;
    return;
  }

  async loadRecon(reconId = this.currentReconId): Promise<Recon> {
    let reconditioningOrder = await this.webService.getReconditioningOrderById(reconId);
    if (reconditioningOrder.project) {
      this.webService.getProjectById(reconditioningOrder.project.id).then(project => reconditioningOrder.project = project);
    }

    if (reconditioningOrder.customer) {
      reconditioningOrder.customer['isLoading'] = true;
      this.webService.getCustomerById(reconditioningOrder.customer.id).then(customer => reconditioningOrder.customer = customer);
    }

    for (let reconditioningOrderItem of reconditioningOrder.items) {
      reconditioningOrderItem.article['isLoading'] = true;
      this.webService.getArticleByIdentifier(reconditioningOrderItem.article.articleIdentifier).then(article => reconditioningOrderItem.article = article);

      for (let reconditioningOrderSuppItem of reconditioningOrderItem.supplementaryItems) {
        this.webService.getArticleByIdentifier(reconditioningOrderSuppItem.article.articleIdentifier).then(article => reconditioningOrderSuppItem.article = article);
      }
    }

    this.currentRecon = reconditioningOrder;
    return reconditioningOrder;
  }

  async loadSpareparts(itemId: string = this.currentItemId) {
    if (!this.currentRecon) {
      this.navCtrl.navigateBack("recons");
    } else {
      this.currentSpareParts = this.currentRecon.items.find(
        (r) => r.id === itemId
      ).supplementaryItems;
      for (let currentSparepart of this.currentSpareParts) {
        this.webService.getArticleByIdentifier(currentSparepart.article.articleIdentifier).then(article => currentSparepart.article = article);
      }
      console.log(this.currentSpareParts);
    }
  }

  async loadOpenItems() {
    let allReconditioningOrders: Recon[] = [];
    let loadedReconditioningOrdersCount: number = 0;
    do {
      let reconditioningOrders = await this.webService.getReconditioningOrders(1000, allReconditioningOrders.length);
      loadedReconditioningOrdersCount = reconditioningOrders.length;
      for (let reconditioningOrder of reconditioningOrders) {
        if (reconditioningOrder.project) {
          reconditioningOrder.project = await this.webService.getProjectById(reconditioningOrder.project.id);
        }

        if (reconditioningOrder.customer) {
          reconditioningOrder.customer = await this.webService.getCustomerById(reconditioningOrder.customer.id);
        }

        for (let reconditioningOrderItem of reconditioningOrder.items) {
          this.webService.getArticleByIdentifier(reconditioningOrderItem.article.articleIdentifier).then(article => reconditioningOrderItem.article = article);

          for (let reconditioningOrderSuppItem of reconditioningOrderItem.supplementaryItems) {
            this.webService.getArticleByIdentifier(reconditioningOrderSuppItem.article.articleIdentifier).then(article => reconditioningOrderSuppItem.article = article);
          }

          reconditioningOrderItem.customer = reconditioningOrder.customer?.name;
          reconditioningOrderItem.project = reconditioningOrder.project?.name;
          reconditioningOrderItem.customerIdentifier = reconditioningOrder.customer?.customerIdentifier;
          reconditioningOrderItem.jobSite = reconditioningOrder.project?.constructionSite.name;
          reconditioningOrderItem.reconditioningOrderIdentifier =
            reconditioningOrder.reconditioningOrderIdentifier;
          reconditioningOrderItem.plannedFinishDate =
            reconditioningOrder.plannedFinishDate;
          reconditioningOrderItem.type = reconditioningOrder.type;
          reconditioningOrderItem.status = reconditioningOrder.status;
          this.openItems.push(reconditioningOrderItem);
        }
      }
    } while (loadedReconditioningOrdersCount === 1000)
    return;
  }

  unlockRecon(reconId: string) {
    return this.webService.unlockReconditioningOrderById(reconId);
  }
}
