import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { Application } from '../models/application';
import { AlertController, LoadingController } from '@ionic/angular';
import { DokaI18NextService } from '@doka-shared/i18next';
import { Role } from '../models/role';
import { User } from '../models/user';
import { UserRole } from '../models/userRole';
import { AuthenticationService } from './authentication.service';
import { Contractor } from '../models/contractor';
import { ConfigService } from './config.service';
import { Branch } from '../models/branch';
import { PostPermissionRequest } from '../models/postPermissionRequest';
import { PermissionRequest } from '../models/permissionRequest';
import { Recon } from '../models/recon';
import { Project } from '../models/project';
import { Customer } from '../models/customer';
import { ConstructionSite } from '../models/constructionSite';
import { Article } from '../models/articles';
import { DatabaseService } from './database.service';
import { FullPickingList } from '../models/pickingLists/full-picking-list-model';
import { BranchConfiguration } from '../models/branchConfiguration';
import { FullTransportOrder } from '../models/transportOrders/full-transport-order-model';
import { Group } from '../models/group';
import { CapacityPlanningWorker } from '../capacity-planning/capacity-planning-administration/models/capacityPlanningWorker';
import { CapacityBaseData } from '../capacity-planning/capacity-planning-administration/models/capacityBaseData';
import { CapacityTonnage } from '../capacity-planning/capacity-planning-administration/models/capacityTonnage';
import { Yard } from '../models/yard';
import { YardStock } from '../models/yardStock';
import { Redelivery } from '../models/returnDeliveries/redelivery';
import { LineItem } from '../models/returnDeliveries/lineItem';
import { lastValueFrom } from 'rxjs';
import { UpdateLineItemParameters } from '../models/returnDeliveries/updateLineItemParameters';
import { UpdateLineItemResponse } from '../models/returnDeliveries/updateLineItemResponse';
import { Sparepart } from '../models/returnDeliveries/sparepart';
import { MobileReturnDeliveryLineItemStatus } from '../models/returnDeliveries/mobileReturnDeliveryLineItemStatus';
import { AddLineItemParameters } from '../models/returnDeliveries/addLineItemParameters';
import { ShippingBoardResponse } from '../models/shippingBoardResponse';
import { ODLData } from '../models/odlData';
import { RedeliveryUser } from '../models/returnDeliveries/redeliveryUser';
import { MobileReturnDeliveryStatus } from '../models/returnDeliveries/mobileReturnDeliveryStatus';
import { Permission } from '../models/permission';
import { FullProject } from '../models/fullProject';
import { FullConstructionSite } from '../models/fullConstructionSite';
import { ShippingBoardReturnDeliveryResponse } from '../models/shippingBoardReturnDeliveryResponse';
import { ReturnStatusAverageTimesModel } from '../models/returnStatusAverageTimes.model';
import { TimeSpan } from '../models/timespan.model';
import { ReturnStatusAverageTimesResponseModel } from '../models/returnStatusAverageTimesResponseModel.model';
import { IReturnLineItemProcessingTimeResponseModel } from '../models/returnLineItemProcessingTimeResponse.model';
import { ReturnLineItemProcessingTimeModel } from '../models/returnLineItemProcessingTime.model';
import { IAverageODLLineItemResponse } from '../models/averageODLLineItemResponse.interface';
import { UpdateLineItemQuantityCustomerCountResponse } from '../models/returnDeliveries/updateLineItemQuantityCustomerCountResponse';
import { UpdateLineItemQuantityFirstSightResult } from '../models/returnDeliveries/updateLineItemQuantityFirstSightResult';
import { InboundOrder } from '../models/inboundOrders/inboundOrder.model';
import { CancelLineItemResponse } from '../models/returnDeliveries/cancelLineItemResponse';
import { CancelFinishingResponse } from '../models/returnDeliveries/cancelFinishingResponse';
import { ReturnStatusProcessingTime } from '../models/returnDeliveries/returnStatusProcessingTime';
import { CancelFirstSightResponse } from '../models/returnDeliveries/cancelFirstSightResponse.model';
import { RemoveProcessorResponse } from '../models/returnDeliveries/removeProcessorResponse.model';
import { StartRedeliveryResponse } from '../models/returnDeliveries/startRedeliveryResponse.model';
import { YardRolesResponse } from '../models/yardRolesResponse.model';
import { DetailedUser } from '../models/detailedUser';
import { YardRole } from '../models/yardRole.model';
import { StartInboundOrderCheckResponse } from '../models/inboundOrders/startInboundOrderCheckResponse.model';
import { CancelInboundOrderCheckResponse } from '../models/inboundOrders/cancelInboundOrderCheckResponse.model';
import { UpdateInboundOrderLineItemParameters } from '../models/inboundOrders/updateInboundLineItemParameters.model';
import { UpdateInboundOrderLineItemResponse } from '../models/inboundOrders/updateInboundOrderLineItemResponse.model';
import { CompleteInboundOrderCheck } from '../models/inboundOrders/completeInboundOrderCheckResponse.model';
import { CheckInboundOrderLineItemResponse } from '../models/inboundOrders/checkInboundOrderLineItemResponse.model';
import { AddInboundOrderLineItemParameters } from '../models/inboundOrders/addInboundOrderLineItemParameters.model';
import { InboundOrderLineItem } from '../models/inboundOrders/inboundOrderLineItem.model';
import { Building } from '../models/building.model';
import { Workplace } from '../models/workplace.model';
import { ReturnDeliveryHistory } from '../models/returnDeliveryHistory.interface';
import { AddReturnLineItemProcessingTime } from '../models/addReturnLineItemProcessingTime.model';
import { AddReturnLineItemFirstSightTime } from '../models/addReturnLineItemFirstSightTime.model';
import { DokaStock } from '../models/dokaStock';
import { FirstCountStockResponse } from '../models/firstCountStockResponse';
import { ReturnLineItemProcessingTimeType } from '../enum/returnLineItemProcessingTimeType.enum';
import { CancelInboundOrderUnloadingResponse } from '../models/inboundOrders/cancelInboundOrderUnloadingResponse';
import { YardDashboardBuilding } from '../models/yardDashboardBuilding';
import { Absence } from '../models/absence';
import { AbsenceType } from '../enum/absenceType.enum';
import { DetailedGroupAPIBranch } from '../models/detailedGroupAPIBranch';
import { SparepartResult } from '../models/sparepartResult.interface';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  private _loadedCustomers: Customer[] = [];
  private _loadedUsers: User[] = [];
  private _loadedProjects: Project[] = [];
  private _loadedFullProjects: FullProject[] = [];
  private _loadedFullConstructionSites: FullConstructionSite[] = [];

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private loadingCtrl: LoadingController,
    private translate: DokaI18NextService,
    private alertCtrl: AlertController,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private databaseService: DatabaseService
  ) { }

  private appendReturnDeliveryHeader(headers: any) {
    headers["branchIdentifier"] = this.configService.config.clientid;
  }

  async getApplications(top: number, skip: number): Promise<Application[]> {
    let requestUrl = environment.yardAPIUrl + "/applications?$top=" + top + "&$skip=" + skip;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let applications = await this.http.get<Application[]>(requestUrl, { headers: headers }).toPromise();
      return applications;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async addApplication(application: Application): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/applications";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("ADD_APPLICATION_LOADER"),
      spinner: 'crescent'
    });
    loadingEl.present();
    try {
      await this.http.post(requestUrl, { 'applicationName': application.applicationName }, { headers: headers }).toPromise();
      loadingEl.dismiss();
      return true;
    } catch (err) {
      console.error(err);
      loadingEl.dismiss();
      if (err.status === 409) {
        await this.showExistsError(this.translate.instant("ADD_APPLICATION_EXISTS_MESSAGE").replace("$value", application.applicationName));
      }
      return false;
    }
  }

  async updateApplication(application: Application): Promise<Application> {
    const requestUrl = environment.yardAPIUrl + "/applications/update";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      applicationId: application.id,
      isWorkRelevant: application.isWorkRelevant
    };

    try {
      let updateApplicationResult = await lastValueFrom(this.http.patch<Application>(requestUrl, body, { headers }));
      return updateApplicationResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async removeApplication(application: Application): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/applications/" + application.id;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("REMOVE_APPLICATION_LOADING"),
      spinner: 'crescent'
    });
    loadingEl.present();

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      loadingEl.dismiss();
      return true;
    } catch (err) {
      console.error(err);
      loadingEl.dismiss();
      return false;
    }
  }

  async getRoles(top: number, skip: number): Promise<Role[]> {
    let requestUrl = environment.yardAPIUrl + "/roles?$top=" + top + "&$skip=" + skip;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let roles = await this.http.get<Role[]>(requestUrl, { headers: headers }).toPromise();
      return roles;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getRoleById(roleId: string): Promise<Role> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let role = await lastValueFrom(this.http.get<Role>(requestUrl, { headers }));
      return role;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async addRole(role: Role): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/roles";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("ADD_ROLE_LOADER"),
      spinner: 'crescent'
    });
    loadingEl.present();
    try {
      await this.http.post(requestUrl, { 'roleName': role.roleName }, { headers: headers }).toPromise();
      loadingEl.dismiss();
      return true;
    } catch (err) {
      console.error(err);
      loadingEl.dismiss();
      if (err.status === 409) {
        await this.showExistsError(this.translate.instant("ADD_ROLE_EXISTS_MESSAGE").replace("$value", role.roleName));
      }
      return false;
    }
  }

  async updateRole(role: Role): Promise<Role> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + role.id;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      canBeRequested: role.canBeRequested
    };

    try {
      let updateRoleResult = await lastValueFrom(this.http.patch<Role>(requestUrl, body, { headers }));
      return updateRoleResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async removeRole(role: Role): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + role.id;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("REMOVE_ROLE_LOADING"),
      spinner: 'crescent'
    });
    loadingEl.present();

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      loadingEl.dismiss();
      return true;
    } catch (err) {
      console.error(err);
      loadingEl.dismiss();
      return false;
    }
  }

  async getApplicationsByRoleId(roleId: string, top: number, skip: number): Promise<Application[]> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/applications?$top=" + top + "&$skip=" + skip;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let applications = await this.http.get<Application[]>(requestUrl, { headers: headers }).toPromise();
      return applications;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async addApplicationToRole(roleId: string, applicationId: string) {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/applications?applicationId=" + applicationId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.post(requestUrl, {}, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async removeApplicationFromRole(roleId: string, applicationId: string) {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/applications/" + applicationId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getUsersByBranchId(branchId: string, searchText: string, top: number, skip: number): Promise<User[]> {
    let requestUrl = environment.yardAPIUrl + "/branches/" + branchId + "/users?$top=" + top + "&$skip=" + skip;
    if (searchText !== "") {
      requestUrl += "&searchText=" + searchText;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let users = await this.http.get<User[]>(requestUrl, { headers: headers }).toPromise();
      return users;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getUserById(userId: string): Promise<User> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let user = this._loadedUsers.find(u => u.id === userId);
    if (user) {
      return user;
    } else {
      try {
        let user = await this.http.get<User>(requestUrl, { headers: headers }).toPromise();
        this._loadedUsers.push(user);
        return user;
      } catch (err) {
        console.error(err);
        return new User("", "", "", "");
      }
    }
  }

  async getDetailedUserById(userId: string): Promise<DetailedUser> {
    const requestUrl = environment.yardAPIUrl + "/users/" + userId + "/details";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let userDetailResponse = await lastValueFrom(this.http.get<DetailedUser>(requestUrl, { headers }));
      return userDetailResponse;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async removeUserFromBranch(branchId: string, userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/branches/" + branchId + "/users/" + userId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("REMOVE_USER_BRANCH_LOADING"),
      spinner: 'crescent'
    });
    await loadingEl.present();
    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      await loadingEl.dismiss();
      return true;
    } catch (err) {
      console.error(err);
      await loadingEl.dismiss();
      return false;
    }
  }

  async getRolesByUserIdAndBranchId(userId: string, branchId?: string, applicationName?: string): Promise<UserRole[]> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/roles";
    if (branchId && applicationName) {
      requestUrl += "?branchId=" + branchId + "&applicationName=" + applicationName;
    } else if (branchId) {
      requestUrl += "?branchId=" + branchId;
    } else if (applicationName) {
      requestUrl += "?applicationName=" + applicationName;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let userRoles = await this.http.get<UserRole[]>(encodeURI(requestUrl), { headers: headers }).toPromise();
      return userRoles;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async addRoleToUserWithBranchId(roleId: string, userId: string, branchId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/roles?branchId=" + branchId + "&roleId=" + roleId + "&creatorEmail=" + this.authenticationService.useremail;
    branchId ? requestUrl += "&branchId=" + branchId : requestUrl += "&branchId=" + this.configService.config.branchId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.post(requestUrl, {}, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async removeRoleFromUser(userId: string, roleId?: string, branchId?: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/roles";
    if (roleId && branchId) {
      requestUrl += "?roleId=" + roleId + "&branchId=" + branchId;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getContractors(): Promise<Contractor[]> {
    let requestUrl = environment.yardAPIUrl + "/contractors";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let contractors = await this.http.get<Contractor[]>(requestUrl, { headers: headers }).toPromise();
      return contractors;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getUsersByContractorId(contractorId: string): Promise<User[]> {
    let requestUrl = environment.yardAPIUrl + "/contractors/" + contractorId + "/users";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let users = await this.http.get<User[]>(requestUrl, { headers: headers }).toPromise();
      return users;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getAllUsers(top: number, skip: number, searchString?: string): Promise<User[]> {
    let requestUrl = environment.yardAPIUrl + "/users/searchUsers?includeProfilePictureUri=true&$top=" + top + "&$skip=" + skip;
    if (searchString) {
      requestUrl += "&searchString=" + searchString;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let users = await this.http.get<User[]>(requestUrl, { headers: headers }).toPromise();
      return users;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getAssignableRolesByRoleId(roleId: string, top: number, skip: number): Promise<Role[]> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/assignableRoles?$top=" + top + "&$skip=" + skip;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let assignableRoles = await this.http.get<Role[]>(requestUrl, { headers: headers }).toPromise();
      return assignableRoles;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async addAssignableRoleToRole(roleId: string, assignableRoleId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/assignableRoles?assignableRoleId=" + assignableRoleId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.post(requestUrl, {}, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async removeAssignableRoleFromRole(roleId: string, assignableRoleId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/assignableRoles/" + assignableRoleId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getAssignableRolesByUserIdAndBranchId(userId: string, top: number, skip: number, branchId?: string): Promise<Role[]> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/assignableRoles?$top=" + top + "&$skip=" + skip;
    if (branchId) {
      requestUrl += "&branchId=" + branchId;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let roles = await this.http.get<Role[]>(requestUrl, { headers: headers }).toPromise();
      return roles;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getUsers(searchText: string, top: number, skip: number): Promise<User[]> {
    let requestUrl = environment.yardAPIUrl + "/users?$top=" + top + "&$skip=" + skip;
    if (searchText !== "") {
      requestUrl += "&searchString=" + searchText;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let users = await this.http.get<User[]>(encodeURI(requestUrl), { headers: headers }).toPromise();
      return users;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getBranches(top: number, skip: number): Promise<Branch[]> {
    let requestUrl = environment.yardAPIUrl + "/branches?$top=" + top + "&$skip=" + skip + "&isoLanguageCode=" + this.databaseService.getIsoLanguageCode();
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let branches = await this.http.get<Branch[]>(requestUrl, { headers: headers }).toPromise();
      return branches;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getBranchesByUserIdAndRoleId(userId: string, roleId: string): Promise<Branch[]> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/roles/" + roleId + "/branches";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let branches = await this.http.get<Branch[]>(requestUrl, { headers: headers }).toPromise();
      return branches;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async addPermissionRequest(permissionRequest: PostPermissionRequest): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/permission-requests";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let body = {
      userId: permissionRequest.userId,
      roleId: permissionRequest.roleId,
      branchId: permissionRequest.branchId
    }

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getPermissionRequests(userId?: string, branchId?: string): Promise<PermissionRequest[]> {
    let requestUrl = environment.yardAPIUrl + "/permission-requests";
    if (userId && branchId) {
      requestUrl += "?userId=" + userId + "&branchId=" + branchId;
    } else if (userId) {
      requestUrl += "?userId=" + userId;
    } else if (branchId) {
      requestUrl += "?branchId=" + branchId;
    }
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let permissionRequests = await this.http.get<PermissionRequest[]>(requestUrl, { headers: headers }).toPromise();
      return permissionRequests;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getApproveablePermissionRequests(): Promise<PermissionRequest[]> {
    let requestUrl = environment.yardAPIUrl + "/users/" + this.oAuthService.getIdentityClaims()['sub'] + "/approveablePermissionRequests";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let permissionRequests = await this.http.get<PermissionRequest[]>(requestUrl, { headers: headers }).toPromise();
      return permissionRequests;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async removePermissionRequest(permissionRequestId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/permission-requests/" + permissionRequestId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.delete(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getAssignableBranchesByUserId(userId: string): Promise<Branch[]> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/assignableBranches?isoLanguageCode=" + isoLanguageCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let branches = await this.http.get<Branch[]>(requestUrl, { headers: headers }).toPromise();
      return branches;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async showExistsError(errorMessage: string) {
    let alertEl = await this.alertCtrl.create({
      header: this.translate.instant("ALREADY_EXISTS_ERROR"),
      message: errorMessage,
      buttons: [
        {
          text: this.translate.instant("OK")
        }
      ]
    });
    await alertEl.present();
  }

  async getReconditioningOrders(top: number, skip: number): Promise<Recon[]> {
    let requestUrl = environment.yardAPIUrl + "/reconditioning-orders";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    var body = {
      pagination: {
        top: top,
        skip: skip
      },
      filtering: {
        branchId: this.configService.config.branchId,
        mobileReconditioningStatus: [
          "Counting",
          "Editing"
        ],
        reconditioningStatus: [
          "Open",
          "InProgress"
        ]
      }
    };

    try {
      let recons = await this.http.post<Recon[]>(requestUrl, body, { headers: headers }).toPromise();
      return recons;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getReconditioningOrderById(reconditioningOrderId: string): Promise<Recon> {
    let requestUrl = environment.yardAPIUrl + "/reconditioning-orders/" + reconditioningOrderId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let recon = await this.http.get<Recon>(requestUrl, { headers: headers }).toPromise();
      return recon;
    } catch (err) {
      console.error(err);
      throw err;
      return new Recon();
    }
  }

  async getProjectById(projectId: string): Promise<Project> {
    let projectRequestUrl = environment.yardAPIUrl + "/projects/" + projectId;
    let constructionSitesRequestUrl = environment.yardAPIUrl + "/projects/" + projectId + "/construction-sites";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let project = this._loadedProjects.find(p => p.id === projectId);
    if (project) {
      return project;
    } else {
      try {
        let resultProject = await this.http.get(projectRequestUrl, { headers: headers }).toPromise();
        let project: Project = new Project();
        project.id = resultProject['id'];
        project.projectIdentifier = resultProject['projectIdentifier'];
        project.name = resultProject['name'];

        let resultJobSites = await this.http.get(constructionSitesRequestUrl, { headers: headers }).toPromise();
        project.constructionSite = new ConstructionSite();
        project.constructionSite.siteIdentifier = resultJobSites[0]['siteIdentifier'];
        project.constructionSite.name = resultJobSites[0]['name'];

        this._loadedProjects.push(project);

        return project;
      } catch (err) {
        console.error(err);
        return new Project();
      }
    }
  }

  async getFullProjectById(projectId: string): Promise<FullProject> {
    let requestUrl = environment.yardAPIUrl + "/projects/" + projectId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let project = this._loadedFullProjects.find(p => p.id === projectId);

    if (project) {
      return project;
    } else {
      try {
        project = await lastValueFrom(this.http.get<FullProject>(requestUrl, { headers }));
        this._loadedFullProjects.push(project);
        return project;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }

  async getFullConstructionSiteById(constructionSiteId: string): Promise<FullConstructionSite> {
    let requestUrl = environment.yardAPIUrl + "/construction-sites/" + constructionSiteId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let constructionSite = this._loadedFullConstructionSites.find(cs => cs.id === constructionSiteId);

    if (constructionSite) {
      return constructionSite;
    } else {
      try {
        let constructionSite = await lastValueFrom(this.http.get<FullConstructionSite>(requestUrl, { headers }));
        this._loadedFullConstructionSites.push(constructionSite);
        return constructionSite;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }

  async getCustomerById(customerId: string): Promise<Customer> {
    let requestUrl = environment.yardAPIUrl + "/customers/" + customerId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let customer = this._loadedCustomers.find(c => c.id === customerId);

    if (customer) {
      return customer;
    } else {
      try {
        let resultCustomer = await this.http.get(requestUrl, { headers: headers }).toPromise();
        let customer: Customer = new Customer();
        customer.id = resultCustomer['id'];
        customer.customerIdentifier = resultCustomer['customerIdentifier'];
        customer.name = resultCustomer['name'];
        this._loadedCustomers.push(customer);
        return customer;
      } catch (err) {
        console.error(err);
        return new Customer();
      }
    }
  }

  async getArticleByIdentifier(articleIdentifier: string): Promise<Article> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    const isoCountryCode = await this.databaseService.getBranchIsoCountryCode();
    const requestUrl =
      environment.yardAPIUrl +
      "/articles/" +
      articleIdentifier +
      "?isoLanguageCode=" +
      isoLanguageCode +
      "&isoCountryCode=" +
      isoCountryCode +
      "&includePackages=true";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let article = await this.http.get<Article>(requestUrl, { headers: headers }).toPromise();
      return article;
    } catch (err) {
      console.error(err);
      return new Article();
    }
  }

  async getArticleById(id: string): Promise<Article> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    const isoCountryCode = await this.databaseService.getBranchIsoCountryCode();
    const requestUrl =
      environment.yardAPIUrl +
      "/articles/" +
      id +
      "?isoLanguageCode=" +
      isoLanguageCode +
      "&isoCountryCode=" +
      isoCountryCode +
      "&includePackages=true";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let article = await this.http.get<Article>(requestUrl, { headers: headers }).toPromise();
      return article;
    } catch (err) {
      console.error(err);
      return new Article();
    }
  }

  async getSparepartsByArticleId(articleId: string, top: number, skip: number): Promise<SparepartResult[]> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    const requestUrl = environment.yardAPIUrl + "/articles/" + articleId + "/spareparts?isoLanguageCode=" + isoLanguageCode + "&$top=" + top + "&$skip=" + skip;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      return await lastValueFrom(this.http.get<SparepartResult[]>(requestUrl, { headers: headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPickingsByCurrentBranchId(top: number, skip: number): Promise<FullPickingList[]> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/picking-lists/details?isoLanguageCode=" + isoLanguageCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      pagination: {
        top,
        skip
      },
      filtering: {
        branchId: this.configService.config.branchId,
        statuses: [
          "Activated"
        ],
        mobilePickingStatuses: [
          "ReadyForPicking",
          "Picking",
          "PickingPartlyDone",
          "ReadyForChecking",
          "Checking",
          "Rejected",
          "InReview",
          "Completed"
        ]
      }
    };

    try {
      let pickingLists = await this.http.post<FullPickingList[]>(requestUrl, requestBody, { headers: headers }).toPromise();
      return pickingLists;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getBranchConfigurationByCurrentBranchId(branchId?: string): Promise<BranchConfiguration> {
    let requestUrl = environment.yardAPIUrl + "/branches/" + (branchId ? branchId : this.configService.config.branchId) + "/branchConfiguration";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let branchConfiguration = await this.http.get<BranchConfiguration>(requestUrl, { headers: headers }).toPromise();
      return branchConfiguration;
    } catch (err) {
      console.error(err);
      return new BranchConfiguration();
    }
  }

  async removeCheckerFromPickingListById(pickingListId: string, userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/remove-checker";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId
    };

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async deactivatePickerFromPickingListById(pickingListId: string, userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/deactivate-picker";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId
    };

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getTransportOrdersByCurrentBranchId(top: number, skip: number): Promise<FullTransportOrder[]> {
    let requestUrl = environment.yardAPIUrl + "/transport-orders?isoLanguageCode=" + this.databaseService.getIsoLanguageCode();
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      pagination: {
        top,
        skip
      },
      filtering: {
        branchId: this.configService.config.branchId,
        statuses: [
          "Approved"
        ],
        mobileLoadingStatuses: [
          "ReadyForLoading",
          "Loading",
          "LoadingPartlyDone",
          "ReadyForChecking",
          "Checking",
          "Completed",
          "InReview"
        ]
      }
    };

    try {
      let transportOrders = await this.http.post<FullTransportOrder[]>(requestUrl, requestBody, { headers: headers }).toPromise();
      return transportOrders;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getTransportOrderById(transportOrderId: string): Promise<FullTransportOrder> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/transport-orders/" + transportOrderId + "?isoLanguageCode=" + isoLanguageCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let transportOrder = await this.http.get<FullTransportOrder>(requestUrl, { headers }).toPromise();
      return transportOrder;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPickingListsByTransportOrderId(transportOrderId: string, top: number, skip: number, branchId?: string): Promise<FullPickingList[]> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/picking-lists/details?isoLanguageCode=" + isoLanguageCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      pagination: {
        top,
        skip
      },
      filtering: {
        branchId: branchId ? branchId : this.configService.config.branchId,
        transportOrderIds: [
          transportOrderId
        ]
      }
    };

    try {
      let pickingLists = await this.http.post<FullPickingList[]>(requestUrl, requestBody, { headers: headers }).toPromise();
      for (let pickingList of pickingLists) {
        for (let lineItem of pickingList.lineItems) {
          lineItem['articleName'] = this.generateArticleName(lineItem['articleName'], lineItem.serialNumberIdentifier !== "", false, lineItem.serialNumberIdentifier, { length: lineItem.length, width: lineItem.width, depth: lineItem.depth }, lineItem.batchIdentifier);
        }
      }
      return pickingLists;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getSinglePickingList(pickingListId: string): Promise<FullPickingList> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "?isoLanguageCode=" + isoLanguageCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let pickingList = await this.http.get<FullPickingList>(requestUrl, { headers: headers }).toPromise();
      for (let lineItem of pickingList.lineItems) {
        lineItem['articleName'] = this.generateArticleName(lineItem['articleName'], lineItem.serialNumberIdentifier !== "", false, lineItem.serialNumberIdentifier, { length: lineItem.length, width: lineItem.width, depth: lineItem.depth }, lineItem.batchIdentifier);
      }
      return pickingList;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async deactivateLoaderFromTransportOrderById(transportOrderId: string, userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/transport-orders/" + transportOrderId + "/deactivate-loader";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      userId
    };

    try {
      this.http.post(requestUrl, requestBody, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async removeCheckerFromTransportOrderById(transportOrderId: string, userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/transport-orders/" + transportOrderId + "/remove-checker";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      userId
    };

    try {
      this.http.post(requestUrl, requestBody, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async changeUserPassword(userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/resetPassword";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let url = await this.http.get(requestUrl, { headers: headers, responseType: "text" as "text" }).toPromise();
      window.open(url, "_blank");
      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async forceChangeUserPassword(userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId + "/forcePasswordReset";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      this.http.get(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async deleteUser(userId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/users/" + userId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      this.http.delete(requestUrl, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async addUserToGroup(userId: string, groupId: string): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/groups/" + groupId + "/users/" + userId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      this.http.post(requestUrl, {}, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getGroups(): Promise<Group[]> {
    let requestUrl = environment.yardAPIUrl + "/groups";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let groups = await this.http.get<Group[]>(requestUrl, { headers: headers }).toPromise();
      return groups;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getCapacityPlanningWorkers(startDate: string, endDate: string): Promise<CapacityPlanningWorker[]> {
    let requestUrl = environment.yardAPIUrl + "/capacity-planning/workers?branchId=" + this.configService.config.branchId + "&startDate=" + startDate + "&endDate=" + endDate;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let workers = await this.http.get<CapacityPlanningWorker[]>(requestUrl, { headers: headers }).toPromise();
      return workers;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async bulkSaveCapacityPlanningWorkers(capacityPlanningWorkers: CapacityPlanningWorker[]): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/capacity-planning/workers/bulk";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let body = [];

    for (let capacityPlanningWorker of capacityPlanningWorkers) {
      let worker = {
        branchId: this.configService.config.branchId,
        date: capacityPlanningWorker.date,
        redeliveryDoka: capacityPlanningWorker.redeliveryDoka ? capacityPlanningWorker.redeliveryDoka : 0,
        redeliveryLeasing: capacityPlanningWorker.redeliveryLeasing ? capacityPlanningWorker.redeliveryLeasing : 0,
        reconditioningDoka: capacityPlanningWorker.reconditioningDoka ? capacityPlanningWorker.reconditioningDoka : 0,
        reconditioningLeasing: capacityPlanningWorker.reconditioningLeasing ? capacityPlanningWorker.reconditioningLeasing : 0,
        pickingDoka: capacityPlanningWorker.pickingDoka ? capacityPlanningWorker.pickingDoka : 0,
        pickingLeasing: capacityPlanningWorker.pickingLeasing ? capacityPlanningWorker.pickingLeasing : 0,
        forkliftDriver: capacityPlanningWorker.forkliftDriver ? capacityPlanningWorker.forkliftDriver : 0,
        operativeOrganizer: capacityPlanningWorker.operativeOrganizer ? capacityPlanningWorker.operativeOrganizer : 0
      }
      body.push(worker);
    }

    console.log(body);

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getCapacityBaseData(): Promise<CapacityBaseData> {
    let requestUrl = environment.yardAPIUrl + "/capacity-planning/baseData?branchId=" + this.configService.config.branchId;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let baseData = await this.http.get<CapacityBaseData>(requestUrl, { headers: headers }).toPromise();
      baseData.exists = true;
      return baseData;
    } catch (err) {
      console.error(err);
      return new CapacityBaseData(0, 0, 0, 0, false, []);
    }
  }

  async createCapacityBaseData(capacityBaseData: CapacityBaseData): Promise<boolean> {
    let requestUrl = environment.yardAPIUrl + "/capacity-planning/baseData";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let body = {
      branchId: this.configService.config.branchId,
      maximumRedeliveryCapacity: capacityBaseData.maximumRedeliveryCapacity ? capacityBaseData.maximumRedeliveryCapacity : 0,
      maximumReconditioningCapacity: capacityBaseData.maximumReconditioningCapacity ? capacityBaseData.maximumReconditioningCapacity : 0,
      targetRedeliveryCapacity: capacityBaseData.targetRedeliveryCapacity ? capacityBaseData.targetRedeliveryCapacity : 0,
      targetReconditioningCapacity: capacityBaseData.targetReconditioningCapacity ? capacityBaseData.targetReconditioningCapacity : 0,
      workDays: capacityBaseData.workDays
    }

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async updateCapacityBaseData(capacityBaseData: CapacityBaseData) {
    let requestUrl = environment.yardAPIUrl + "/capacity-planning/baseData";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let body = {
      branchId: this.configService.config.branchId,
      maximumRedeliveryCapacity: capacityBaseData.maximumRedeliveryCapacity ? capacityBaseData.maximumRedeliveryCapacity : 0,
      maximumReconditioningCapacity: capacityBaseData.maximumReconditioningCapacity ? capacityBaseData.maximumReconditioningCapacity : 0,
      targetRedeliveryCapacity: capacityBaseData.targetRedeliveryCapacity ? capacityBaseData.targetRedeliveryCapacity : 0,
      targetReconditioningCapacity: capacityBaseData.targetReconditioningCapacity ? capacityBaseData.targetReconditioningCapacity : 0,
      workDays: capacityBaseData.workDays
    };

    try {
      await this.http.put(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getCapacityTonnages(startDate: string, endDate: string): Promise<CapacityTonnage[]> {
    let requestUrl = environment.yardAPIUrl + "/capacity-tonnages?branchId=" + this.configService.config.branchId + "&startDate=" + startDate + "&endDate=" + endDate;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let capacityTonnage = await this.http.get<CapacityTonnage[]>(requestUrl, { headers: headers }).toPromise();
      return capacityTonnage;
    } catch (err) {
      console.error(err);
      return [];
    }

  }

  async getYards() {
    let requestUrl = "https://api.doka.com/branches/1a196b74-2620-ec11-981e-c8966545135f/yards";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let test = await this.http.get(requestUrl, { headers: headers }).toPromise();
      console.log(test);
    } catch (err) {
      console.error(err);
    }
  }

  async getStockByYardId(yardId: string, top: number, skip: number): Promise<DokaStock[]> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let isoCountryCode = await this.getIsoCountryCodeByBranchId(this.configService.config.branchId);
    let requestUrl = environment.yardAPIUrl + "/yard/" + yardId + "/stock?branchId=" + this.configService.config.branchId + "&isoLanguageCode=" + isoLanguageCode + "&isoCountryCode=" + isoCountryCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      pagination: {
        top: top,
        skip: skip
      },
      filtering: {
        owners: ["Doka"]
      }
    };

    try {
      let yardStock = await lastValueFrom(this.http.post<DokaStock[]>(requestUrl, body, { headers: headers }));
      return yardStock;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getFirstCountStockByCurrentBranch(): Promise<FirstCountStockResponse[]> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let isoCountryCode = await this.getIsoCountryCodeByBranchId(this.configService.config.branchId);
    let requestUrl = environment.yardAPIUrl + "/first-count-yard/" + this.configService.config.branchId + "/stock?isoLanguageCode=" + isoLanguageCode + "&isoCountryCode=" + isoCountryCode;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let firstCountStock = await lastValueFrom(this.http.get<FirstCountStockResponse[]>(requestUrl, { headers: headers }));
      return firstCountStock;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getYardsByBranchId(): Promise<Yard[]> {
    let requestUrl = environment.yardAPIUrl + "/branches/" + this.configService.config.branchId + "/yards";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let yards = await this.http.get<Yard[]>(requestUrl, { headers: headers }).toPromise();
      return yards;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getUsersByBranchIdAndRolename(roleName: string): Promise<User[]> {
    let requestUrl = environment.yardAPIUrl + "/roles/users?branchId=" + this.configService.config.branchId + "&rolename=" + roleName;
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let users = await this.http.get<User[]>(requestUrl, { headers: headers }).toPromise();
      return users;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async assignPickerToPickingList(pickingListId: string, userId: string) {
    let requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/assign-picker";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId
    };

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async assignCheckerToPickingList(pickingListId: string, userId: string) {
    let requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/assign-checker";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId
    };

    try {
      await this.http.post(requestUrl, body, { headers: headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async unlockReconditioningOrderById(reconOrderId: string) {
    let requestUrl = environment.yardAPIUrl + "/reconditioning-orders/" + reconOrderId + "/unlock";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await this.http.post(requestUrl, {}, { headers }).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getIsoCountryCodeByBranchId(branchId: string): Promise<string> {
    const requestUrl = environment.yardAPIUrl + "/branches/" + branchId + "/isoCountryCode";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let isoCountryCode = await this.http.get<string>(requestUrl, { headers: headers, responseType: 'text' as 'json' }).toPromise();
      return isoCountryCode;
    } catch (err) {
      console.error(err);
      return "en";
    }
  }

  async getReturnDeliveriesByCurrentBranch(mobileReturnDeliveryStatuses?: MobileReturnDeliveryStatus[], includeArticleNames?: boolean) {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let isoCountryCode = await this.getIsoCountryCodeByBranchId(this.configService.config.branchId);
    let requestUrl = environment.yardAPIUrl + "/return-deliveries";
    var auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const requestBody = {
      parameters: {
        pagination: {
          includeTotalCount: true,
          top: 1000,
          skip: 0
        },
        filtering: {
          branchId: this.configService.config.branchId,
          mobileReturnDeliveryStatuses: mobileReturnDeliveryStatuses ?
            mobileReturnDeliveryStatuses :
            [
              "Released",
              "Unloading",
              "FirstSight",
              "FirstSightFinished",
              "Processing",
              "ProcessingPartlyDone",
              "ProcessingFinished",
              "Finishing"
            ]
        }
      },
      includeLineItems: true,
      isoLanguageCode: isoLanguageCode,
      isoCountryCode: isoCountryCode,
      includeArticleNames: includeArticleNames ? includeArticleNames : false
    };

    try {
      let loadedRedeliveries: number = 0;
      let allRedeliveries: Redelivery[] = [];
      do {
        requestBody.parameters.pagination.skip = allRedeliveries.length;
        let redeliveries = await this.http.post<Redelivery[]>(requestUrl, requestBody, { headers }).toPromise();
        loadedRedeliveries = redeliveries.length;
        allRedeliveries.push(...redeliveries);
      } while (loadedRedeliveries === 1000)
      return allRedeliveries;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getRedeliveryById(redeliveryId: string, includeLineItems: boolean = true): Promise<Redelivery> {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let isoCountryCode = await this.getIsoCountryCodeByBranchId(this.configService.config.branchId);
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "?isoLanguageCode=" + isoLanguageCode + "&isoCountryCode=" + isoCountryCode + "&includeLineItems=" + includeLineItems;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let redelivery = await this.http.get<Redelivery>(requestUrl, { headers }).toPromise();
      for (let lineItem of redelivery.lineItems) {
        lineItem.quantitiesIncrease = {
          quantityOk: 0,
          quantityRepair: 0,
          quantityFinishedReconditioning: 0,
          quantityDamaged: 0,
          quantityWear: 0,
          quantityOwnConsumption: 0,
          quantityCustomerCount: 0,
          quantityFirstSight: 0
        };
        lineItem.article.articleName = this.generateArticleName(lineItem.article.articleName, lineItem.article.isSerialNumberRequired, lineItem.article.isSizeRequired, lineItem.serialNumberIdentifier, { length: lineItem.length, width: lineItem.width, depth: lineItem.depth }, lineItem.batchIdentifier);
        for (let sparepart of lineItem.spareparts) {
          sparepart.quantityCustomerIncrease = 0;
          sparepart.quantityDokaIncrease = 0;
        }
      }
      return redelivery;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async startRedeliveryProcessing(redeliveryId: string, userId?: string): Promise<StartRedeliveryResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/start-processing";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    const body = {
      userId: userId ? userId : this.authenticationService.userId
    };
    try {
      let response = await lastValueFrom(this.http.post<StartRedeliveryResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      throw err;
    }
  }

  async startRedeliveryFirstSight(redeliveryId: string, userId?: string): Promise<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, firstSightUser: RedeliveryUser }> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/start-firstsight";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      userId: userId ? userId : this.authenticationService.userId
    };

    try {
      let startFirstSightResult = await lastValueFrom(this.http.post<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, firstSightUser: RedeliveryUser }>(requestUrl, body, { headers }));
      let user = await this.getUserById(startFirstSightResult.firstSightUser.userId);
      startFirstSightResult.firstSightUser.identityUser = user;
      return startFirstSightResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async completeRedeliveryProcessing(redeliveryId: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/complete-processing";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    const body = {
      userId: this.authenticationService.userId
    };
    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      throw err;
    }
  }

  async appendLineItemRemarks(lineItem: LineItem, newAdditionalText: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItem.id + "/append-remarks";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);
    console.log(headers);
    let body = {
      remarks: newAdditionalText,
      userId: this.authenticationService.userId
    }

    try {
      let newRemarks = await lastValueFrom(this.http.post<{ remarks: string }>(requestUrl, body, { headers }));
      return newRemarks;
    } catch (err) {
      throw err;
    }
  }

  async getLineItemById(redeliveryId: string, lineItemId: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/line-items/" + lineItemId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let lineItem = await lastValueFrom(this.http.get<LineItem>(requestUrl, { headers }));
      return lineItem;
    } catch (err) {
      throw err;
    }
  }

  async updateReturnDeliveryLineItem(lineItemId: string, parameters: UpdateLineItemParameters) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/update";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);
    let body = {
      deltaQuantities: {
        quantityOk: parameters.quantityOk,
        quantityRepair: parameters.quantityRepair,
        quantityFinishedReconditioning: parameters.quantityFinishedReconditioning,
        quantityDamaged: parameters.quantityDamaged,
        quantityWear: parameters.quantityWear,
        quantityOwnConsumption: parameters.quantityOwnConsumption,
        quantityExternalRepair: 0,
        quantityOverDelivery: 0,
        quantityCleaningRequired: 0,
        quantityFrameRepair: 0,
        quantityPlywoodRepair: 0
      },
      userId: this.authenticationService.userId
    }

    try {
      let updateLineItemResponse = await lastValueFrom(this.http.post<UpdateLineItemResponse>(requestUrl, body, { headers }));
      return updateLineItemResponse;
    } catch (err) {
      throw err;
    }
  }

  async addReturnDeliveryLineItem(returnDeliveryId: string, addLineItemParameters: AddLineItemParameters) {
    let isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    let isoCountryCode = await this.getIsoCountryCodeByBranchId(this.configService.config.branchId);
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + returnDeliveryId + "/add-line-item";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    addLineItemParameters.userId = this.authenticationService.userId;

    let body = {
      parameters: addLineItemParameters,
      isoLanguageCode: isoLanguageCode,
      isoCountryCode: isoCountryCode
    };

    try {
      let addedLineItem = await lastValueFrom(this.http.post<LineItem>(requestUrl, body, { headers }));
      return addedLineItem;
    } catch (err) {
      throw err;
    }
  }

  async addReturnDeliverySparepart(lineItemId: string, sparepart: Sparepart) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/add-spare-part";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);

    let body = {
      articleId: sparepart.article.id,
      quantityCustomer: sparepart.quantityCustomerIncrease,
      quantityDoka: sparepart.quantityDokaIncrease,
      isIncluded: sparepart.isIncluded,
      materialCondition: sparepart.materialCondition,
      userId: this.authenticationService.userId,
      serialNumberIdentifier: sparepart.serialNumberIdentifier,
      reason: sparepart.reason
    };

    try {
      let addSparepartResponse = await lastValueFrom(this.http.post<Sparepart>(requestUrl, body, { headers }));
      return addSparepartResponse;
    } catch (err) {
      throw err;
    }
  }

  async updateReturnDeliverySparepart(lineItemId: string, sparepart: Sparepart) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/update-spare-part";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);

    let body = {
      returnDeliveryLineItemSparePartId: sparepart.id,
      deltaQuantityDoka: sparepart.quantityDokaIncrease,
      deltaQuantityCustomer: sparepart.quantityCustomerIncrease,
      isIncluded: sparepart.isIncluded,
      userId: this.authenticationService.userId,
      reason: sparepart.reason
    };

    try {
      let updateSparepartResponse = await lastValueFrom(this.http.post<Sparepart>(requestUrl, body, { headers }));
      return updateSparepartResponse;
    } catch (err) {
      throw err;
    }
  }

  async completeLineItemProcessing(lineItemId: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/complete-processing";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);

    let body = {
      userId: this.authenticationService.userId
    };

    try {
      let completeLineItemProcessingResponse = await lastValueFrom(this.http.post<{ mobileReturnDeliveryStatus: MobileReturnDeliveryLineItemStatus }>(requestUrl, body, { headers }));
      return completeLineItemProcessingResponse;
    } catch (err) {
      throw err;
    }
  }

  async removeReturnDeliverySparepart(lineItemId: string, sparepartId: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/remove-spare-part";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);

    let body = {
      returnDeliveryLineItemSparePartId: sparepartId,
      userId: this.authenticationService.userId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  async startReturnDeliveryFinishing(redeliveryId: string, userId?: string): Promise<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, finishingUser: RedeliveryUser }> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/start-finishing";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      userId: userId ? userId : this.authenticationService.userId
    };

    try {
      let startFinishingResult = await lastValueFrom(this.http.post<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, finishingUser: RedeliveryUser }>(requestUrl, body, { headers }));
      return startFinishingResult;
    } catch (err) {
      throw err;
    }
  }

  async completeReturnDeliveryFinishing(redeliveryId: string): Promise<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, finishingUser: RedeliveryUser }> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/complete-finishing";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      userId: this.authenticationService.userId
    };

    try {
      let completeFinishingResult = await lastValueFrom(this.http.post<{ mobileReturnDeliveryStatus: MobileReturnDeliveryStatus, finishingUser: RedeliveryUser }>(requestUrl, body, { headers }));
      return completeFinishingResult;
    } catch (err) {
      throw err;
    }
  }

  async reopenLineItem(lineItemId: string): Promise<{ mobileReturnDeliveryStatus: MobileReturnDeliveryLineItemStatus }> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/reopen";
    const auth_token = this.oAuthService.getAccessToken();
    let headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    this.appendReturnDeliveryHeader(headers);

    const body = {
      userId: this.authenticationService.userId
    };

    try {
      let reopenLineItemResult = await lastValueFrom(this.http.post<{ mobileReturnDeliveryStatus: MobileReturnDeliveryLineItemStatus }>(requestUrl, body, { headers }));
      return reopenLineItemResult;
    } catch (err) {
      console.error(err);
    }
  }

  async getShippingBoardTransportOrders(): Promise<ShippingBoardResponse[]> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    const requestUrl = environment.yardAPIUrl + "/shipping-board-transport-orders?branchId=" + this.configService.config.branchId + "&isoLanguageCode=" + isoLanguageCode;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let shippingBoardTransportOrders = await lastValueFrom(this.http.get<ShippingBoardResponse[]>(requestUrl, { headers }));
      return shippingBoardTransportOrders;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getShippingBoardReturnDeliveries(): Promise<ShippingBoardReturnDeliveryResponse[]> {
    const isoLanguageCode = await this.databaseService.getIsoLanguageCode();
    const requestUrl = environment.yardAPIUrl + "/shipping-board-return-deliveries?branchId=" + this.configService.config.branchId + "&isoLanguageCode=" + isoLanguageCode;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let shippingBoardReturnDeliveries = await lastValueFrom(this.http.get<ShippingBoardReturnDeliveryResponse[]>(requestUrl, { headers }));
      return shippingBoardReturnDeliveries;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getReconODLDataByCurrentBranch() {
    const requestUrl = environment.odlDbUrl + "odl/reconditioning/items?branchIdentifier=" + this.configService.config.clientid;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let odlData = await lastValueFrom(this.http.get<ODLData[]>(requestUrl, { headers }));
      return odlData;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getAllPermissions(): Promise<Permission[]> {
    const requestUrl = environment.yardAPIUrl + "/permissions";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let permissions: Permission[] = await lastValueFrom(this.http.get<Permission[]>(requestUrl, { headers }));
      return permissions;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getPermissionByRoleId(roleId: string): Promise<Permission[]> {
    const requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/permissions";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let permissions: Permission[] = await lastValueFrom(this.http.get<Permission[]>(requestUrl, { headers }));
      return permissions;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addPermissionToRole(roleId: string, permissionId: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/permissions";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      permissionId: permissionId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removePermissionFromRole(roleId: string, permissionId: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/roles/" + roleId + "/permissions";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      permissionId: permissionId
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers: headers, body: body }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private generateArticleName(originalArticleName: string, isSerialNumberRequired: boolean, isSizeRequired: boolean, serialNumberIdentifier: string, dimensions: { length: number, width: number, depth: number }, batchIdentifier: string) {
    if (isSizeRequired) {
      let articleName = "";
      let croppedArticlenames = originalArticleName.split("/");
      for (let croppedArticlename of croppedArticlenames) {
        if (croppedArticlenames[0] === croppedArticlename) {
          articleName = croppedArticlename.replace("$$$$$", dimensions.depth.toString());
        } else {
          articleName += "/" + croppedArticlename.replace("$$$$$", dimensions.width.toString());
        }
      }
      return articleName;
    } else if (isSerialNumberRequired) {
      return originalArticleName + " (" + serialNumberIdentifier + ")";
    } else if (batchIdentifier !== null && batchIdentifier !== "") {
      return originalArticleName + " (" + batchIdentifier + ")";
    } else {
      return originalArticleName;
    }
  }

  public async getAvailableLanguages(): Promise<string[]> {
    let requestUrl = environment.yardAPIUrl + "/translations/" + environment.identityServerClientId;
    const headers = {
      "accept-version": "1.0"
    };

    try {
      let langs = await lastValueFrom(this.http.get<string[]>(requestUrl, { headers }));
      return langs;
    } catch (err) {
      console.warn("Could not get available Translations", err);
      return [];
    }
  }

  public async getReturnStatusAverageTimes(startDateTime?: string, endDateTime?: string): Promise<ReturnStatusAverageTimesModel> {
    let requestUrl = environment.yardAPIUrl + "/odl/return-processing-times/average?branchId=" + this.configService.config.branchId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    if (startDateTime) {
      requestUrl += "&startDateTime=" + startDateTime;
    }
    if (endDateTime) {
      requestUrl += "&endDateTime=" + endDateTime;
    }

    try {
      let returnStatusAverageTimesResponse = await lastValueFrom(this.http.get<ReturnStatusAverageTimesResponseModel>(requestUrl, { headers }));
      let returnStatusAverageTimes: ReturnStatusAverageTimesModel = {
        totalAverageTime: TimeSpan.parse(returnStatusAverageTimesResponse.totalAverageTime),
        unloadingAverageTime: TimeSpan.parse(returnStatusAverageTimesResponse.unloadingAverageTime),
        firstSightAverageTime: TimeSpan.parse(returnStatusAverageTimesResponse.firstSightAverageTime),
        editingAverageTime: TimeSpan.parse(returnStatusAverageTimesResponse.editingAverageTime),
        finishingAverageTime: TimeSpan.parse(returnStatusAverageTimesResponse.finishingAverageTime)
      };
      return returnStatusAverageTimes;
    } catch (err) {
      throw err;
    }
  }

  public async getReturnLineItemAverageTimes(startDateTime?: string, endDateTime?: string): Promise<ReturnLineItemProcessingTimeModel[]> {
    const isoLanguageCode = this.databaseService.getIsoLanguageCode();
    let requestUrl = environment.yardAPIUrl + "/odl/return-line-item-processing-times?branchId=" + this.configService.config.branchId + "&isoLanguageCode=" + isoLanguageCode;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };
    if (startDateTime) {
      requestUrl += "&startDateTime=" + startDateTime;
    }
    if (endDateTime) {
      requestUrl += "&endDateTime=" + endDateTime;
    }

    try {
      let returnLineItemProcessingTimesResponse = await lastValueFrom(this.http.get<IReturnLineItemProcessingTimeResponseModel[]>(requestUrl, { headers }));
      let returnLineItemProcessingTimes: ReturnLineItemProcessingTimeModel[] = [];
      for (let returnLineItemProcessingTime of returnLineItemProcessingTimesResponse) {
        returnLineItemProcessingTimes.push(new ReturnLineItemProcessingTimeModel(
          returnLineItemProcessingTime.articleId,
          returnLineItemProcessingTime.articleIdentifier,
          returnLineItemProcessingTime.articleName,
          returnLineItemProcessingTime.averageTime,
          returnLineItemProcessingTime.totalTime,
          returnLineItemProcessingTime.okQuantity,
          returnLineItemProcessingTime.reconQuantity,
          returnLineItemProcessingTime.reconOkQuantity,
          returnLineItemProcessingTime.defectQuantity,
          returnLineItemProcessingTime.itemGroup
        ));
      }
      return returnLineItemProcessingTimes;
    } catch (err) {
      throw err;
    }
  }

  public async overrideCompletePickingListById(pickingListId: string) {
    const requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/override-complete";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, null, { headers }));
      return true;
    } catch (err) {
      throw err;
    }
  }

  public async overrideCompleteTransportOrderById(transportOrderId: string) {
    const requestUrl = environment.yardAPIUrl + "/transport-orders/" + transportOrderId + "/override-complete";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, null, { headers }));
      return true;
    } catch (err) {
      throw err;
    }
  }

  public async getAverageTimesByArticleIds(articleIds: string[]): Promise<IAverageODLLineItemResponse[]> {
    const requestUrl = environment.yardAPIUrl + "/odl/return-line-item-processing-times-by-articleids?branchId=" + this.configService.config.branchId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let averageODLLineItemResponse = await lastValueFrom(this.http.post<IAverageODLLineItemResponse[]>(requestUrl, articleIds, { headers }));
      return averageODLLineItemResponse;
    } catch (err) {
      throw err;
    }
  }

  public async appendReturnDeliveryRemark(returnDeliveryId: string, remark: string) {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + returnDeliveryId + "/append-remarks";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      remarks: remark,
      userId: this.authenticationService.userId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
    } catch (err) {
      throw err;
    }
  }

  public async appendDeliveryPickingListRemark(pickingListId: string, remark: string): Promise<string> {
    const requestUrl = environment.yardAPIUrl + "/picking-lists/" + pickingListId + "/append-remarks";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      additionalRemarks: remark
    };

    try {
      let remarksResult = await lastValueFrom(this.http.post<string>(requestUrl, body, { headers }));
      return remarksResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateLineItemQuantityCustomerCount(lineItemId: string, deltaQuantityCustomerCount: number): Promise<UpdateLineItemQuantityCustomerCountResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/update-quantity-customercount";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      userId: this.authenticationService.userId,
      deltaQuantityCustomerCount: deltaQuantityCustomerCount
    };

    try {
      let updateQuantityCustomerCountResult = await lastValueFrom(this.http.post<UpdateLineItemQuantityCustomerCountResponse>(requestUrl, body, { headers }));
      return updateQuantityCustomerCountResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateLineItemQuantityFirstSight(lineItemId: string, deltaFirstSight: number): Promise<UpdateLineItemQuantityFirstSightResult> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItemId + "/update-quantity-first-sight";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      userId: this.authenticationService.userId,
      deltaQuantityFirstSight: deltaFirstSight
    };

    try {
      let updateQuantityFirstSightResult = await lastValueFrom(this.http.post<UpdateLineItemQuantityFirstSightResult>(requestUrl, body, { headers }));
      return updateQuantityFirstSightResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getStockByProjectId(projectId: string, top: number, skip: number): Promise<YardStock[]> {
    const requestUrl = environment.yardAPIUrl + "/projects/" + projectId + "/stock?isoLanguageCode=" + this.databaseService.getIsoLanguageCode() + "&isoCountryCode=" + this.getIsoCountryCodeByBranchId(this.configService.config.branchId) + "&owner=Doka&pointOfView=Doka&branchId=" + this.configService.config.branchId + "&$top=" + top + "&$skip=" + skip;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let stocks = await lastValueFrom(this.http.get<YardStock[]>(requestUrl, { headers }));
      for (let stock of stocks) {
        stock.articleName = this.generateArticleName(stock.articleName ?? "", stock.localizedArticle.isSerialNumberRequired, stock.localizedArticle.isWidthRequired || stock.localizedArticle.isLengthRequired, stock.serialNumberIdentifier, { length: stock.length, width: stock.width, depth: stock.depth }, stock.batchIdentifier);
      }
      return stocks;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getStockByConstructionSiteId(constructionSiteId: string, top: number, skip: number): Promise<YardStock[]> {
    const requestUrl = environment.yardAPIUrl + "/construction-sites/" + constructionSiteId + "/stock?isoLanguageCode=" + this.databaseService.getIsoLanguageCode() + "&isoCountryCode=" + this.getIsoCountryCodeByBranchId(this.configService.config.branchId) + "&$top=" + top + "&$skip=" + skip;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let stocks = await lastValueFrom(this.http.get<YardStock[]>(requestUrl, { headers }));
      for (let stock of stocks) {
        stock.articleName = this.generateArticleName(stock.articleName ?? "", stock.localizedArticle.isSerialNumberRequired, stock.localizedArticle.isWidthRequired || stock.localizedArticle.isLengthRequired, stock.serialNumberIdentifier, { length: stock.length, width: stock.width, depth: stock.depth }, stock.batchIdentifier);
      }
      return stocks;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getInboundOrdersByCurrentBranch(top: number, skip: number, transportOrderIdentifier?: string): Promise<InboundOrder[]> {
    const requestUrl = environment.yardAPIUrl + "/inbound/get-details";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const body = {
      branchIdentifier: this.configService.config.clientid,
      inboundStatuses: ["ReadyForInboundCheck"],
      top: top,
      skip: skip,
      isoLanguageCode: this.databaseService.getIsoLanguageCode(),
      transportOrderIdentifier: transportOrderIdentifier ?? undefined
    };

    try {
      let inboundOrders = await lastValueFrom(this.http.post<InboundOrder[]>(requestUrl, body, { headers }));
      return inboundOrders;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getInboundOrderById(inboundOrderId: string): Promise<InboundOrder> {
    const requestUrl = environment.yardAPIUrl + "/inbound/" + inboundOrderId + "?isoLanguageCode=" + this.databaseService.getIsoLanguageCode();
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    try {
      let inboundOrder = await lastValueFrom(this.http.get<InboundOrder>(requestUrl, { headers }));
      return inboundOrder;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async appendInboundRemark(inboundOrderId: string, remark: string): Promise<string> {
    const requestUrl = environment.yardAPIUrl + "/inbound/append-remarks";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: inboundOrderId,
      remarks: remark
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, requestBody, { headers }));
      let inboundOrder = await this.getInboundOrderById(inboundOrderId);
      return inboundOrder.remarks;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async startInboundOrderCheck(inboundOrderId: string): Promise<StartInboundOrderCheckResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound/start-check";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: inboundOrderId,
      userId: this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<StartInboundOrderCheckResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelInboundOrderCheck(inboundOrderId: string): Promise<CancelInboundOrderCheckResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound/cancel-check";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: inboundOrderId
    };

    try {
      let response = await lastValueFrom(this.http.post<CancelInboundOrderCheckResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async completeInboundOrderCheck(inboundOrderId: string): Promise<CompleteInboundOrderCheck> {
    const requestUrl = environment.yardAPIUrl + "/inbound/complete-check";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: inboundOrderId,
      userId: this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<CompleteInboundOrderCheck>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelInboundOrderUnloading(inboundOrderId: string): Promise<CancelInboundOrderUnloadingResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound/cancel-unloading";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: inboundOrderId
    };

    try {
      let response = await lastValueFrom(this.http.post<CancelInboundOrderUnloadingResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async checkInboundOrderLineItem(inboundOrderLineItemId: string): Promise<CheckInboundOrderLineItemResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound-line-item/check";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderLineItemId: inboundOrderLineItemId,
      userId: this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<CheckInboundOrderLineItemResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async uncheckInboundOrderLineItem(inboundOrderLineItemId: string): Promise<CheckInboundOrderLineItemResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound-line-item/uncheck";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderLineItemId: inboundOrderLineItemId,
      userId: this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<CheckInboundOrderLineItemResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateInboundOrderLineItem(updateLineItemParameters: UpdateInboundOrderLineItemParameters): Promise<UpdateInboundOrderLineItemResponse> {
    const requestUrl = environment.yardAPIUrl + "/inbound-line-item/change-quantities";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      lineItemId: updateLineItemParameters.lineItemId,
      userId: this.authenticationService.userId,
      quantityOkNew: updateLineItemParameters.quantityOkNew,
      quantityOkUsed: updateLineItemParameters.quantityOkUsed,
      quantityDefect: updateLineItemParameters.quantityDefect,
      quantityRepair: updateLineItemParameters.quantityRepair
    };

    try {
      let response = await lastValueFrom(this.http.post<UpdateInboundOrderLineItemResponse>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addInboundOrderLineItem(addLineItemParameters: AddInboundOrderLineItemParameters): Promise<InboundOrderLineItem> {
    const requestUrl = environment.yardAPIUrl + "/inbound/add-line-item?isoLanguageCode=" + this.databaseService.getIsoLanguageCode();
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      inboundOrderId: addLineItemParameters.inboundOrderId,
      articleIdentifier: addLineItemParameters.articleIdentifier,
      userId: this.authenticationService.userId,
      serialNumberIdentifier: addLineItemParameters.serialNumberIdentifier,
      length: addLineItemParameters.length,
      width: addLineItemParameters.width,
      depth: addLineItemParameters.depth,
      materialCondition: addLineItemParameters.materialCondition,
    };

    try {
      let response = await lastValueFrom(this.http.post<InboundOrderLineItem>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async appendLineItemRemark(lineItemId: string, remark: string): Promise<{ remarks: string }> {
    const requestUrl = environment.yardAPIUrl + "/inbound-line-item/append-remarks";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0"
    };

    const requestBody = {
      lineItemId: lineItemId,
      remarks: remark
    };

    try {
      let response = await lastValueFrom(this.http.post<{ remarks: string }>(requestUrl, requestBody, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelReturnDeliveryLineItem(lineItem: LineItem, redeliveryId: string): Promise<CancelLineItemResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries-line-items/" + lineItem.id + "/cancel";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: this.authenticationService.userId
    };

    try {
      let dateString = new Date().toISOString();
      await this.addReturnLineItemFirstSightTime({
        branchId: this.configService.config.branchId,
        returnDeliveryId: redeliveryId,
        returnDeliveryLineItemId: lineItem.id,
        itemId: lineItem.article.id,
        startDateTime: dateString,
        endDateTime: dateString,
        firstSightCount: lineItem.quantities.quantityFirstSight * -1,
        customerCount: lineItem.quantities.quantityCustomerCount * -1,
        firstSightSeen: lineItem.firstSightSeen,
        aiCountPossible: false,
        aiCountUsed: false,
        aiCountQuantity: 0
      });
      await this.addReturnLineItemProcessingTime({
        branchId: this.configService.config.branchId,
        returnDeliveryId: redeliveryId,
        returnDeliveryLineItemId: lineItem.id,
        itemId: lineItem.article.id,
        startDateTime: dateString,
        endDateTime: dateString,
        okQuantity: lineItem.quantities.quantityOk * -1,
        reconQuantity: lineItem.quantities.quantityRepair * -1,
        reconOkQuantity: lineItem.quantities.quantityFinishedReconditioning * -1,
        defectQuantity: lineItem.quantities.quantityDamaged * -1,
        wearQuantity: lineItem.quantities.quantityWear * -1,
        userCount: 0,
        didCancel: true,
        returnLineItemProcessingTimeSpareParts: [],
        returnLineItemProcessingTimeType: ReturnLineItemProcessingTimeType.Editing
      });
    } catch (err) {
      console.warn(err);
    }

    try {
      let response = await lastValueFrom(this.http.post<CancelLineItemResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelReturnDeliveryFinishing(redeliveryId: string, userId?: string): Promise<CancelFinishingResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/cancel-finishing";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId ? userId : this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<CancelFinishingResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelProcessingFinished(redeliveryId: string): Promise<CancelFinishingResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/cancel-processing-finished";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: this.authenticationService.userId
    };

    try {
      let response = await lastValueFrom(this.http.post<CancelFinishingResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addReturnStatusProcessingTime(returnStatusProcessingTime: ReturnStatusProcessingTime) {
    const requestUrl = environment.yardAPIUrl + "/odl/return-processing-times";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, returnStatusProcessingTime, { headers }));
    } catch (err) {
      console.warn(err);
    }
  }

  public async cancelReturnDeliveryFirstSight(redeliveryId: string, userId: string, resetData: boolean): Promise<CancelFirstSightResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/cancel-firstsight";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId,
      resetData: resetData
    };

    try {
      let response = await lastValueFrom(this.http.post<CancelFirstSightResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removeReturnDeliveryProcessor(redeliveryId: string, userId: string): Promise<RemoveProcessorResponse> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + redeliveryId + "/remove-processing-user";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId
    };

    try {
      let response = await lastValueFrom(this.http.post<RemoveProcessorResponse>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getYardRolesByCurrentBranchId(): Promise<YardRolesResponse> {
    const isoLanguageCode = this.databaseService.getIsoLanguageCode();
    const requestUrl = environment.yardAPIUrl + "/yard-roles/users?branchId=" + this.configService.config.branchId + "&isoLanguageCode=" + isoLanguageCode;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let response = await lastValueFrom(this.http.get<YardRolesResponse>(requestUrl, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getYardRoles(includeBranchRoles?: boolean, includeCountryRoles?: boolean, includeAreaRoles?: boolean): Promise<YardRole[]> {
    let requestUrl = environment.yardAPIUrl + "/yard-roles";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    let isFirstQueryParameter = true;

    if (includeBranchRoles !== undefined) {
      requestUrl += (isFirstQueryParameter ? "?" : "&") + "includeBranchRoles=" + includeBranchRoles;
      isFirstQueryParameter = false;
    }
    if (includeCountryRoles !== undefined) {
      requestUrl += (isFirstQueryParameter ? "?" : "&") + "includeCountryRoles=" + includeCountryRoles;
      isFirstQueryParameter = false;
    }
    if (includeAreaRoles !== undefined) {
      requestUrl += (isFirstQueryParameter ? "?" : "&") + "includeAreaRoles=" + includeAreaRoles;
      isFirstQueryParameter = false;
    }

    try {
      let response = await lastValueFrom(this.http.get<YardRole[]>(requestUrl, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addYardRoleAreaUserRelation(yardRoleId: string, areaId: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/add-area-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      areaId: areaId,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async deleteYardRoleAreaUserRelation(yardRoleId: string, areaId: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/delete-area-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      areaId: areaId,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers: headers, body: body }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addYardRoleCountryUserRelation(yardRoleId: string, isoCountryCode: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/add-country-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      isoCountryCode: isoCountryCode,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async deleteYardRoleCountryUserRelation(yardRoleId: string, isoCountryCode: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/delete-country-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      isoCountryCode: isoCountryCode,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers: headers, body: body }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addYardRoleBranchUserRelation(yardRoleId: string, branchId: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/add-branch-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      branchId: branchId,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async deleteYardRoleBranchUserRelation(yardRoleId: string, branchId: string, userId: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/delete-branch-user-relation";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      yardRoleId: yardRoleId,
      branchId: branchId,
      userId: userId
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers: headers, body: body }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addYardRole(name: string, isAreaRole: boolean, isCountryRole: boolean, isBranchRole: boolean): Promise<YardRole> {
    const requestUrl = environment.yardAPIUrl + "/yard-roles";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      name: name,
      isAreaRole: isAreaRole,
      isCountryRole: isCountryRole,
      isBranchRole: isBranchRole
    };

    try {
      let response = await lastValueFrom(this.http.post<YardRole>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateYardRole(id: string, isAreaRole: boolean, isCountryRole: boolean, isBranchRole: boolean): Promise<YardRole> {
    const requestUrl = environment.yardAPIUrl + "/yard-roles";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      id: id,
      isAreaRole: isAreaRole,
      isCountryRole: isCountryRole,
      isBranchRole: isBranchRole
    };

    try {
      let response = await lastValueFrom(this.http.patch<YardRole>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async deleteYardRole(id: string) {
    const requestUrl = environment.yardAPIUrl + "/yard-roles/" + id;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getBuildingsByCurrentBranch(): Promise<Building[]> {
    const requestUrl = environment.yardAPIUrl + "/buildings/" + this.configService.config.branchId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let response = await lastValueFrom(this.http.get<Building[]>(requestUrl, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addBuildingToCurrentBranch(buildingName: string, iconName: string): Promise<Building> {
    const requestUrl = environment.yardAPIUrl + "/buildings";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      branchId: this.configService.config.branchId,
      buildingName: buildingName,
      iconName: iconName
    };

    try {
      var response = await lastValueFrom(this.http.post<Building>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async changeBuildingIcon(buildingId: string, iconName: string): Promise<Building> {
    const requestUrl = environment.yardAPIUrl + "/buildings/" + buildingId + "/change-icon";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      iconName: iconName
    };

    try {
      let response = await lastValueFrom(this.http.patch<Building>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removeBuilding(buildingId: string) {
    const requestUrl = environment.yardAPIUrl + "/buildings/" + buildingId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getWorkplacesByBuildingId(buildingId: string): Promise<Workplace[]> {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + buildingId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let response = await lastValueFrom(this.http.get<Workplace[]>(requestUrl, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addWorkplaceToBuilding(buildingId: string, workplaceName: string): Promise<Workplace> {
    const requestUrl = environment.yardAPIUrl + "/workplaces/";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      buildingId: buildingId,
      name: workplaceName
    };

    try {
      let response = await lastValueFrom(this.http.post<Workplace>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removeWorkplace(workplaceId: string) {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + workplaceId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addEquipmentToWorkplace(workplaceId: string, equipmentName: string, activeSince: string): Promise<Workplace> {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + workplaceId + "/add-equipment";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      name: equipmentName,
      activeSince: activeSince
    };

    try {
      let response = await lastValueFrom(this.http.post<Workplace>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removeEquipmentFromWorkplace(workplaceId: string, equipmentId: string) {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + workplaceId + "/remove-equipment/" + equipmentId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers }));
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateAssignedArticlesToWorkplace(workplaceId: string, articleIds: string[]): Promise<Workplace> {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + workplaceId + "/assignedArticles/";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      assignedArticles: articleIds
    };

    try {
      let response = await lastValueFrom(this.http.post<Workplace>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateWorkplaceRelevantApplications(workplaceId: string, relevantApplicationIds: string[]): Promise<Workplace> {
    const requestUrl = environment.yardAPIUrl + "/workplaces/" + workplaceId + "/relevantApplications";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      relevantApplications: relevantApplicationIds
    };

    try {
      let response = await lastValueFrom(this.http.post<Workplace>(requestUrl, body, { headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getReturnDeliveryHistoryByReturnDeliveryId(returnDeliveryId: string): Promise<ReturnDeliveryHistory[]> {
    const requestUrl = environment.yardAPIUrl + "/return-deliveries/" + returnDeliveryId + "/history";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      let response = await lastValueFrom(this.http.get<ReturnDeliveryHistory[]>(requestUrl, { headers: headers }));
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addReturnLineItemFirstSightTime(returnLineItemFirstSightTime: AddReturnLineItemFirstSightTime) {
    const requestUrl = environment.yardAPIUrl + "/odl/return-line-item-first-sight-times";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, returnLineItemFirstSightTime, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addReturnLineItemProcessingTime(returnLineItemProcessingTime: AddReturnLineItemProcessingTime) {
    const requestUrl = environment.yardAPIUrl + "/odl/return-line-item-processing-times";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, returnLineItemProcessingTime, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getActiveUsersByCurrentBranch(): Promise<YardDashboardBuilding[]> {
    const requestUrl = environment.yardAPIUrl + "/branches/" + this.configService.config.branchId + "/activeUsers";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      return await lastValueFrom(this.http.get<YardDashboardBuilding[]>(requestUrl, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getAbsencesByCurrentBranch(): Promise<Absence[]> {
    const requestUrl = environment.yardAPIUrl + "/branches/" + this.configService.config.branchId + "/absences";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      return await lastValueFrom(this.http.get<Absence[]>(requestUrl, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async addAbsence(userId: string, startDate: string, endDate: string, absenceType: AbsenceType, notes: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/absences";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId,
      startDate: startDate,
      endDate: endDate,
      absenceType: absenceType,
      notes: notes
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateAbsence(absenceId: string, startDate: string, endDate: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/absences/" + absenceId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      startDate: startDate,
      endDate: endDate
    };

    try {
      await lastValueFrom(this.http.patch(requestUrl, body, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async deleteAbsence(absenceId: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/absences/" + absenceId;
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async setUserOperativeRelevant(userId: string, branchId?: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/users/add-operative-relevant-user";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId,
      branchId: branchId ? branchId : this.configService.config.branchId
    };

    try {
      await lastValueFrom(this.http.post(requestUrl, body, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async removeUserOperativeRelevant(userId: string, branchId?: string): Promise<void> {
    const requestUrl = environment.yardAPIUrl + "/users/remove-operative-relevant-user";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    const body = {
      userId: userId,
      branchId: branchId ? branchId : this.configService.config.branchId
    };

    try {
      await lastValueFrom(this.http.delete(requestUrl, { headers: headers, body: body }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getOperativeRelevantBranchesByUserId(userId: string): Promise<DetailedGroupAPIBranch[]> {
    const requestUrl = environment.yardAPIUrl + "/users/" + userId + "/operative-relevant-branches";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      return await lastValueFrom(this.http.get<DetailedGroupAPIBranch[]>(requestUrl, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getOperativeRelevantUsersByBranchId(branchId: string): Promise<User[]> {
    const requestUrl = environment.yardAPIUrl + "/branches/" + branchId + "/operative-relevant-users";
    const auth_token = this.oAuthService.getAccessToken();
    const headers = {
      Authorization: `Bearer ${auth_token}`,
      "accept-version": "1.0",
    };

    try {
      return await lastValueFrom(this.http.get<User[]>(requestUrl, { headers }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

}
