<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <doka-icon
          type="filled"
          slot="icon-only"
          name="close"
          size="2xl"
        ></doka-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button
        [disabled]="selectedToken == null"
        (click)="loginWithSelectedToken()"
        ><doka-icon
          type="filled"
          slot="start"
          name="check"
          size="2xl"
        ></doka-icon
        >{{ "OK" | i18nextEager }}</ion-button
      >
    </ion-buttons>
    <ion-title>{{ "SELECT_TOKEN" | i18nextEager }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="searchWrapper">
    <doka-search-field
      [value]="searchText"
      (valueChange)="searchText=$event; onSearch()"
    ></doka-search-field>
  </div>
  <ion-list lines="none" class="ion-padding" style="background: transparent">
    <ion-item *ngIf="isLoading">
      <doka-spinner></doka-spinner>
    </ion-item>
    <ion-item
      *ngFor="let currentToken of loadedTokens"
      (click)="selectToken(currentToken)"
      [color]="currentToken.id == selectedToken?.id ? 'primary' : 'light'"
      button
    >
      <doka-icon
        *ngIf="currentToken.id != selectedToken?.id"
        name="radio_button_unchecked"
        size="2xl"
        slot="start"
      ></doka-icon>
      <doka-icon
        *ngIf="currentToken.id == selectedToken?.id"
        name="check_circle"
        size="2xl"
        slot="start"
      ></doka-icon>
      <ion-label>
        <h5>
          <b
            >{{ "MANDANT" | i18nextEager }}: {{ currentToken.branchIdentifier
            }}</b
          >
        </h5>
        <h5>{{ currentToken.name }}, {{ currentToken.displayName }}</h5>
        <p *ngIf="adminService.userHasPermission('Administrator')">
          {{ "TOKEN" | i18nextEager }}: {{ currentToken.token }}
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-infinite-scroll
    *ngIf="!isLoading"
    (ionInfinite)="loadMoreTokens($event)"
  >
    <div class="infinite-scroll-content">
      <doka-spinner
        [ngClass]="{'notVisible': !isLoadingMore}"
        class="infiniteScrollSpinner"
      ></doka-spinner>
    </div>
  </ion-infinite-scroll>
</ion-content>
