import { Component, Input, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent, ModalController } from '@ionic/angular';
import { Role } from 'src/app/models/role';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-add-permission-request-role',
  templateUrl: './add-permission-request-role.component.html',
  styleUrls: ['./add-permission-request-role.component.scss'],
})
export class AddPermissionRequestRoleComponent implements OnInit {

  @Input("existingRoles") existingRoles: Role[];
  roles: Role[] = [];
  isLoadingRoles: boolean = false;
  isLoadingMoreRoles: boolean = false;
  loadedRolesCount: number = 0;

  constructor(
    private modalCtrl: ModalController,
    private webService: WebService
  ) { }

  ngOnInit() {
    this.loadRoles();
  }

  async loadRoles() {
    this.isLoadingRoles = true;
    this.loadedRolesCount = 0;
    this.roles = await this.webService.getRoles(100, 0);
    this.loadedRolesCount = this.roles.length;
    this.roles = this.roles.filter(r => this.existingRoles.findIndex(er => er.id === r.id) === -1);
    this.isLoadingRoles = false;
  }

  async loadMoreRoles(event: InfiniteScrollCustomEvent) {
    this.isLoadingMoreRoles = true;
    console.log(this.loadedRolesCount);
    let roles = await this.webService.getRoles(100, this.loadedRolesCount);
    this.loadedRolesCount += roles.length;
    this.roles.push(...roles);
    this.roles = this.roles.filter(r => this.existingRoles.findIndex(er => er.id === r.id) === -1);
    await event.target.complete();
    this.isLoadingMoreRoles = false;
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  selectRole(role: Role) {
    this.modalCtrl.dismiss({
      role: role
    });
  }

}
