import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, LoadingController, ModalController } from '@ionic/angular';
import { DokaI18NextService } from '@doka-shared/i18next';
import { environment } from 'src/environments/environment';
import { Return } from '../models/return';
import { AuthenticationService } from '../services/authentication.service';
import { ReturnService } from '../services/return.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-return-details',
  templateUrl: './return-details.component.html',
  styleUrls: ['./return-details.component.scss']
})
export class ReturnDetailsComponent implements OnInit {

  @ViewChild("arrivalDatePicker") arrivalDatePicker: IonDatetime;
  @ViewChild("departureDatePicker") departureDatePicker: IonDatetime;
  tempReturn: Return = new Return();
  arrivalDate: Date = null;
  departureDate: Date = null;
  isLoading: boolean = false;
  didChange: boolean = false;

  constructor(
    private returnService: ReturnService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private authenticationService: AuthenticationService,
    private alertCtrl: AlertController,
    private translate: DokaI18NextService,
    public adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.returnService.loadSingleReturn(this.returnService.currentReturn._id).then(returnItem => {
      Object.assign(this.tempReturn, returnItem);
      this.tempReturn.items = [];
      this.arrivalDate = this.tempReturn.pamdateofarrival_place ? (new Date(+this.tempReturn.pamdateofarrival_place.substring(6, 19))) : (new Date());
      this.departureDate = this.tempReturn.pamdateofdeparture_place ? (new Date(+this.tempReturn.pamdateofdeparture_place.substring(6, 19))) : (new Date());
      this.arrivalDatePicker.value = this.toIsoString(this.arrivalDate);
      this.departureDatePicker.value = this.toIsoString(this.departureDate);
      console.log(this.arrivalDatePicker.value);
      this.isLoading = false;
    });
  }

  dismissModal() {
    if (this.didChange) {
      this.alertCtrl.create({
        header: this.translate.instant("ARE_YOU_SURE"),
        message: this.translate.instant("FINISHING_WARNING"),
        buttons: [
          {
            text: this.translate.instant("DISCARD_CHANGES"),
            role: 'cancel',
            handler: () => {
              this.modalCtrl.dismiss();
            }
          },
          {
            text: this.translate.instant("SAVE"),
            handler: () => {
              this.saveReturn();
            }
          }
        ]
      }).then(alertEl => {
        alertEl.present();
      });
    } else {
      this.modalCtrl.dismiss();
    }
  }

  openYardcam(type: string, number: string) {
    window.open(environment.yardCamLink + '?voucherType=' + type + '&searchText=' + number, '_blank');
  }

  changeDate(type: string) {
    if (type === 'arrivalDate') {
      console.log(this.arrivalDatePicker.value);
      this.arrivalDate = new Date(this.arrivalDatePicker.value.toString());
      console.log(this.arrivalDatePicker.value);
    } else {
      this.departureDate = new Date(this.departureDatePicker.value.toString());
    }
    if (!this.isLoading) {
      this.didChange = true;
    }
  }

  saveReturn() {
    this.loadingCtrl.create({
      spinner: 'crescent'
    }).then(loadingEl => {
      loadingEl.present();
      this.returnService.loadSingleReturn(this.tempReturn._id).then((returnItem: Return) => {
        returnItem.unloaderemplid = this.tempReturn.unloaderemplid;
        returnItem.pamplacenumber = this.tempReturn.pamplacenumber;
        returnItem.carrieraccount = this.tempReturn.carrieraccount;
        returnItem.transunitcode = this.tempReturn.transunitcode;
        returnItem.pamdateofarrival_place = `/Date(${this.arrivalDate.getTime()})/`;
        returnItem.pamdateofdeparture_place = `/Date(${this.departureDate.getTime()})/`;
        let saveReturn: Return = new Return();
        Object.assign(saveReturn, returnItem);
        returnItem.items = [];
        if ((returnItem.pamstatus === 'None' || returnItem.pamstatus === 'PosFinished') && !this.isLocked(returnItem)) {
          this.returnService.saveReturn(saveReturn).then(() => {
            this.returnService.currentReturn.unloaderemplid = this.tempReturn.unloaderemplid;
            this.returnService.currentReturn.pamplacenumber = this.tempReturn.pamplacenumber;
            this.returnService.currentReturn.carrieraccount = this.tempReturn.carrieraccount;
            this.returnService.currentReturn.transunitcode = this.tempReturn.transunitcode;
            returnItem.pamdateofarrival_place = `/Date(${this.arrivalDate.getTime()})/`;
            returnItem.pamdateofdeparture_place = `/Date(${this.departureDate.getTime()})/`;
            loadingEl.dismiss();
            this.modalCtrl.dismiss();
          }).catch(error => {
            console.error(error);
            loadingEl.dismiss();
          });
        } else if (returnItem.pamstatus === 'QtyEstimatedFinished') {
          returnItem.pamstatus = 'PosFinished';
          this.returnService.exportReturn(returnItem).subscribe(() => {
            this.returnService.saveReturn(saveReturn).then(() => {
              this.returnService.currentReturn.unloaderemplid = this.tempReturn.unloaderemplid;
              this.returnService.currentReturn.pamplacenumber = this.tempReturn.pamplacenumber;
              this.returnService.currentReturn.carrieraccount = this.tempReturn.carrieraccount;
              this.returnService.currentReturn.transunitcode = this.tempReturn.transunitcode;
              returnItem.pamdateofarrival_place = `/Date(${this.arrivalDate.getTime()})/`;
              returnItem.pamdateofdeparture_place = `/Date(${this.departureDate.getTime()})/`;
              loadingEl.dismiss();
              this.modalCtrl.dismiss();
            }).catch(error => {
              console.error(error);
              loadingEl.dismiss();
            });
          }, error => {
            console.error(error);
            loadingEl.dismiss();
          })
        } else {
          loadingEl.dismiss();
          this.modalCtrl.dismiss();
        }
      });
    });
  }

  isLocked(returnItem: Return) {
    return !(!returnItem.locked || (returnItem.locked && returnItem.lockemail === this.authenticationService.useremail));
  }

  setDidChange() {
    console.log("SET DID CHANGE");
    if (!this.isLoading) {
      this.didChange = true;
    }
  }

  convertDate(dateTimeString) {
    return new Date(dateTimeString);
  }

  toIsoString(date: Date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

}
