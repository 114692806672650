import { Package } from './package';
import { ArticleAsset } from './articleAsset';
import { OrderDetails } from './orderDetails';

export class Article {
    id: string;
    articleIdentifier: string;
    type: string;
    lifeCycleCode: string;
    volume: number;
    weight: number;
    length: number;
    width: number;
    depth: number;
    isCeMarkingRequired: boolean;
    isInspectionRequired: boolean;
    isDangerousGood: boolean;
    isSerialNumberRequired: boolean;
    isLengthRequired: boolean;
    isWidthRequired: boolean;
    names: any[];
    itemGroup: any;
    mainProductGroup: any;
    handlingGroup: any;
    handling: any;
    deliveryConditions: { isoLanguageCode: string, description: string}[];
    surfaces: { isoLanguageCode: string, description: string}[];
    orderDetails: OrderDetails[];
    assets: ArticleAsset[];
    packages: Package[];
    name: string;
} 