import { ReturnItem } from './returnitem';

export class Return {
  _id: string;
  _rev: string;
  _deleted: boolean;
  additionaltext: string;
  AdditionalTextNew?: string;
  carrieraccount: string;
  custaccount: string;
  cellularphone: string;
  pamstatus: string;
  cleaningagreed: boolean;
  cleaningplusagreed: boolean;
  formworkservicetop: boolean;
  formworkserviceplus: boolean;
  formworkservicebasic: boolean;
  formworkserviceexclusive: boolean;
  cleaningandfsbasic: boolean;
  clientid: string;
  contactperson: string;
  custname: string;
  customerdeliverynote: string;
  dateofarrival: string;
  depname: string;
  meansoffreight: string;
  pamplacenumber: string;
  pamplacenumberid: string;
  unloaderemplid: string;
  deviceserviceagreed: boolean;
  items: ReturnItem[];
  pamdateofarrival_place: string;
  pamdateofdeparture_place: string;
  projectno: string;
  redeliverydate: string;
  rentalsitedescription: string;
  siteno: string;
  subjobsite: string;
  subsubjobsite: string;
  supportpulling: boolean;
  transunitcode: string;
  locked: boolean;
  type: string;
  uploadStatus: string;
  convertedDate: Date;
  AppVersion?: string;
  lockemail: string;
  Couchadditionaltext?: string;
  tonnage: number;
  tonnage1st: number;
  redeliveryType: string;
}
