<div scrollY="true" class="list" [ngClass]="{ menuIsClosed: !isMenuOpen }">
  <ion-accordion-group value="apps">
    <ion-accordion
      *ngIf="
        adminService.canAccessSite('dashboard') ||
        adminService.canAccessSite('returns') ||
        adminService.canAccessSite('pickings') ||
        adminService.canAccessSite('transport-orders') ||
        adminService.canAccessSite('recons')
      "
      value="apps"
    >
      <ion-item
        lines="none"
        class="firstMenuButton"
        [ngClass]="{
          marked:
            checkIfMarked('dashboard') ||
            checkIfMarked('returns') ||
            checkIfMarked('pickings') ||
            checkIfMarked('transport-orders') ||
            checkIfMarked('recons') ||
            checkIfMarked('return-deliveries') ||
            checkIfMarked('shipping-board')
        }"
        slot="header"
        color="medium"
      >
        <doka-icon name="apps" size="3xl" type="filled"></doka-icon>
        <ion-label>{{ "APPS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('dashboard')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('dashboard') }"
        (click)="navigate('dashboard')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          name="home"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "DASHBOARD" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('shipping-board')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('shipping-board') }"
        (click)="navigate('shipping-board')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          name="directions_boat"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "SHIPPING_BOARD" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('returns')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('returns') }"
        (click)="navigate('returns')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          name="local_shipping"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "RETURNS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('return-deliveries')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('return-deliveries') }"
        (click)="navigate('return-deliveries')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          name="local_shipping"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "RETURN_DELIVERIES" | i18nextEager }}</ion-label>
      </ion-item>
      <!--       <ion-item
        *ngIf="adminService.canAccessSite('pickings')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('pickings') }"
        (click)="navigate('pickings')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-img
          class="img_icon"
          src="../../assets/imgs/picking_icon.png"
        ></ion-img>
        <ion-label>{{ "PICKINGS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('transport-orders')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('transport-orders') }"
        (click)="navigate('transport-orders')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-img
          class="img_icon"
          src="../../assets/imgs/outbound_icon.png"
        ></ion-img>
        <ion-label>{{ "TRANSPORT_ORDERS" | i18nextEager }}</ion-label>
      </ion-item> -->
      <ion-item
        *ngIf="adminService.canAccessSite('recons')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('recons') }"
        (click)="navigate('recons')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-img
          class="img_icon"
          src="../../assets/imgs/recon_icon.png"
        ></ion-img>
        <ion-label>{{ "RECON_ORDERS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('inbound-orders')"
        class="secondMenuButton"
        [ngClass]="{ marked: checkIfMarked('inbound-orders') }"
        (click)="navigate('inbound-orders')"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          name="orders"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "INBOUND_ORDERS" | i18nextEager }}</ion-label>
      </ion-item>
    </ion-accordion>
    <ion-accordion
      *ngIf="
        adminService.canAccessSite('user-management') ||
        adminService.canAccessSite('user-management-administration') ||
        adminService.canAccessSite('organizational-overview')
      "
      value="usermanagement"
    >
      <ion-item
        lines="none"
        class="firstMenuButton"
        slot="header"
        color="medium"
        [ngClass]="{
          marked:
            checkIfMarked('user-management') ||
            checkIfMarked('user-management-administration') ||
            checkIfMarked('organizational-overview')
        }"
      >
        <doka-icon name="group" size="3xl" type="filled"></doka-icon>
        <ion-label>{{ "USER_MANAGEMENT" | i18nextEager }}</ion-label>
        <ion-badge
          *ngIf="adminService.userPermissionRequests.length > 0"
          class="openPermissionRequestBadge"
          >{{ adminService.userPermissionRequests.length }}</ion-badge
        >
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('organizational-overview')"
        (click)="navigate('organizational-overview')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('organizational-overview') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="account_tree"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "ORGANIZATION_HEADER" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('user-management')"
        (click)="navigate('user-management')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('user-management') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="person"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "USER_ADMINISTRATION" | i18nextEager }}</ion-label>
        <ion-badge *ngIf="adminService.userPermissionRequests.length > 0">{{
          adminService.userPermissionRequests.length
        }}</ion-badge>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('user-management-administration')"
        (click)="navigate('user-management-administration')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('user-management-administration') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="admin_panel_settings"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "ADMINISTRATION" | i18nextEager }}</ion-label>
      </ion-item>
    </ion-accordion>
    <ion-accordion value="capacityPlanning">
      <ion-item
        lines="none"
        class="firstMenuButton"
        slot="header"
        color="medium"
        [ngClass]="{
          marked:
            checkIfMarked('capacity-planning-administration') ||
            checkIfMarked('capacity-planning-overview') ||
            checkIfMarked('capacity-planning-charts') ||
            checkIfMarked('yard-stock-overview') ||
            checkIfMarked('capacity-planning-facility-management')
        }"
      >
        <doka-icon name="calculate" size="3xl" type="filled"></doka-icon>
        <ion-label>{{ "CAPACITY_PLANNING" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="!environment.production"
        (click)="navigate('capacity-planning-productivity')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('capacity-planning-productivity') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="query_stats"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{
          "CAPACITY_PLANNING_PRODUCTIVITY" | i18nextEager
        }}</ion-label>
      </ion-item>
      <ion-item
        (click)="navigate('capacity-planning-overview')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('capacity-planning-overview') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="space_dashboard"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "CAPACITY_PLANNING_OVERVIEW" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        (click)="navigate('capacity-planning-charts')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('capacity-planning-charts') }"
        button
      >
        <doka-icon
          class="img_icon"
          name="monitoring"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{ "CAPACITY_PLANNING_CHARTS" | i18nextEager }}</ion-label>
      </ion-item>
      <!--       <ion-item *ngIf="adminService.canAccessSite('yard-stock-overview')" class="secondMenuButton inventoryMenuButton" [ngClass]="{'marked': checkIfMarked('yard-stock-overview')}" (click)="navigate('yard-stock-overview')" lines="none" slot="content" color="medium" button>
        <ion-img class="img_icon" src="../../assets/imgs/inventory_icon.png"></ion-img>
        <ion-label>{{ "YARD_STOCK_OVERVIEW" | i18nextEager }}</ion-label>
      </ion-item> -->
      <ion-item
        (click)="navigate('capacity-planning-odlinsights')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{ marked: checkIfMarked('capacity-planning-odlinsights') }"
        button
      >
        <ion-icon
          class="img_icon"
          src="../../assets/imgs/ODLLogo.svg"
        ></ion-icon>
        <ion-label>{{ "ODL_INSIGHTS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="
          adminService.canAccessSite('capacity-planning-facility-management')
        "
        (click)="navigate('capacity-planning-facility-management')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{
          marked: checkIfMarked('capacity-planning-facility-management')
        }"
        button
      >
        <doka-icon
          class="img_icon"
          name="factory"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{
          "CAPACITY_PLANNING_FACILITY_MANAGEMENT" | i18nextEager
        }}</ion-label>
      </ion-item>
      <ion-item
        *ngIf="adminService.canAccessSite('capacity-planning-administration')"
        (click)="navigate('capacity-planning-administration')"
        class="secondMenuButton"
        lines="none"
        slot="content"
        color="medium"
        [ngClass]="{
          marked: checkIfMarked('capacity-planning-administration')
        }"
        button
      >
        <doka-icon
          class="img_icon"
          name="admin_panel_settings"
          size="3xl"
          type="filled"
        ></doka-icon>
        <ion-label>{{
          "CAPACITY_PLANNING_ADMINISTRATION" | i18nextEager
        }}</ion-label>
      </ion-item>
    </ion-accordion>
    <ion-accordion value="links">
      <ion-item
        lines="none"
        class="firstMenuButton"
        slot="header"
        color="medium"
      >
        <doka-icon name="link" size="3xl" type="filled"></doka-icon>
        <ion-label>{{ "LINKS" | i18nextEager }}</ion-label>
      </ion-item>
      <ion-item
        class="secondMenuButton"
        (click)="openDokaID()"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-icon
          class="img_icon"
          src="../../assets/imgs/DokaIDIcon.svg"
        ></ion-icon>
        <ion-label
          >{{ "DOKA_ID" | i18nextEager
          }}<ion-icon
            class="externalLinkIcon"
            src="../../assets/imgs/externalLink.svg"
          ></ion-icon
        ></ion-label>
      </ion-item>
      <ion-item
        class="secondMenuButton"
        (click)="openYardCam()"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-icon
          class="img_icon"
          src="../../assets/imgs/YardCamIcon.svg"
        ></ion-icon>
        <ion-label
          >{{ "YARDCAM_LINK" | i18nextEager
          }}<ion-icon
            class="externalLinkIcon"
            src="../../assets/imgs/externalLink.svg"
          ></ion-icon
        ></ion-label>
      </ion-item>
      <ion-item
        class="secondMenuButton"
        (click)="openDokaYardQC()"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-icon
          class="img_icon"
          src="../../assets/imgs/YardQCIcon.svg"
        ></ion-icon>
        <ion-label
          >{{ "DOKA_YQC" | i18nextEager
          }}<ion-icon
            class="externalLinkIcon"
            src="../../assets/imgs/externalLink.svg"
          ></ion-icon
        ></ion-label>
      </ion-item>
      <ion-item
        class="secondMenuButton"
        (click)="openDokaEM()"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <ion-icon
          class="img_icon"
          src="../../assets/imgs/EMIoTLogo.svg"
        ></ion-icon>
        <ion-label
          >{{ "DOKA_EM" | i18nextEager
          }}<ion-icon
            class="externalLinkIcon"
            src="../../assets/imgs/externalLink.svg"
          ></ion-icon
        ></ion-label>
      </ion-item>
      <ion-item
        class="secondMenuButton"
        (click)="openDokaEMIoT()"
        lines="none"
        slot="content"
        color="medium"
        button
      >
        <doka-icon
          class="img_icon"
          size="3xl"
          type="filled"
          name="monitoring"
        ></doka-icon>
        <ion-label
          >{{ "DOKA_EMIOT" | i18nextEager
          }}<ion-icon
            class="externalLinkIcon"
            src="../../assets/imgs/externalLink.svg"
          ></ion-icon
        ></ion-label>
      </ion-item>
    </ion-accordion>
  </ion-accordion-group>
  <ion-item
    class="standaloneLink"
    (click)="navigate('voucher-detail')"
    [ngClass]="{ marked: checkIfMarked('voucher-detail') }"
    lines="none"
    color="medium"
    button
  >
    <doka-icon
      class="img_icon"
      name="contract"
      size="3xl"
      type="filled"
    ></doka-icon>
    <ion-label>{{ "VOUCHER_DETAILS" | i18nextEager }}</ion-label>
  </ion-item>
  <ion-item
    class="standaloneLink"
    (click)="onRequestPermission()"
    lines="none"
    color="medium"
    button
  >
    <doka-icon class="img_icon" name="key" size="2xl" type="filled"></doka-icon>
    <ion-label>{{ "REQUEST_PERMISSIONS" | i18nextEager }}</ion-label>
  </ion-item>
</div>
